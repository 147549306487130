import React from 'react';
import { connect, useDispatch, useSelector } from "react-redux";
import { usePopperTooltip } from 'react-popper-tooltip';
import { findFlagUrlByNationality } from "country-flags-svg";
import pkLogo from 'assets/images/pk.png';

import {
  classModifier,
  getDateByTimezoneOffset,
  LS,
} from "utils";
import { addContactsToEntities } from "redux/ducks/contacts";
import {
  selectIsProfileInBuffer,
  selectCanIEditSession,
  selectProfilePrevBookedCount,
  selectUserTimezone,
  selectAgentByGirlId,
  selectActiveSessionId,
  selectActiveSessionContact
} from "redux/selectors/selectors";
import {
  updateCurrentDraggableId,
  DIVA_GIRLS_IMG_HOST_PREVIEW,
  DIVA_GIRLS_LINK,
} from "redux/ducks/divaGirls";
import {
  updateBufferedId,
  removeBufferedId,
} from "redux/ducks/sessions";
import { MODAL_TYPES, openModal } from "redux/ducks/activeWindows";

import './GalleryProfileCard.scss';
import ICONS from "assets/icons";
import LazyLoadImage from "components/LazyLoadImage/LazyLoadImage";
import Labels from "components/Labels/Labels";
import ProfileRates from "components/ProfileRates/ProfileRates";
import Portal from 'components/Portal';


const GalleryProfileCard = props => {
  const {
    id,
    profile,
    activeSession,
    activeSessionId,
    canIEditSession,
    userTimezone,
    isInBuffer,
    agentName,
  } = props;

  const activeSessionContact = useSelector(selectActiveSessionContact);

  const dispatch = useDispatch();

  const {
    visible,
    setTooltipRef,
    setTriggerRef,
    getTooltipProps,
  } = usePopperTooltip(
    {
      offset: [0, 0],
      trigger: 'hover',
      placement: 'auto',
    }
  );

  const {
    getTooltipProps: getFlagTooltipProps,
    setTooltipRef: setFlagTooltipRef,
    setTriggerRef: setFlagTriggerRef,
    visible: flagTooltipVisible,
  } = usePopperTooltip({
    trigger: "hover",
    placement: 'top',
  })

  const flagUrl = findFlagUrlByNationality(profile.nationality) || pkLogo;
  const additionalFlagUrl = profile.nationality_alt?.includes('European') ? pkLogo : findFlagUrlByNationality(profile.nationality_alt);
  const flagTooltipText = `Nationality: ${profile.nationality}` + (profile.nationality_alt ? `, ${profile.nationality_alt}` : '');

  const imgPath = DIVA_GIRLS_IMG_HOST_PREVIEW + profile.avatar;

  // const getClassName = () => {
  //   let className = 'profiles__item';

  //   if (props.isInBooked) {
  //     className += ' profiles__item--lightgreen';
  //   }
  //   else if (props.isInProposed) {
  //     className += ' profiles__item--orange';
  //   }

  //   if (isInBuffer) {
  //     className += ' profiles__item--in-buffer';
  //   }

  //   return className;
  // }

  // const showAnimation = (animation) => {
  //   switch (animation) {
  //     case 'add-to-buffer':
  //       setAnimation(" profiles__item--add-to-buffer");
  //       break;
  //     case 'remove-from-buffer':
  //       setAnimation(" profiles__item--remove-from-buffer");
  //       break;
  //   }

  //   setTimeout(() => setAnimation(""), 200);
  // };

  const onDragStart = e => {
    e.dataTransfer.setData("id", id);
    e.dataTransfer.setData("id_new", profile.id_new);
    e.dataTransfer.setData("contact_uid", profile.contact_uid);

    props.updateCurrentDraggableId(id);
  };

  const onDragEnd = e => {
    props.updateCurrentDraggableId(null);

    const dropInBuffer = LS.getItem('dropInBuffer', props.userId);

    if (dropInBuffer) {
      !isInBuffer && showAnimation('add-to-buffer');
      LS.deleteItem('dropInBuffer', props.userId);
    }
  };

  // const showProfileReviews = () => {
  //   props.openModal(MODAL_TYPES.profileReviews, { id });
  // }

  const addToBuffer = () => {
    // if (animation) return;

    props.updateBufferedId(id, activeSession, canIEditSession, profile.id_new, profile.contact_uid);
    // .then(() => showAnimation('add-to-buffer'));
  };

  const removeFromBuffer = () => {
    // if (animation) return;

    props.removeBufferedId(id, activeSession, canIEditSession);
    // .then(() => showAnimation('remove-from-buffer'));
  }

  const renderProfileRate = () => {
    const prices = profile.prices || [];
    const hour = prices.find(rate => rate.name === '1 hour') || {};
    const { incall = '-', outcall = '-' } = hour;

    return `${incall}/ ${outcall}`;
  }

  const getProfileShortBrestSize = () => {
    return profile.breast_size
      ? profile.breast_size.split(' ')[0]
      : '';
  }

  const handleBookingFormOpen = () => {
    dispatch(openModal(MODAL_TYPES.bookingForm, {
      initialProfile: profile,
      initialActiveSessionContact: activeSessionContact,
      activeSessionId,
    }));
  }

  const renderTextIfNotAvailable = () => {
    const { till } = profile.availability_info;
    const tillByTimezone = getDateByTimezoneOffset(userTimezone, till);
    const oneDay = 86400000;
    const oneWeek = oneDay * 7;
    const localTimezone = new Date().getTimezoneOffset() * (-1);
    const weekDays = {
      0: 'Sunday',
      1: 'Monday',
      2: 'Tuesday',
      3: 'Wednesday',
      4: 'Thursday',
      5: 'Friday',
      6: 'Saturday',
    }

    const plusDay = (date) => new Date(+date + oneDay);
    const getIsDay = (day) => day.toDateString() === tillByTimezone.toDateString();
    const getWeekDay = () => weekDays[tillByTimezone.getDay()];

    const today = getDateByTimezoneOffset(userTimezone);
    const tomorrow = plusDay(today);
    const dayAfterTomorrow = plusDay(tomorrow);
    const todayAtMidnight = new Date(today).setHours(0, 0, 0, 0) - (userTimezone - localTimezone) * 60000;
    const inOneWeek = todayAtMidnight + oneWeek;
    const inTwoWeeks = +inOneWeek + oneWeek;

    if (getIsDay(today)) { // Today
      const time = tillByTimezone.toLocaleString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: props.userHour12,
      })
      return `Will be available today after ${time}`;
    }
    else if (getIsDay(tomorrow)) { // Tomorrow
      return `Will be available tomorrow`;
    }
    else if (getIsDay(dayAfterTomorrow)) { // Day after tomorrow
      return `Will be available the day after tomorrow`;
    }
    else if (+tillByTimezone < inOneWeek) { // 7 days
      return `Will be available on ${getWeekDay()}`;
    }
    else if (+tillByTimezone < inTwoWeeks) { // 14 days
      return `Will be available on next ${getWeekDay()}`;
    }
    else { // Sat, August 15
      const humanDate = tillByTimezone.toLocaleDateString('en-US', {
        weekday: 'short',
        month: 'long',
        day: 'numeric',
      });
      return `Will be available ${humanDate}`;
    }
  }

  return (
    <div className="gallery-profile-card">
      {visible && 
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'gallery-profile-card__profile-rates-wrapper' })}
        >
          <ProfileRates
            profileId={profile.id}
            defaultPrices={profile.prices || []}
            isMember={profile.is_for_member || false}
            memberPrices={profile.prices_member || []}
            secretPrices={profile.prices_secret || []}
          />
        </div>
      }
      <div
        className={classModifier("gallery-profile-card__top-wrap", [
          profile.is_available_now && "available-now",
          !profile.is_available_now && !profile.is_available_today && "off-today",
        ])}
      >
        {!!canIEditSession && isInBuffer &&
          <button
            className={classModifier("gallery-profile-card__buffer-button", "remove")}
            onClick={removeFromBuffer}
            title="Remove from buffer"
          >
            <ICONS.close />
          </button>
        }
        {!!canIEditSession && !isInBuffer &&
          <button
            className={classModifier("gallery-profile-card__buffer-button", "add")}
            onClick={addToBuffer}
            title="Add to buffer"
          >
            <ICONS.buffer />
          </button>
        }

        <p className="gallery-profile-card__name" title={profile.name}>
          {profile.name}
        </p>

        {!!profile.rating.rating &&
          <button
            className="gallery-profile-card__rating-button"
            // onClick={showProfileReviews}
            disabled={!profile.reviewsCount}
          >
            {profile.rating.rating}
          </button>
        }

        <button ref={setTriggerRef} className="gallery-profile-card__rates-button">
          <ICONS.poundSign />
        </button>

        {profile.link &&
          <a
            href={DIVA_GIRLS_LINK + profile.link}
            target="_blank"
            className="gallery-profile-card__divaescort-link"
          >
            <ICONS.externalLinkSquare />
          </a>
        }
      </div>

      <div
        className="gallery-profile-card__image-thumb"
        draggable
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
        onDoubleClick={addToBuffer}
      >
        <LazyLoadImage src={imgPath} />

        {!!props.prevBookedCount &&
          <p className="gallery-profile-card__prev-booked-count">
            {props.prevBookedCount}
            <ICONS.eye />
          </p>
        }

        <div
          className={classModifier("gallery-profile-card__image-tags-wrap", [
            activeSession !== 0 && "session-with-client",
          ])}
        >
          <div className="gallery-profile-card__labels-or-book-wrap">
            <Labels profile={profile} classPrefix="profile-card-mini" />

            {/* <BookButton
              profileId={id}
              showAnimation={showAnimation}
              className={'booked-dropdown'}
            /> */}
            <button
              className='gallery-profile-card__open-map-button'
              onClick={() => dispatch(openModal(MODAL_TYPES.girlsMap, { mapProps: { profile } }))}
            >
              <ICONS.mapDash />
            </button>
            <button onClick={handleBookingFormOpen} className="gallery-profile-card__book-button">Book</button>
          </div>

          <div ref={setFlagTriggerRef}  className='gallery-profile-card__flag-img-wrap'>
            {<img 
              alt='flag' 
              src={flagUrl} 
              className='gallery-profile-card__flag-img'
            />}

            {additionalFlagUrl && 
              <img 
                alt='flag' 
                src={additionalFlagUrl} 
                className='gallery-profile-card__flag-img'
              />
            }
          </div>

          {flagTooltipVisible && 
            <Portal>
              <div
                className="popup"
                ref={setFlagTooltipRef}
                {...getFlagTooltipProps()}
              >
                {flagTooltipText}
              </div>
            </Portal>
          }

          {profile.availability_info?.type &&
            <p className="gallery-profile-card__not-available">
              {renderTextIfNotAvailable()}
            </p>
          }

          {agentName &&
            <p className="gallery-profile-card__agent-name">{agentName}</p>
          }
        </div>
      </div>

      <p className="gallery-profile-card__short-info">
        {`${renderProfileRate()}/ ${profile.age} y.o./ ${profile.height} cm/ ` +
          `${getProfileShortBrestSize()}/ ${profile.location_main}`}
      </p>

      {/* <ProfileRates
        isEditor
        profileId={profile.id}
        defaultPrices={profile.prices} 
        memberPrices={profile.prices_member}
        secretPrices={profile.prices_secret}
      /> */}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  userHour12: state.user.hour12,
  profile: state.divaGirls.entities[ownProps.id],
  isInBuffer: selectIsProfileInBuffer(state, ownProps.id),
  // isInBooked: selectIsProfileInBooked(state, ownProps.id),
  // isInProposed: selectIsProfileInProposed(state, ownProps.id),
  activeSession: state.sessions.activeSession,
  activeSessionId: selectActiveSessionId(state),
  canIEditSession: selectCanIEditSession(state),
  userTimezone: selectUserTimezone(state),
  userId: state.user.id,
  prevBookedCount: selectProfilePrevBookedCount(state, ownProps.id),
  agentName: selectAgentByGirlId(state, ownProps.id)?.fn,
});

const mapDispatchToProps = {
  openModal,
  addContactsToEntities,
  updateCurrentDraggableId,
  updateBufferedId,
  removeBufferedId,
}

export default connect(mapStateToProps, mapDispatchToProps)(GalleryProfileCard);

import React from 'react';
import { useSelector } from 'react-redux';

import { classModifier } from 'utils';
import { MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';
import { selectTelegramGroupMember } from 'redux/selectors/selectors';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';

import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';
import DateTime from 'components/DateTime';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import Spinner from 'components/UI/Spinner/Spinner';
import MsgText from './MsgText';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';
import { INTERACTION_TYPES } from '../../../../../config/constants';

const IncomingGroupMessage = ({ interaction, replyMsg, activeGroup, activeRecipientId, ...props }) => {
  const sender = useSelector((state) => selectTelegramGroupMember(state, interaction.from_telegram_user_id))
  const webmasterMsgsIds = useSelector((state) => state.girlChats.groupChat.webmasterMsgsIds);

  const [menu, toggleMenu] = useToggle(false);

  const isWebmasterMsg = webmasterMsgsIds.includes(interaction.id);
  const updatedInteraction = {
    ...interaction,
    caller_id: activeRecipientId,
    telegram_group_id: activeGroup,
    type: INTERACTION_TYPES.TELEGRAM_GROUP_MESSAGE,
    senderId: sender?.id,
    senderName: sender?.first_name,
    senderTelegramUserName: sender?.username || sender?.first_name || sender?.phone || 'Unknown User',
  }

  return (
    <li
      key={interaction.id}
      className={classModifier('interaction', [
        'inc',
        'tg-group',
        isWebmasterMsg && 'webmaster',
      ])}
      onMouseEnter={() => toggleMenu(true)}
      onMouseLeave={() => toggleMenu(false)}
    >
      <header
        className={classModifier("interaction__header", !interaction.body && 'is-empty-body')}
      >
        {sender?.photo && (
          <div className={classModifier("interaction__ava", !interaction.body && 'is-empty-body')}>
            <img
              src={`data:image/jpeg;base64,${sender.photo}`}
              alt="ava"
            />

            <ICONS.telegram className="interaction__ava-telegram-icon" />
          </div>
        )}

        <div className={classModifier("interaction__header-info")}>
          <ContactInteractionMenu
            isOpen={menu}
            closeMenu={toggleMenu}
            interaction={updatedInteraction}
            isTelegramGroupMsg
            activeChannel='telegram'
            replyMsg={replyMsg}
            isWebmasterMsg={isWebmasterMsg}
          />

          <span className="interaction__name">{sender?.first_name || 'Unknown User'}</span>

          <DateTime
            className={classModifier('interaction__time', 'msg')}
            date={interaction.dateCreated}
            config={MESSAGE_DATE_CONFIG}
          />

          {isWebmasterMsg && <ICONS.w className='interaction__webmaster-icon' />}
        </div>
      </header>

      <div
        className={classModifier('interaction__body-wrap', [
          !interaction.body && !interaction.media && 'empty-body',
          (interaction.media && interaction.body) && 'attachments',
        ])}
        onClick={props.handleLinkClick}
      >
        {(interaction.reply_to && interaction.reply_to.type !== 'messageService' && interaction.reply_to.type !== 'messageEmpty') && (
            <div className="interaction__replied-msg">
              <AttachmentRepliedMessage
                interaction={interaction.reply_to}
                activeGroup={activeGroup}
              />
            </div>
        )}

        {interaction.body &&
          <div className={classModifier("interaction__text-wrap", [
            interaction.media && 'attachments'
          ])}>
            <MsgText
              interaction={interaction}
            />
          </div>
        }

        {(interaction.media?.path || interaction.media?.document) && (
          !interaction.images.length
            ? <Spinner spinnerSize={16} />
            : <InteractionAttachments attachments={{ images: interaction.images }} />
        )}
      </div>
    </li>
  );
};

export default IncomingGroupMessage;

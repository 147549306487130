import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { CONTACT_TYPES, TOOLTIP_THEMES } from 'config/constants';
import { getContactsById } from 'redux/ducks/contacts';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import { 
  selectProfilesByIds,
  selectContactsByIds
} from 'redux/selectors/selectors';

import "./NotForTag.scss";
import TagBlock from 'components/UI/TagBlock/TagBlock';
import Tooltip from 'components/UI/Tooltip/Tooltip';
import GirlsList from 'components/UI/GirlsList/GirlsList';

const NotForTag = ({
  caller,
  children,
  ...props
}) => {

  const [isLoading, setIsLoading] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const dispatch = useDispatch();

  const notForClientsIds = caller?.not_for_clients?.map(item => item.caller_ignore_id) || [];
  const notForDivaIds = caller?.not_for_clients?.map(i => i.diva_default_id).filter(Boolean);

  const contacts = useSelector(state => selectContactsByIds(state, notForClientsIds));
  const profilesByDivaId = useSelector(state => selectProfilesByIds(state, notForDivaIds));

  const contactsIds = contacts.filter(Boolean).map(item => item.id);
  const profilesIds = profilesByDivaId?.map(item => item ? item.id : null).filter(Boolean) || [];

  useEffect(() => {
    if(isVisible) {
      if(contactsIds.length !== notForClientsIds.length) {
        setIsLoading(true);
  
        const uniqueContactsIds = [];
        notForClientsIds.forEach(item => !contactsIds.includes(item) && uniqueContactsIds.push(item));
  
        uniqueContactsIds.length && dispatch(getContactsById(uniqueContactsIds, CONTACT_TYPES.GIRL))
          .finally(() => setIsLoading(false));
      }
  
      if(profilesIds?.length !== notForDivaIds?.length) {
        const uniqueProfilesIds = [];
        notForDivaIds?.forEach(item => !profilesIds.includes(item) && uniqueProfilesIds.push(item));
  
        uniqueProfilesIds.length && dispatch(getActiveDivaGirls({ params: { 'filter-profileid': uniqueProfilesIds } }));
      }
    }
  }, [caller, isVisible]);

  const mergedItemsList = contacts?.map(item => {
    const foundedProfileById = profilesByDivaId
      .filter(Boolean)
      .find(profItem => profItem.id === item?.diva_default_id);

    return {
      ...item,
      age: foundedProfileById?.age,
      prices: foundedProfileById?.prices || [],
      agents: foundedProfileById?.agents || [],
      height: foundedProfileById?.height,
      breast_size: foundedProfileById?.breast_size,
      nationality: foundedProfileById?.nationality,
      isDivaNotFound: !foundedProfileById?.prices?.length && item?.diva_default_id
    };
  });

  const openEscortModal = (escort) => {
    dispatch(getContactsById(escort.id))
      .then((contact) => {
        if (contact) {
          dispatch(openModal(MODAL_TYPES.contactCard, { contact: contact.id }));
        }
      })
  }


  return (
    <Tooltip
      className="not-for-tag__popup-list"
      text={
        <GirlsList
          title="Not for list"
          items={mergedItemsList}
          isLoading={isLoading}
          onItemClick={openEscortModal}
        />
      }
      theme={TOOLTIP_THEMES.MAIN}
      interactive
      placement="bottom"
      setIsVisible={setIsVisible}
    >
      <TagBlock {...props}>
        {children}
      </TagBlock>
    </Tooltip>
  )
}

export default React.memo(NotForTag, (prevProps, nextProps) => {
  return prevProps.caller === nextProps.caller;
});

import React, {
  useState,
  useRef,
  useEffect,
  useCallback,
  useMemo,
} from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Scrollbars } from "react-custom-scrollbars-2";

import API from "api/api";
import { classModifier } from "utils";
import {
  getEmails,
  LIST_TYPES,
  updateSearch,
  setEmailsOffset,
  setActiveFolder,
  MAIL_LIST_LIMIT,
  clearActiveFolder,
  clearSelectedMails,
  setActiveSortFilter,
  MAIL_LIST_SORT_OPTIONS,
  getUsersAvatarsForMail,
  changeDefaultMailForUserRequest,
  setActiveFolderTabName,
  MAIL_LIST_FOLDER_TABS,
} from "redux/ducks/mail";

import "./MailList.scss";
import Spinner from "components/UI/Spinner/Spinner";
import MailListItem from "../MailListItem/MailListItem";
import MailPagination from "../MailPagination/MailPagination";
import { selectActiveFolderTab } from "redux/selectors/selectors";

const MailList = ({
  ids,
  tabs = [],
  type,
  offset,
  search,
  option,
  pending,
  entities,
  totalCount,
  getEmails,
  updateSearch,
  setActiveFolder,
  clearActiveFolder,
  setActiveSortFilter,
  setEmailsOffset,
  clearSelectedMails,
  defaultModeOfOperation,
  getUsersAvatarsForMail,
  changeDefaultMailForUserRequest,
  activeFolderTabName,
  setActiveFolderTabName,
  availableMails,
  defaultMailForUser,
  activeFolderTab,
}) => {
  const scrollbarsRef = useRef();

  const [firstPending, setFirstPending] = useState(true);
  const [isTimeoutFetch, setIsTimeoutFetch] = useState(false);

  const isSearchType = type === LIST_TYPES.search;

  const isDefaultTab = useMemo(
    () =>
      MAIL_LIST_FOLDER_TABS[LIST_TYPES.inbox].some(
        (tab) => tab.operatorName === activeFolderTabName
      ),
    [activeFolderTabName]
  );

  useEffect(() => {
    !isSearchType && updateSearch("");
    setActiveFolder(type);

    return () => {
      clearActiveFolder();
      clearSelectedMails();
      setActiveSortFilter(MAIL_LIST_SORT_OPTIONS[0].value);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setIsTimeoutFetch(true);
    }, 100);
  }, [activeFolderTab]);

  useEffect(() => {
    if (!isSearchType && search) return;

    if (defaultModeOfOperation?.type && isTimeoutFetch) {
      handleGetOffset(0).then(() => setFirstPending(false));
    }
  }, [
    activeFolderTabName,
    option,
    search,
    defaultModeOfOperation?.type,
    isTimeoutFetch,
  ]);

  useEffect(async () => {
    if (!activeFolderTab?.id) return;
    if (activeFolderTab.operatorName === defaultMailForUser?.username) return;
    try {
      const { data: dataUserPhoto } = await API.getUsersAvatarsForMail(
        activeFolderTab.id
      );
      await getUsersAvatarsForMail(dataUserPhoto);
    } catch (error) {
      console.error(error);
    }
  }, [activeFolderTab, defaultMailForUser]);

  const handleGetOffset = useCallback(
    async (offset) => {
      if (isSearchType && search) {
        await getEmails({
          type,
          offset,
          search,
          limit: MAIL_LIST_LIMIT,
        });
      } else {
        const preparedAvailableMailsArray = availableMails
          ?.filter((el) => el.type === defaultModeOfOperation?.type)
          .map((el) => el.id);

        const config = {
          type,
          offset,
          mode: activeFolderTab?.mode,
          limit: MAIL_LIST_LIMIT,
          sortBy: option,
          emails_ids: preparedAvailableMailsArray,
          emailId:
            activeFolderTab?.id ||
            (type !== LIST_TYPES.inbox &&
            !isDefaultTab &&
            defaultModeOfOperation?.id
              ? defaultModeOfOperation.id
              : null),
        };

        if (
          activeFolderTab?.id &&
          activeFolderTab.operatorName !== defaultMailForUser?.username
        ) {
          changeDefaultMailForUserRequest(activeFolderTab.id).then(
            async () => await getEmails(config)
          );
        } else {
          await getEmails(config);
        }
      }

      clearSelectedMails();
      setEmailsOffset(offset);
      scrollConversationsToTop();
    },
    [
      defaultModeOfOperation,
      isSearchType,
      search,
      availableMails,
      activeFolderTabName,
      activeFolderTab,
      option,
    ]
  );

  const scrollConversationsToTop = () => {
    if (scrollbarsRef.current) {
      scrollbarsRef.current.scrollToTop();
    }
  };

  if (firstPending) {
    return (
      <div className="mail-list">
        <Spinner spinnerSize={75} className={"mail-list__spinner"} />
      </div>
    );
  }

  return (
    <div className={classModifier("mail-list", pending && "pending")}>
      <div className="mail-list__header">
        {tabs.length > 0 && (
          <ul className="mail-list__tabs">
            {tabs.map((tab) => (
              <li
                key={tab.operatorName}
                className={classModifier("mail-list__tab", [
                  activeFolderTabName === tab.operatorName && "selected",
                ])}
                onClick={() => {
                  setActiveFolderTabName(tab.operatorName);
                  clearSelectedMails();
                }}
              >
                {tab.operatorName}
              </li>
            ))}
          </ul>
        )}

        <div className="mail-list__pagination-sort-wrap">
          {totalCount > 0 && (
            <MailPagination
              offset={offset}
              pageSize={MAIL_LIST_LIMIT}
              totalCount={totalCount}
              onGetOffset={handleGetOffset}
            />
          )}

          {!isSearchType && (
            <div className="mail-list__sort">
              <Select
                className="react-select"
                classNamePrefix="react-select"
                options={MAIL_LIST_SORT_OPTIONS}
                defaultValue={MAIL_LIST_SORT_OPTIONS[0]}
                getOptionValue={(option) => option.value}
                getOptionLabel={(option) => option.label}
                onChange={(option) => setActiveSortFilter(option.value)}
                isSearchable={false}
                components={{
                  IndicatorSeparator: null,
                }}
              />
            </div>
          )}
        </div>
      </div>

      {!ids.length && !pending && (
        <p className="mail-list__no-items">No emails</p>
      )}

      {ids.length > 0 && (
        <Scrollbars
          autoHide
          autoHideTimeout={800}
          ref={scrollbarsRef}
          style={{ width: "auto", height: "auto" }}
          className="mail-list__scrollbars"
        >
          <ul className="mail-list__conversations">
            {ids.map((id) => (
              <MailListItem type={type} item={entities[id]} key={id} />
            ))}
          </ul>
        </Scrollbars>
      )}
    </div>
  );
};

const mSTP = (state, ownProps) => ({
  ids: state.mail[ownProps.type].ids,
  entities: state.mail[ownProps.type].entities,
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  pending: state.mail.mailList.mailListPending,
  totalCount: state.mail.totalCount,
  option: state.mail.mailList.activeSortFilter,
  defaultModeOfOperation: state.mail.defaultModeOfOperation,
  activeFolderTabName: state.mail.mailList.activeFolderTabName,
  availableMails: state.mail.availableMails,
  defaultMailForUser: state.mail.defaultMailForUser,
  activeFolderTab: selectActiveFolderTab(state),
});

const mDTP = {
  getEmails,
  updateSearch,
  setEmailsOffset,
  setActiveFolder,
  clearActiveFolder,
  clearSelectedMails,
  setActiveSortFilter,
  getUsersAvatarsForMail,
  changeDefaultMailForUserRequest,
  setActiveFolderTabName,
};

export default connect(mSTP, mDTP)(MailList);

import classNames from 'classnames';
import chroma from 'chroma-js'

import { BOOKING_DETAILS_ITEMS_NAMES } from 'components/BookingItemNew/config/constants';
import { ATTACHMENT_BOOKING_DATE_CONFIG } from 'config/dates-сonfig';

import './BookingAttachmentItem.scss';

const detailsItem = [
  BOOKING_DETAILS_ITEMS_NAMES.TYPE,
  BOOKING_DETAILS_ITEMS_NAMES.DATE,
  BOOKING_DETAILS_ITEMS_NAMES.DURATION,
  BOOKING_DETAILS_ITEMS_NAMES.RATE,
]

const BookingAttachmentItem = (props) => {
  const {
    className,
    Escort,
    Details,
    Operator,
    bookingColor,
    handleOpenSession,
    isDeleted,
  } = props;

  const escortAndOperatorColor = chroma.contrast(bookingColor, '#fff') > 2 ? '#fff' : '#0092f2';

	return (
    <div
    	className={classNames('booking-attachment', [className, isDeleted && 'deleted'])}
      style={{ backgroundColor: bookingColor }}
      onClick={handleOpenSession}
    >
			<Escort
      	classPrefix="booking-attachment-escort"
        textStyle={{ color: escortAndOperatorColor }}
      	isAvatarShowed
        namesAlignment="reverse-vertical"
        extraText={
          <Details
          	className="booking-attachment__details"
            style={{ color: '#000' }}
          	items={detailsItem}
            dateConfig={ATTACHMENT_BOOKING_DATE_CONFIG}
          />
        }
      />

      <Operator
      	className="booking-attachment__operator"
        style={{ color: escortAndOperatorColor }}
      />

      {props?.confirmationStatus === 'cancelled' &&
        <div className='booking-attachment__status'>
          <span>{props.confirmationStatus}</span>
        </div>
      }


      {isDeleted &&
        <div className='booking-attachment__status'>
          <span>Deleted</span>
        </div>
      }

    </div>
  )
}

export default BookingAttachmentItem;

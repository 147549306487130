import { INTERACTION_TYPES } from 'config/constants';

export default (id) => {
  const interactionChannel = {
    [INTERACTION_TYPES.INCOMING_MSG]: 'comments',
    [INTERACTION_TYPES.OUTGOING_MSG]: 'comments',
    [INTERACTION_TYPES.INCOMING_MSG_TELEGRAM]: 'robot',
    [INTERACTION_TYPES.OUTGOING_MSG_TELEGRAM]: 'robot',
    [INTERACTION_TYPES.TELEGRAM_GROUP_MESSAGE]: 'telegram',
    [INTERACTION_TYPES.INCOMING_PRIVATE_MSG_TELEGRAM]: 'telegram',
    [INTERACTION_TYPES.OUTGOING_PRIVATE_MSG_TELEGRAM]: 'telegram',
    [INTERACTION_TYPES.MASTER_TASK_TELEGRAM_GROUP]: 'telegram',
    [INTERACTION_TYPES.INCOMING_MSG_WHATSAPP]: 'whatsapp',
    [INTERACTION_TYPES.OUTGOING_MSG_WHATSAPP]: 'whatsapp',
    [INTERACTION_TYPES.INCOMING_MSG_IPHONE_IMESSAGE]: 'apple',
    [INTERACTION_TYPES.OUTGOING_MSG_IPHONE_IMESSAGE]: 'apple',
    [INTERACTION_TYPES.INCOMING_MSG_IPHONE_SMS]: 'apple',
    [INTERACTION_TYPES.OUTGOING_MSG_IPHONE_SMS]: 'apple',
    [INTERACTION_TYPES.INCOMING_EMAIL]: 'mail',
    [INTERACTION_TYPES.INCOMING_MSG_DINSTAR]: 'comments',
    [INTERACTION_TYPES.OUTGOING_MSG_DINSTAR]: 'comments',
  };

  return interactionChannel[id];
}

import React, { useCallback, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import classNames from 'classnames';
import _ from 'lodash';

import API from 'api/api';
import { MODAL_TYPES, closeModal, openModal } from 'redux/ducks/activeWindows';
import { createTasks, updateTasksOrReminders } from 'redux/ducks/notifications';
import { selectAllOperators, selectNonDevelopersOperatorsList, selectUserTimezone } from 'redux/selectors/selectors';
import { FEEDBACK_DATE_CONFIG, TASKS_OR_REMINDERS_DATE_CONFIG } from 'config/dates-сonfig';
import { classModifier, getContactAvatar, getDateByTimezoneOffset, parseMsgText } from 'utils';
import { BUTTON_THEMES, CONTACTS_MODES_TYPES, CONTACT_TYPES } from 'config/constants';
import { MAIL_SEARCH_CONTACTS_LIMIT } from 'config/constants';
import { setGirlGroupWebmasterMsgs } from 'redux/ducks/girlChats';
import { selectContactById } from 'redux/selectors/selectors';

import ICONS from 'assets/icons';
import './AddNewTasksOrRemaindersModal.scss'
import { usePopperTooltip } from 'react-popper-tooltip';
import popperViewportCenter from 'config/popperViewportCenter';
import DateTimePicker from 'components/DateTimePicker/DateTimePicker';
import DateTime from 'components/DateTime';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import TaskLog from 'components/TasksAndRemindersModal/components/TaskLog';
import Button from 'components/Button/Button';
import FormTextTextarea from 'components/FormTextTextarea/FormTextarea';
import getIconTypeByInteractionId from 'utils/getIconTypeByInteractionId';
import MsgText from 'containers/Chat/components/ChatTimeline/interactions/MsgText';
import InteractionAttachments from 'containers/Chat/components/ChatAttachments/InteractionAttachments';
import PinnedMsgAttachments from 'containers/Chat/components/ChatTimeline/PinnedMsgs/PinnedMsgsAttachments';

const contactTypeOptions = [
  { label: 'Client', value: CONTACT_TYPES.CLIENT },
  { label: 'Girl', value: CONTACT_TYPES.GIRL },
];

const defaultAllOperators = { label: "All operators", value: "" };

const convertForSelect = (list) => list.map((item) => ({ label: item.username, value: item.id }));

const AddNewTasksOrRemaindersModal = ({ item, ...props }) => {
  const operatorsList = useSelector(selectAllOperators);
  const nonDevelopersOperatorsList = useSelector(selectNonDevelopersOperatorsList);
  const operatorId = useSelector((state) => state.user.id);
  const activeTgGroupInternalId = useSelector((state) => state.girlChats.groupChat.internalId);
  const userTimezone = useSelector(selectUserTimezone);
  const convertedOperatorsList = convertForSelect(nonDevelopersOperatorsList);
  const assignedOperator = convertForSelect(operatorsList).find(operator => operator.value === Number(item?.assigned_to_user_id));
  
  const dispatch = useDispatch();

  const defaultOperator = assignedOperator || defaultAllOperators;

  const interaction_id = item?.interaction_id;
  const [taskState, setTaskState] = useState({
    title: item?.title || '',
    isCalendarOpen: false,
    date: item?.remind_at,
    contactType: props.isWebmaster ? CONTACT_TYPES.GIRL : CONTACT_TYPES.CLIENT,
    selectOperator: item?.assigned_to_user_id || defaultAllOperators.value,
    selectContact: props.caller || item?.associated_with,
    description: item?.description || '',
    ...(item?.interaction_id ? { interaction_id } : {})
  });

  const {
    title,
    isCalendarOpen,
    date,
    contactType,
    selectOperator,
    selectContact,
    description,
  } = taskState;

  const isFormDisabled = props.isWebmaster
    ? (!selectContact || !(description || taskState.interaction_id))
    : (!title && !description)

  const getAssignedMessageUsername = (item) => {
    if (item.interaction.hasOwnProperty('senderName')) {
      return item.interaction.senderName;
    }

    if (item.interaction.telegram_group_id) {
      return item.telegramMember ? (item.telegramMember.username || item.telegramMember.first_name) : item.telegram_username
    }

    const sender = item.interaction.caller || item.interaction.contact;

    return sender?.fn || sender?.short_name || sender?.name;
  }

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isCalendarOpen,
    onVisibleChange: () =>
      setTaskState((prev) => ({ ...prev, isCalendarOpen: !isCalendarOpen })),
  }, {
    modifiers: [popperViewportCenter],
  });

  const textareaRef = useRef(null);

  const handleSelectDate = (timestamp) => {
    setTaskState((prev) => ({ ...prev, date: timestamp, isCalendarOpen: false }))
  };

  const fetchSearchContacts = async ({ query, offset, cancelToken }) => {
    try {
      const { data } = await API.searchContacts(
        contactType,
        query, offset,
        MAIL_SEARCH_CONTACTS_LIMIT,
        cancelToken
      );
      return ({
        newItems: data,
        newHasMore: data.length === MAIL_SEARCH_CONTACTS_LIMIT,
      });
    } catch (message) {
      return console.error(message);
    }
  };

  const onAddClient = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchContacts,
      itemComponent: SearchListContactItem,
      onChoose: (selectedContact) => {
        setTaskState((prev) => ({ ...prev, selectContact: selectedContact }))
        dispatch(closeModal(MODAL_TYPES.searchList));
      },
    }));
  };

  const handleCreateTask = useCallback(() => {
    let saveTask;

    if (props.isCreate) {
      saveTask = props.isWebmaster ? API.createWebmasterTask : (props) => dispatch(createTasks(props));
    } else {
      saveTask = props.isWebmaster ? API.updateWebmasterTask : (props) => dispatch(updateTasksOrReminders(props));
    }

    saveTask({
      description,
      title,
      caller_id: selectContact?.id,
      remind_at: new Date(date)?.getTime(),
      user_id: selectOperator,
      ...(!props.isCreate ? { isReminders: !!item.is_sent, id: item.id } : {}),
      ...(props.isWebmaster && item?.id ? { webmaster_id: item.id } : {}),
      ...(taskState.interaction_id ? { interaction_id: taskState.interaction_id } : {}),
      ...(item?.isTelegramGroupMsg ? {
          telegram_message_id: item.interaction.id,
          message_text: item.interaction.body,
          telegram_group_id: activeTgGroupInternalId,
        } : {}
      ),
      ...(props.isWebmaster && item?.interaction?.senderId && { telegram_member_id: item.interaction.senderId }),
      ...(props.isWebmaster && item?.interaction?.senderTelegramUserName && { telegram_username: item.interaction.senderTelegramUserName }),
    })
      .then(() => {
        dispatch(closeModal());
        props.setIsTaskInfoModalOpen?.(false);
        if (item.isTelegramGroupMsg) {
          dispatch(setGirlGroupWebmasterMsgs([item.interaction.id]));
        }
      })
      .catch(() => {
        dispatch(closeModal());
        props.setIsTaskInfoModalOpen?.(false);
      });
  }, [
    description,
    title,
    contactType,
    date,
    selectContact,
    selectOperator,
    props.setIsTaskInfoModalOpen,
    item,
    taskState
  ]);

  const headerTitle = `${props.isCreate ? "Create New" : "Edit"} ${props.isWebmaster ? "Webmaster " : ""}Task`

  const handleTextareaChange = (e) => {
    setTaskState((prev) => ({ ...prev, description: e.target.value }));
  }

  const AssignedMessage = useCallback(({ className }) => {
    const { type: lastInteractionChannel } = item.interaction;

    const handleDeleteMessage = (event) => {
      setTaskState((prev) => _.pickBy(prev, (_, key) => key !== 'interaction_id'));
      event.stopPropagation();
    }

    const classes = [className, 'assigned-message', 'new-tasks-or-reminders-modal__assigned-message'];
    const lastInteractionType = getIconTypeByInteractionId(lastInteractionChannel);
    const LastInteractionIcon = ICONS[lastInteractionType];
    const attachemnts = item.interaction.attachments || (item.interaction.images && item.interaction);

    return (
      <article className={classNames(...classes)}>
        <header className="assigned-message__header">
          <div className="assigned-message__caller">
						{LastInteractionIcon && <LastInteractionIcon className={classModifier('assigned-message__icon', lastInteractionType)}/>}
            {getAssignedMessageUsername(item)}
          </div>

          <button
            className="assigned-message__delete-btn"
            onClick={handleDeleteMessage}
          >
            <ICONS.close />
          </button>
        </header>

        <div className="assigned-message__content">
          {item.interaction.body && (
            <MsgText
              interaction={item.interaction}
              isAttachmentsExist={Boolean(item.interaction.attachments)}
            />
          )}

          {attachemnts && (
            <div
              className="assigned-message__attachments"
              data-button
            >
              <PinnedMsgAttachments attachments={attachemnts} isMenuOpen />
            </div>
          )}
        </div>
      </article>
    )
  }, []);
  // const getOperatorName = (operatorID) =>
  //   convertedOperatorsList.find((item) => item.value === operatorID)?.label;

  return (
    <div className="new-tasks-or-reminders-modal">
      <header className="new-tasks-or-reminders-modal__header">
        <div className="new-tasks-or-reminders-modal__header-title">
          {headerTitle}
        </div>
        <div className="new-tasks-or-reminders-modal__btns">
          <button
            className="new-tasks-or-reminders-modal__btn-cancel"
            onClick={() => {
              dispatch(closeModal());
              !props.isCreate && props.setIsTaskInfoModalOpen?.(false);
            }}
          >
            Cancel
          </button>
          <button
            className="new-tasks-or-reminders-modal__btn-done"
            onClick={handleCreateTask}
            disabled={isFormDisabled}
          >
            Done <ICONS.check />
          </button>
        </div>
      </header>

      <div className="new-tasks-or-reminders-modal__form">
        <div className="new-tasks-or-reminders-modal__form-header">
          <div className="new-tasks-or-reminders-modal__reminders-wrapper">
            {date ? (
              <ICONS.bellFill className="new-tasks-or-reminders-modal__icon-bell" />
            ) : (
              <ICONS.bellSlash className="new-tasks-or-reminders-modal__icon-bell" />
            )}

            <div
              className={classModifier(
                'new-tasks-or-reminders-modal__reminders-text', [date && 'date']
              )}
            >
              {date ? (
                <DateTime
                  date={date}
                  config={TASKS_OR_REMINDERS_DATE_CONFIG}
                  // ignoreTimezone
                />
              ) : 'Reminder not set'}
            </div>
            {date ? (
              <div className="new-tasks-or-reminders-modal__date-btns">
                <ICONS.close
                  id="close"
                  onClick={() => setTaskState((prev) => ({ ...prev, date: null }))}
                />
                <ICONS.pencil
                  id="edit"
                  onClick={() => setTaskState((prev) => ({ ...prev, isCalendarOpen: true }))}
                />
              </div>
            ) : (
              <button
                ref={setTriggerRef}
                className="new-tasks-or-reminders-modal__btn-set"
              >
                SET
              </button>
            )
            }
          </div>
          <div className="new-tasks-or-reminders-modal__box">
            Assign to:
						
            <div className={classModifier("new-tasks-or-reminders-modal__field", "assigned")}>
              <Select
                options={[
                  defaultAllOperators,
                  ...convertedOperatorsList
                ]}
                isSearchable={false}
                defaultValue={defaultOperator}
                onChange={(inputValue) =>
                  setTaskState((prev) => ({ ...prev, selectOperator: inputValue.value }))
                }
                className="react-select"
                classNamePrefix="react-select"
                components={{
                  IndicatorSeparator: null,
                }}
              />
            </div>
          </div>
        </div>

        <div className="new-tasks-or-reminders-modal__label-wrapper">
          <label className="new-tasks-or-reminders-modal__label">
            <span className="new-tasks-or-reminders-modal__label-text">
              Title
            </span>
            <input
              value={title}
              className="new-tasks-or-reminders-modal__input"
              type="text"
              onChange={(e) => setTaskState((prev) => ({ ...prev, title: e.target.value }))}
              placeholder="Title or comments"
            />
          </label>
        </div>

        <div className="new-tasks-or-reminders-modal__description-wrapper">
          <span className="new-tasks-or-reminders-modal__label-text">
            Description
          </span>

          <FormTextTextarea
            className="new-tasks-or-reminders-modal__description"
            autosize
            inputRef={textareaRef}
            inputProps={{
              onChange: handleTextareaChange,
              rows: 1,
              value: parseMsgText(description) instanceof Object ? parseMsgText(description).map((part) => part.value).join('') : description,
            }}
            extraContent={taskState.interaction_id ? <AssignedMessage /> : null}
          />
        </div>

        <div className="new-tasks-or-reminders-modal__client-info">
          {selectContact ? (
            <div className={classModifier('new-tasks-or-reminders-modal__client',
              [selectContact && 'selected']
            )}>
              <ICONS.close
                id="close"
                onClick={() => setTaskState((prev) => ({ ...prev, selectContact: null }))}
              />
              <LazyLoadImage src={getContactAvatar(selectContact)} alt="ava" />
              <div className="new-tasks-or-reminders-modal__client-text-content">
                <span className="new-tasks-or-reminders-modal__client-name">{selectContact?.fn}</span>
                <span className="new-tasks-or-reminders-modal__client-contact">
                  {selectContact?.tels[0]?.tel || selectContact?.emails?.[0]?.email || ''}
                </span>
              </div>
            </div>
          ) : (
            <>
              <div className="new-tasks-or-reminders-modal__box">
                Associate with:

                <div className={classModifier("new-tasks-or-reminders-modal__field", "associated")}>
                  <Select
                    options={contactTypeOptions}
                    isSearchable={false}
                    defaultValue={contactTypeOptions[props.isWebmaster ? 1 : 0]}
                    onChange={(inputValue) =>
                      setTaskState((prev) => ({ ...prev, contactType: inputValue.value }))
                    }
                    className="react-select"
                    classNamePrefix="react-select"
                    components={{
                      IndicatorSeparator: null,
                    }}
                  />
                </div>
              </div>
              <Button
                className="new-tasks-or-reminders-modal__btn-add"
                theme={BUTTON_THEMES.primary}
                onClick={onAddClient}
                icon={<ICONS.adrBookInvert />}
              >
                Add {CONTACTS_MODES_TYPES[contactType]}
              </Button>
            </>
          )}
        </div>

        {/* <div className="new-tasks-or-reminders-modal__date-info">
          {item?.date_created && item?.created_by_user_id &&
            <div className="new-tasks-or-reminders-modal__date">
              Created{" "}
              <DateTime
                date={item.date_created}
                config={FEEDBACK_DATE_CONFIG}
                ignoreTimezone
              />{" "}
              by: <span>{getOperatorName(item.created_by_user_id)}</span>
            </div>
          }
          {item?.completed_date && item?.completed_by_user_id &&
            <div className="new-tasks-or-reminders-modal__date">
              Completed{" "}
              <DateTime
                date={item.completed_date}
                config={FEEDBACK_DATE_CONFIG}
                ignoreTimezone
              />{" "}
              by: <span>{getOperatorName(item.completed_by_user_id)}</span>
            </div>
          }
        </div> */}

        {item && <TaskLog item={item} className="new-tasks-or-reminders-modal__date-info" />}

      </div>

      {isCalendarOpen && (
        <div
          ref={setTooltipRef}
          {...getTooltipProps({ className: 'calendar-form-field__tooltip' })}
        >
          <DateTimePicker
            onSelectDate={handleSelectDate}
            onCancel={() => setTaskState((prev) => ({ ...prev, isCalendarOpen: false }))}
            initDate={date || new Date()}
            minDate={+getDateByTimezoneOffset(userTimezone) + 900000}
            // ignoreTimezone
          />
        </div>
      )}
    </div>
  );
}

export default AddNewTasksOrRemaindersModal;

import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

import ICONS from 'assets/icons';
import { BOOKING_MESSAGES_DATE_CONFIG } from 'config/dates-сonfig';
import { selectOperatorOrUserById } from 'redux/selectors/selectors';
import { classModifier } from 'utils';

import './BookingLog.scss';
import DateTime from 'components/DateTime';
import List from 'components/List/List';

const excludedDescription = [
  'date_created',
  'uuid_group',
  'profit',
  'rate',
  'taxi_fee',
  'requirements',
  'priceType',
  'price_type',
  'requirements_count',
  'girlContactId',
  'is_success',
  'profile_id',
  'girlName',
]

const bookingLogLabels = {
  'date': 'Date',
  'type': 'Type',
  'duration': 'Duration',
  'discount': 'Discount',
  'taxiFee': 'Taxi fee',
  'total_rate': 'Rate',
  'confirmation_status': 'Booking status',
  'services_added': 'Services',
  'services_removed': 'Services',
  // 'girlName': 'Girl name',
  'owner': 'Operator',
  'bookingStatus': 'Booking status',
  'callerName': 'Caller name',
  'notes': 'Notes',
  'address': 'Address',
  'special_request': 'Special Request',
  'requirements_added': 'Requirements',
  'requirements_removed': 'Requirements',
  'message': 'Message to client',
  'booking': 'Booking',
  'meeting_type': 'Meeting type',
  'reason': 'Reason',
  'description': 'Cancelation description',
}

const nonSpecificKeys = [
  'notes',
  'address',
  'special_request',
  'message',
  'owner',
  'bookingStatus',
  'confirmation_status',
  'is_success',
  'reason',
  'description',
]

const OperatorName = ({ id }) => {
  const operator = useSelector((state) => selectOperatorOrUserById(state, id));

  return operator.username;
}

const getSpecificBookingLogValue = (fieldKey, value) => {
  if (fieldKey === 'date') {
    return <DateTime date={value} config={BOOKING_MESSAGES_DATE_CONFIG} />;
  }

  if (fieldKey === 'owner') {
    return <OperatorName id={value} />;
  }

  if (fieldKey === 'address') {
    return value.address;
  }

  if (['total_rate', 'discount', 'taxiFee'].includes(fieldKey)) {
    return `£${value}`;
  }

  if (fieldKey.includes('services')) {
    return value.map(service => service.name).join(', ');
  }
}

const getBookingLogFieldLabel = (fieldKey) => {
  let removedOrAddedIcon;

  if (fieldKey.includes('removed')) {
    removedOrAddedIcon = <ICONS.plusThick className={classModifier('booking-log__label-icon', 'minus')} />
  }

  if (fieldKey.includes('added')) {
    removedOrAddedIcon = <ICONS.plusThick className={classModifier('booking-log__label-icon', 'plus')} />
  }

  return (
    <h5 className='booking-log__log-label'>
      {removedOrAddedIcon}
      {bookingLogLabels[fieldKey]}:
    </h5>
  )
}

const getBookingLogValue = (fieldKey, value) => {
  const specificValue = getSpecificBookingLogValue(fieldKey, value);

  if (specificValue) {
    return specificValue;
  }

  if (value) {
    return Array.isArray(value) ? value.join(', ') : value;
  }

  return <mark className='booking-log__log-deleted'>deleted</mark>;
}

const BookingLogDetailsItem = ({ fieldsList }) => {
  return fieldsList.map(([fieldKey, value], i) => (
    <div key={i} className='booking-log__log-item'>
      {getBookingLogFieldLabel(fieldKey)}
      {getBookingLogValue(fieldKey, value)}
    </div>
  ))
}

const BookingLogItem = ({ item }) => {
  const { id, user_id, action, description, date, profile_name } = item;

  const operator = useSelector((state) => selectOperatorOrUserById(state, user_id));

  const fieldsList = description
    ? Object.entries(description).filter(([key]) => !excludedDescription.includes(key))
    : [];
  const isGeneral = nonSpecificKeys.includes(Object.keys(description)[0]);

  const isCreateBookingAction = action === 'create booking';

  return (
    <li key={id} className='booking-log__item'>
      <div className='booking-log__item-header'>
        <span className='booking-log__operator'>
          {operator.username}
        </span>

        <DateTime
          className='booking-log__date'
          date={date}
          config={BOOKING_MESSAGES_DATE_CONFIG}
        />
      </div>


      <div className='booking-log__log-description'>
        {!isGeneral && (
          <span className='booking-log__girl-name'>
            {profile_name}:
          </span>
        )}

        {isCreateBookingAction
          ? <span className='booking-log__log-label'>Booked</span>
          : <BookingLogDetailsItem fieldsList={fieldsList} />}
      </div>
    </li>
  )
}

const BookingLog = ({ bookingLog, isDuoProfileExisted }) => {
  return (
    <List
      classPrefix='booking-log'
      list={bookingLog}
      listLoadPending={!bookingLog?.length}
      autoHide
      withoutAbsentLabel
    >
      <BookingLogItem isDuoProfileExisted={isDuoProfileExisted} />
    </List>
  )
}

export default React.memo(BookingLog);

import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import classNames from 'classnames';
import { classModifier } from 'utils';

import ICONS from 'assets/icons';
import { selectModalsTransitionModes } from 'redux/selectors/selectors';
import { closeModal, closeChildModal, MODAL_TYPES } from 'redux/ducks/activeWindows';

import './ProfileCarousel.scss';
import Carousel from 'components/Carousel/Carousel';
import CarouselNavigation from 'components/CarouselNavigation/CarouselNavigation';

const ProfileCarousel = (props) => {
  const {
    closeModal,
    closeChildModal,
    updateSliderCount,
    isNotProfile,
    isGallery
  } = props;
  const modalsTransitionModes = useSelector(selectModalsTransitionModes);

  const [activeSlide, setActiveSlide] = useState(props.defaultActiveSlide || 0);
  const media = props.media?.length
    ? props.media
    : [props.avatar]

  useEffect(() => {
    updateSliderCount && updateSliderCount(Math.floor(activeSlide / 8))
  }, [activeSlide]);

  const classes = classNames(
    classModifier('modal', [
      modalsTransitionModes[MODAL_TYPES.profileCarousel],
      MODAL_TYPES.profileCarousel
    ]),
    'profile-carousel'
  )

  return (
    <div className={classes}>
      <div className="profile-carousel__header">
        <div className="profile-carousel__image-name">
          {media[activeSlide]?.image_name || media[activeSlide].fileName || media[activeSlide].name}
        </div>

        <button
          className="profile-carousel__esc-btn"
          onClick={() => isGallery ? closeModal() : closeChildModal()}
        >
          <ICONS.close className="profile-carousel__esc-btn-icon" /> (Esc)
        </button>

        {props.disableBack &&
          <button
            className="profile-carousel__back-btn"
            onClick={() => closeChildModal()}
          >
            <ICONS.arrowCircleThin className="profile-carousel__back-btn-icon" /> (Back)
          </button>
        }
      </div>
      
      <div className="profile-carousel__holder">
        <Carousel
          media={media}
          classNamePrefix="profile-carousel"
          spinnerSize={100}
          activeSlide={activeSlide}
          isNotProfile={isNotProfile}
        />
      </div>

      {media.length > 1 && 
        <CarouselNavigation
          isModal
          mediaLength={media.length}
          classNamePrefix="profile-carousel"
          setActiveSlide={setActiveSlide}
        />
      }

    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  images: ownProps.id
    ? state.divaGirls.entities[ownProps.id].images
    : ownProps.images,
  avatar: ownProps.id && state.divaGirls.entities[ownProps.id].avatar,
});

const mapDispatchToProps = {
  closeModal,
  closeChildModal
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCarousel);

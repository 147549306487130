import { useDispatch, useSelector } from 'react-redux';
import { memo } from 'react';

import { BOOKING_ITEM_VIEWS } from './config/constants';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import getBookingConfirmationStatusColor from './helpers/getBookingConfirmationStatusColor';
import getActualMarkers from './helpers/getActualMarkers';
import { getSessionById, normalizeSession, showSessionFromHistory, updateActiveSession } from 'redux/ducks/sessions';
import getBookingColorByStage from './helpers/getBookingColorByStage';

import './BookingItemNew.scss';
import BookingConfirmationStatus from './components/BookingConfirmationStatus/BookingComfirmationStatus';
import BookingClientItem from './views/BookingClientItem/BookingClientItem';
import BookingConfinedItem from './views/BookingConfinedItem/BookingConfinedItem';
import BookingGirlItem from './views/BookingGirlItem/BookingGirlItem';
import BookingLinedItem from './views/BookingLinedItem/BookingLInedItem';
import BookingTableItem from './views/BookingTableItem/BookingTableItem';
import BookingDetails from './components/BookingDetails/BookingDetails';
import BookingOperator from './components/BookingOperator/BookingOperator';
import BookingDate from './components/BookingDate/BookingDate';
import BookingEscort from './components/BookingEscort/BookingEscort';
import BookingClient from './components/BookingClient/BookingClient';
import BookingMarkers from './components/BookingMarkers/BookingMarkers';
import BookingBadges from './components/BookingBadges/BookingBadges';
import BookingStatus from './components/BookingStatus/BookingStatus';
import BookingWeekItem from './views/BookingWeekItem/BookingWeekItem';
import BookingShortLinedItem from './views/BookingShortLInedItem/BookingShortLinedItem';
import BookingModalLinedItem from './views/BookingModalLinedItem/BookingModalLInedItem';
import BookingSessionItem from './views/BookingSessionItem/BookingSessionItem';
import BookingNotes from './components/BookingNotes/BookingNotes';
import BookingClientPinnedItem from './views/BookingClientPinnedItem/BookingClientPinnedItem';
import BookingRequirements from './components/BookingRequirements/BookingRequirements';
import BookingAttachmentItem from './views/BookingAttachmentItem/BookingAttachementItem';
import BookingGirlPinnedItem from './views/BookingGirlPinnedItem/BookingGirlPinnedItem';
import getBookingStates from './helpers/getBookingStates';
import API from '../../api/api';
import BookingGTabItem from './views/BookingGTabItem/BookingGTabItem';
import ExistedBookingItem from './views/ExistedBookingItem/ExistedBookingItem';
import { LS } from '../../utils';


const viewComponents = {
  [BOOKING_ITEM_VIEWS.CONFINED]: BookingConfinedItem,
  [BOOKING_ITEM_VIEWS.LINED]: BookingLinedItem,
  [BOOKING_ITEM_VIEWS.TABLE]: BookingTableItem,
  [BOOKING_ITEM_VIEWS.GIRL]: BookingGirlItem,
  [BOOKING_ITEM_VIEWS.CLIENT]: BookingClientItem,
  [BOOKING_ITEM_VIEWS.WEEK]: BookingWeekItem,
  [BOOKING_ITEM_VIEWS.SHORT_LINED]: BookingShortLinedItem,
  [BOOKING_ITEM_VIEWS.MODAL_LINED]: BookingModalLinedItem,
  [BOOKING_ITEM_VIEWS.SESSION]: BookingSessionItem,
  [BOOKING_ITEM_VIEWS.CLIENT_PINNED]: BookingClientPinnedItem,
  [BOOKING_ITEM_VIEWS.GIRL_PINNED]: BookingGirlPinnedItem,
  [BOOKING_ITEM_VIEWS.ATTACHMENT]: BookingAttachmentItem,
  [BOOKING_ITEM_VIEWS.GTab]: BookingGTabItem,
  [BOOKING_ITEM_VIEWS.Form]: ExistedBookingItem,
}

const containerComponents = {
  ConfirmationStatus: BookingConfirmationStatus,
  Details: BookingDetails,
  Operator: BookingOperator,
  Date: BookingDate,
  Escort: BookingEscort,
  Client: BookingClient,
  Markers: BookingMarkers,
  Badges: BookingBadges,
  Status: BookingStatus,
  Notes: BookingNotes,
  Requirements: BookingRequirements
}

export const BookingItemNew = (props) => {
  const {
    item,
    type,
    className,
    getCallbackRef,
    onClick,
    onDelete,
    ...restProps
  } = props;
  
  const sessionId = item.session_id || item.sessionId;

  const currentUserId = useSelector((state) => state.user.id);
  const isSalesPageOpen = useSelector((state) => state.user.isSalesPageOpen);
  const session = useSelector((state) => state.sessions.entities[sessionId]);
  const dispatch = useDispatch();

  const innerProps = Object.entries(containerComponents)
    .reduce((acc, [name, Component]) => ({
      ...acc,
      [name]: (props) => (
        <Component booking={item} {...props} />
      )
    }), {});
  

  innerProps.confirmationStatus = item.confirmation_status;
  innerProps.isDeleted = !!item.is_deleted;
  innerProps.statusColor = item.confirmation_status ? getBookingConfirmationStatusColor(item.confirmation_status) : null;
  innerProps.bookingColor = getBookingColorByStage(item)
  innerProps.innerRef = getCallbackRef ? getCallbackRef(item.id) : null;
  innerProps.getActualMarkers = getActualMarkers(item);
  innerProps.isSameCaller = (callerObj) => {
    if (callerObj?.clientId) {
      return [item.callerId, +item.caller_id].includes(callerObj.clientId);
    }

    if (callerObj?.girlId) {
      return [item.profile_id, item.profile_id_duo].includes(callerObj.girlId);
    }

    return false;
  }
		
  innerProps.handleOpenBookingModal = () => {
    dispatch(openModal(MODAL_TYPES.bookingEditForm, {
      booking: item,
      profileId: item.profile_id,
      callerId: item.callerId,
    }))
  }

  innerProps.handleOpenSession = () => {
    if (session) {
      API.switchSalesSession(sessionId);

      dispatch(updateActiveSession(sessionId));
    } else {
      API.switchSalesSession(sessionId, true);

      dispatch(getSessionById(sessionId))
        .then(({ data }) => dispatch(showSessionFromHistory(normalizeSession(data))))
        .catch(err => console.error(err));
    }

    if (!isSalesPageOpen) {
      LS.setItem("aSession", sessionId, currentUserId);
      window.open('/sales', '_blank');
    }
  }

  innerProps.getBookingStates = getBookingStates(item);

  const View = viewComponents[type];

  return <View className={className} {...restProps} {...innerProps} />
}

export default memo(
  BookingItemNew,
	(oldProps, newProps) => {
    return oldProps.id === newProps.id
      && oldProps.isHidden === newProps.isHidden
      && oldProps.checkbox === newProps.checkbox
      && JSON.stringify(oldProps.oppenedChatCallerId) === JSON.stringify(newProps.oppenedChatCallerId)
  }
);

import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useForm, useFormState } from 'react-final-form';
import { v4 } from 'uuid';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useDidUpdate } from 'hooks';
import { classModifier, convertDateFromUTC } from 'utils';
import popperViewportCenter from 'config/popperViewportCenter';

import './BookingSpecialRequestMessages.scss';
import BookingSpecialRequestMessageItem from './BookingSpecialRequestMessageItem';
import { setBookingLog } from 'redux/ducks/bookings';

const BookingSpecialRequestMessages = ({
  isEditBooking,
  updateBookingsList,
  callerId,
  bookingId,
  isDisabled,
  isRequestMessageFieldOpen,
  setIsRequestMessageFieldOpen,
  getBookingLog,
  isFormEmpty
}) => {
  const formState = useFormState();
  const initialFormState = formState.values.scheduleMessages?.length 
    ? formState.values.scheduleMessages 
    : [{ uid: v4(), body: '' }];

  const [requestList, setRequestList] = useState(initialFormState);
  const [deletedMsgsList, setDeletedMsgsList] = useState([]);

  const form = useForm();

  const isOneElement = requestList.length === 1;
  const isAnyEmptyItemData = requestList.some(item => !item.body || !item.date);
  const isDeletedAllItems = isOneElement && !requestList.every(item => item.body || item.date);

  useDidUpdate(() => {
    if(!isRequestMessageFieldOpen) {
      setRequestList(initialFormState);
      setDeletedMsgsList([]);
    }
  }, [isRequestMessageFieldOpen]);

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isRequestMessageFieldOpen,
    onVisibleChange: setIsRequestMessageFieldOpen,
  }, {
    modifiers: [popperViewportCenter]
  });

  const handleSaveRequest = () => {
    let clearRequestList = requestList.filter(i => i.body).filter(i => i.date);
    
    if(isEditBooking) {
      const resNewMsgs = clearRequestList
        ?.filter(obj => obj.uid && !obj.id)
        .map(obj => {
          return API.createScheduledMsg({
            ...obj,
            date: +new Date(obj.date),
            contactId: callerId,
            bookingId,
            uid: obj.uid
          })
        });
      
      !!resNewMsgs.length && 
        Promise.all(resNewMsgs)
          .then(response => {
            const data = response.map(res => res.data);

            clearRequestList = clearRequestList.map(item => {
              const foundedMsgByUid = data.find(i => i.uid === item.uid);

              if(foundedMsgByUid) {
                return { ...foundedMsgByUid, date: +convertDateFromUTC(foundedMsgByUid.date)};
              } else return item;
            });

            setRequestList(clearRequestList);
            form.change('scheduleMessages', clearRequestList);
  
            updateBookingsList && updateBookingsList(prevState => {
              const updatedBookingsList = prevState.map(booking => {
                return booking.id === data[0].booking_id
                  ? { 
                    ...booking, 
                      scheduleMessages: clearRequestList
                    } 
                  : booking;
              });
              return updatedBookingsList;
            })
          })
          .catch(console.error)
          .finally(setBookingLog);


      clearRequestList?.forEach((obj, idx) => {
        const isNewScheduledMessage = obj.uid && !obj.id;

        if(!isNewScheduledMessage) {
          // edit schedule msgs
          const isEdit = obj?.body !== initialFormState[idx]?.body || +new Date(obj?.date) !== +new Date(initialFormState[idx]?.date);
      
          if(isEdit) {
            API.updateScheduledMsg({
              ...obj,
              date: +new Date(obj.date),
              contactId: obj.caller?.id,
              bookingId: obj.booking_id
            })
            .then(({ data }) => {
              const updatedList = clearRequestList.map(item => {
                if(item.id === data.id) {
                  return data;
                } else return item;
              });
    
              setRequestList(updatedList);
              form.change('scheduleMessages', updatedList);
              
              updateBookingsList && updateBookingsList(prevState => {
                const updatedBookingsList = prevState.map(booking => {
                  return booking.id === data.booking_id
                    ? { 
                      ...booking, 
                        scheduleMessages: booking.scheduleMessages.map(msg => {
                          return msg.id === data.id ? data : msg
                        })
                      } 
                    : booking;
                });
                return updatedBookingsList;
              })
            })
            .catch(console.error);
          }
        }
      });
  
      // delete schedule msgs
      if(!!deletedMsgsList.length) {
        deletedMsgsList.forEach(id => typeof id === 'number' && API.deleteScheduledMsg(id)
          .then(() => {  
            form.change('scheduleMessages', clearRequestList);

            updateBookingsList && updateBookingsList(prevState => {
              return prevState.map(booking => {
                if(booking.id === bookingId) {
                  return { 
                    ...booking, 
                    scheduleMessages: booking.scheduleMessages.filter(msg => !deletedMsgsList.includes(msg.id))
                  }
                } else return booking;
              });
            }
          )})
        );
      }
    } else {
      form.change('scheduleMessages', clearRequestList);
    }
    
    setTimeout(getBookingLog, 2000);
    setIsRequestMessageFieldOpen(false);
  }

  const tooltipProps = getTooltipProps({
    className: 'booking-special-request-messages__tooltip',
  });

  const removeField = (id) => {
    const updatedList = requestList.filter(item => item.id !== id);

    if(!!updatedList.length) {
      setRequestList(updatedList);
    } else setRequestList([{ uid: v4(), body: '', date: '' }]);

    setDeletedMsgsList([...deletedMsgsList, id]);
  }

  const addEmptyRequestField = () => {
    const updatedList = requestList.concat([{ uid: v4(), body: '' }]);

    setRequestList(updatedList);
  }

  const handleSetRequestListItem = (data, id, field) => {
    setRequestList(prevState => 
      prevState.map(item => 
        item.uid === id || item.id === id 
          ? { ...item, [field]: data }
          : item
      )
    )
  };

  return (
    <div className='booking-special-request-messages'>
      <button 
        type='button'  
        ref={setTriggerRef}
        className={classModifier('booking-form__btn', ['request-messages', isEditBooking && 'booking-editor'])}
        disabled={isDisabled || isFormEmpty}
      >
        <ICONS.pencil className='booking-form__btn-icon'/>
      </button>

      {isRequestMessageFieldOpen &&
        <div ref={setTooltipRef} {...tooltipProps}>
          <div className='booking-special-request-messages__header'>
            <h2 className='booking-special-request-messages__title'>Special Request</h2>
            <div className='booking-special-request-messages__btns-wrapper'>
              <button 
                type='button' 
                onClick={() => setIsRequestMessageFieldOpen(false)}
                className={classModifier('booking-special-request-messages__btn', 'cancel')}
              >
                Cancel
              </button>
              <button
                type='button'
                disabled={!deletedMsgsList.length && requestList.some(i => !i.body || !i.date)}
                onClick={handleSaveRequest}
                className={classModifier('booking-special-request-messages__btn', 'save')}
              >
                Save
                <ICONS.check className={classModifier('booking-special-request-messages__btn-icon', 'check')}/>
              </button>
            </div>
          </div>

          <div className='booking-special-request-messages__main'>
            {requestList.map((item) => {
              return (
                <BookingSpecialRequestMessageItem 
                  key={item.id || item.uid}
                  item={item}
                  isOneElement={isDeletedAllItems}
                  isAnyEmptyItemData={isAnyEmptyItemData}
                  removeField={removeField}
                  addEmptyRequestField={addEmptyRequestField}
                  setRequestItemData={(data, field) => handleSetRequestListItem(data, item.uid || item.id, field)}
                />
              )
            })}
          </div>
        </div>
      }
    </div>
  )
}

export default BookingSpecialRequestMessages;

import React, { Component } from 'react';
import { connect } from 'react-redux';

import LS from 'utils/localStorageAPI';
import {
  getContactTimeline,
  updateContactTimeline,
  updateActiveContact,
  sendMessage,
  removeContactTab,
  removeContactTabsFromContextMenu,
  markContactChatAsRead,
  getTimelineMedia,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  getContactMessageContext,
  updateContactMessageContext,
  deletePinnedMessage,
  pinClientMsg,
  getContactReminders,
  cleanContactReminders,
  updateContactReminders,
  removeMessageReminder,
  updateContactMessageSearch,
  stopContactMessageSearch,
  searchContactMessage,
  searchGlobalContactMessage,
  updateGlobalContactMessageSearch,
  getContactDateMsgContext,
  cleanContactDateMsgContext,
  updateContactDateMsgContext,
  getScheduledMsgs,
  cleanScheduledMsgs,
  updateScheduledMsgs,
  getScheduledMsgsCount,
} from 'redux/ducks/clientChats';
import {
  playMedia,
  openModal,
  closeModal,
  MODAL_TYPES,
} from 'redux/ducks/activeWindows';
import {
  cleanServiceMsgs,
  fixGirlTab,
  getServiceMsgs,
  getServiceMsgsCount,
  updateServiceMsgs
} from 'redux/ducks/girlChats';
import { updateContactInState, addNewArrayGirlsToState } from 'redux/ducks/contacts';
import {
  selectActiveGirlTabId,
  selectGirlChatsField,
  selectUserTimezone
} from 'redux/selectors/selectors';
import API from 'api/api';
import { CHAT_SOURCES, CHAT_TYPES } from 'config/constants';
import { getDateByTimezoneOffset } from 'utils';
import { getUnfinishedBookingsByGirlId } from 'redux/ducks/bookings';
import ICONS from 'assets/icons';

import Title from 'components/UI/Title/Title';
import ChatRestore from './components/ChatRestore';
import Chat from './Chat';

class GirlChat extends Component {
  state = {
    repliedMsg: null,
    editedMsg: null,
    voiceMsg: null,
    images: null,
  }

  componentDidMount() {
    if (this.props.active && !this.props.timeline.length) {
      this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, false);
      this.props.getScheduledMsgsCount(this.props.active, CHAT_TYPES.GIRL);
      this.props.getServiceMsgsCount(this.props.active);
    }

    let urlParams = new URLSearchParams(window.location.search);
    let webMasterId = urlParams.get('webMasterId');
    let contactId = urlParams.get('contactId');

    window.history.replaceState(null, null, window.location.pathname);

    if (webMasterId && contactId) {
      this.getContactMsgContext(+webMasterId, { id: +contactId, type: CHAT_TYPES.GIRL });
    }

    if (this.props.active) {
      const { diva_default_id, diva_id } = this.props.activeRecipient;

      this.props.getUnfinishedBookingsByGirlId(diva_default_id || diva_id, Date.parse(getDateByTimezoneOffset(this.props.userTimezone)))
    }
  }

  componentWillUnmount() {
    if (this.state.images) {
      this.cleanImages();
    }
    if (this.state.videos) {
      this.cleanVideos();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { activeChatSource } = this.props;

    if (prevProps.tabs !== this.props.tabs) {
      LS.setItem('gTabs', this.props.tabs, this.props.userId);
    }
    if (prevProps.unfixedTab !== this.props.unfixedTab) {
      LS.setItem('unfixedGirlTab', this.props.unfixedTab, this.props.userId);
    }

    if (!this.props.activeRecipient) return

    if (prevProps.activeGroup !== this.props.activeGroup) {
      if (this.props.activeGroup !== null && this.props.activeGroup !== 0) {
        const withCancel = !!prevProps.activeGroup;

        this.props.getContactTimeline(this.props.activeGroup, CHAT_TYPES.GIRL, this.props.userTimezone, withCancel, '', true);
      }
    }

    if (prevProps.active !== this.props.active) {
      if (this.props.active !== null) {
        const withCancel = !!prevProps.active;

        this.props.getScheduledMsgsCount(this.props.active, CHAT_TYPES.GIRL, withCancel);
        this.props.getServiceMsgsCount(this.props.active);

        if (this.props.girlsSearchSource === CHAT_SOURCES.MSGS && this.props.girlsSearch) {
          this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, withCancel, this.props.girlsSearch);
        }
        else {
          if (activeChatSource === CHAT_SOURCES.SYSTEM_MSGS && activeChatSource === prevProps.activeChatSource) {
            this.props.getServiceMsgs(this.props.activeRecipient, this.props.userTimezone);
          }
          if (activeChatSource === CHAT_SOURCES.MSGS && activeChatSource === prevProps.activeChatSource) {
            this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, !!prevProps.active);
          }
        }

        if (this.props.active !== 'new_chat') {
          const { diva_default_id, diva_id } = this.props.activeRecipient;

          this.props.getUnfinishedBookingsByGirlId(diva_default_id || diva_id, Date.parse(getDateByTimezoneOffset(this.props.userTimezone)))
        }
      }
      if (this.state.editedMsg) {
        this.cleanEditedMsg();
      }
      if (this.state.voiceMsg) {
        this.updateVoiceMsg();
      }
      if (this.state.images) {
        this.cleanImages();
      }
      if (this.state.videos) {
        this.cleanVideos();
      }
      LS.setItem('aGirl', this.props.active, this.props.userId);
    }
    if (activeChatSource !== prevProps.activeChatSource) {
      if (activeChatSource === CHAT_SOURCES.REMINDERS) {
        this.props.getContactReminders(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.getTimelineMedia(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        this.props.getScheduledMsgs(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
        this.props.getServiceMsgs(this.props.activeRecipient, this.props.userTimezone);
      }
      else if (activeChatSource === CHAT_SOURCES.MSGS) {
        this.props.getContactTimeline(this.props.active, CHAT_TYPES.GIRL, this.props.userTimezone, !!prevProps.active);
      }

      if (prevProps.activeChatSource === CHAT_SOURCES.REMINDERS) {
        this.props.cleanContactReminders(CHAT_TYPES.GIRL);
      }
      else if (prevProps.activeChatSource === CHAT_SOURCES.MEDIA) {
        this.props.cleanTimelineMedia(CHAT_TYPES.GIRL);
      }
      else if (prevProps.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        this.props.cleanScheduledMsgs(CHAT_TYPES.GIRL);
      }
      else if (prevProps.activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
        this.props.cleanServiceMsgs();
      }
    }
  }


  replyMsg = (msg) => this.setState({ repliedMsg: msg });
  cleanRepliedMsg = () => this.setState({ repliedMsg: null });

  editMsg = (msg) => this.setState({ editedMsg: msg });
  cleanEditedMsg = () => this.setState({ editedMsg: null });

  updateVoiceMsg = (blob, duration) => {
    if (blob && duration) {                 // add voiceMsg
      this.setState({
        voiceMsg: {
          blob,
          url: URL.createObjectURL(blob),
          duration
        }
      });
    }
    else if (this.state.voiceMsg) {         // clean voiceMsg
      this.setState({ voiceMsg: null });
    }
  }

  updateImages = (images) => {
    const imagesWithUrl = images.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ images: imagesWithUrl });
  }
  cleanImages = () => {
    this.state.images.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ images: null });
  }

  updateVideos = (videos) => {
    const videosWithUrl = videos.map(file => Object.assign(file, {
      url: URL.createObjectURL(file)
    }));

    this.setState({ videos: videosWithUrl });
  }
  cleanVideos = () => {
    this.state.videos.forEach(file => URL.revokeObjectURL(file.url));
    this.setState({ videos: null });
  }

  timeline = () => {
    if (!this.props.activeGroup) {
      if (this.props.contextMsgId) {
        return this.props.auxiliaryTimeline;
      } else if (this.props.contextDate) {
        return this.props.auxiliaryTimeline;
      } else if (this.props.search) {
        return this.props.auxiliaryTimeline;
      } else if (this.props.activeChatSource !== CHAT_SOURCES.MSGS) {
        return this.props.auxiliaryTimeline;
      }
      return this.props.timeline;
    }
    return this.props.timeline;
  }

  updateTimeline = () => {
    if (!this.props.activeGroup) {
      if (this.props.contextMsgId) {
        return this.updateMsgContext;
      }
      else if (this.props.contextDate) {
        return this.updateDateMsgContext;
      }
      else if (this.props.search && this.props.isGlobalSearch) {
        return this.updateGlobalContactMessageSearch;
      }
      else if (this.props.search) {
        return this.updateMsgSearch;
      }
      else if (this.props.activeChatSource === CHAT_SOURCES.MEDIA) {
        return this.updateContactTimelineMedia;
      }
      else if (this.props.activeChatSource === CHAT_SOURCES.REMINDERS) {
        return this.updateContactReminders;
      }
      else if (this.props.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS) {
        return this.updateScheduledMsgs;
      }
      else if (this.props.activeChatSource === CHAT_SOURCES.SYSTEM_MSGS) {
        return this.updateServiceMsgs;
      }
      return this.updateContactTimeline;
    }
    return this.updateContactTimeline;
  }

  getContactMsgContext = (msgId, contact, searchQuery) =>
    this.props.getContactMessageContext(msgId, CHAT_TYPES.GIRL, contact, searchQuery, this.props.userTimezone);

  updateMsgContext = (activeRecipient, page, loadDirection) => this.props.updateContactMessageContext(this.props.contextMsgId, page, loadDirection, activeRecipient, this.props.userTimezone);

  getContactDateMsgContext = (date) =>
    this.props.getContactDateMsgContext(date, this.props.activeRecipient.id, CHAT_TYPES.GIRL, this.props.userTimezone);

  updateDateMsgContext = (activeRecipient, page, loadDirection) =>
    this.props.updateContactDateMsgContext(activeRecipient, page, loadDirection, this.props.contextDate, this.props.userTimezone);


  startMessageSearch = (query) =>
    this.props.searchContactMessage(query, this.props.activeRecipient, this.props.userTimezone);

  updateMsgSearch = (activeRecipient, page, loadDirection, isArchive) =>
    this.props.updateContactMessageSearch(activeRecipient, page, loadDirection, this.props.search, this.props.userTimezone, (isArchive || this.props.isAuxiliaryArchiveDisplayed));

  stopMessageSearch = () => this.props.stopContactMessageSearch(this.props.activeRecipient);

  updateContactTimeline = (activeRecipient, page, loadDirection, isArchive) =>
    this.props.updateContactTimeline(
      !!this.props.activeGroup ? { id: this.props.activeGroup, type: 2 } : activeRecipient,
      page,
      loadDirection,
      this.props.userTimezone,
      !!this.props.activeGroup,
      (isArchive || this.props.isArchiveDisplayed)
    );

  updateGlobalContactMessageSearch = (activeRecipient, page, loadDirection, isArchive) =>
    this.props.updateGlobalContactMessageSearch(activeRecipient, page, this.props.search, loadDirection, this.props.userTimezone, (isArchive || this.props.isAuxiliaryArchiveDisplayed));

  updateContactTimelineMedia = (activeRecipient, page, loadDirection, isArchive) =>
    this.props.updateContactTimelineMedia(activeRecipient, page, loadDirection, this.props.userTimezone, (isArchive || this.props.isAuxiliaryArchiveDisplayed));

  updateContactReminders = (activeRecipient, page, loadDirection) =>
    this.props.updateContactReminders(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateScheduledMsgs = (activeRecipient, page, loadDirection) =>
    this.props.updateScheduledMsgs(activeRecipient, page, loadDirection, this.props.userTimezone);

  updateServiceMsgs = (activeRecipient, page, loadDirection) =>
    this.props.updateServiceMsgs(activeRecipient, page, loadDirection, this.props.userTimezone);

  showTimePicker = async () => this.props.openModal(
    MODAL_TYPES.timePicker, {
    // action: this.getContactDateMsgContext,
    onSelectDate: date => {
      this.getContactDateMsgContext(date - (this.props.userTimezone - (new Date().getTimezoneOffset() * (-1))) * 60000);
      this.props.closeModal();
    },
    maxDate: getDateByTimezoneOffset(this.props.userTimezone),
    userTimezone: this.props.userTimezone
  }
  );

  changeRecipientAudioStatus = (action, recipientId) =>
    API.setContactAudioStatus(action, recipientId)
      .then(({ data }) => {
        this.props.updateContactInState(data.caller, data.caller.type);
      })
      .catch(console.error);

  render() {
    const isMainTimelineOpen = !!this.props.activeGroup ||
      !(
        this.props.search ||
        this.props.activeChatSource !== CHAT_SOURCES.MSGS ||
        this.props.contextMsgId ||
        this.props.contextDate
      );

    const timelineLowerLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryLowerLoadedPage
      : this.props.timelineLowerLoadedPage;

    const timelinePageCount = !isMainTimelineOpen
      ? this.props.auxiliaryPageCount
      : this.props.timelinePageCount;

    const timelineHigherLoadedPage = !isMainTimelineOpen
      ? this.props.auxiliaryHigherLoadedPage
      : this.props.timelineCurrentPage;

    const timelineCurrentPage = !isMainTimelineOpen
      ? this.props.auxiliaryCurrentPage
      : this.props.timelineCurrentPage;

    return this.props.activeRecipient
      ? (
        <Chat
          girlChat
          title="Conversations with Girls"
          type={CHAT_TYPES.GIRL}
          activeRecipient={this.props.activeRecipient}
          updateActiveContact={this.props.updateActiveContact}
          profileId={this.props.userId}
          userTimezone={this.props.userTimezone}
          userHour12={this.props.userHour12}
          notForClients={this.props.notForClients}

          activeGroup={this.props.activeGroup}
          activeGroupType={this.props.activeGroupType}

          tabs={this.props.tabs}
          isTabsDraggable
          withUnfixedTab
          unfixedTab={this.props.unfixedTab}
          fixTab={this.props.fixGirlTab}
          removeContactTab={this.props.removeContactTab}
          removeContactTabsFromContextMenu={this.props.removeContactTabsFromContextMenu}
          isBufferChat={this.props.isBufferChat}

          timelinePending={this.props.timelinePending}
          updatePending={this.props.updatePending}
          isMainTimelineOpen={isMainTimelineOpen}
          timeline={this.timeline()}
          timelineCurrentPage={timelineCurrentPage}
          timelinePageCount={timelinePageCount}
          timelineHigherLoadedPage={timelineHigherLoadedPage}
          timelineLowerLoadedPage={timelineLowerLoadedPage}
          newInteractionType={this.props.newInteractionType}
          updateContactTimeline={this.updateTimeline()}

          isArchiveDisplayed={this.props.isArchiveDisplayed}
          isAuxiliaryArchiveDisplayed={this.props.isAuxiliaryArchiveDisplayed}
          hasArchive={this.props.hasArchive}
          auxiliaryHasArchive={this.props.auxiliaryHasArchive}

          msgTemplates={this.props.msgTemplates}
          shortcuts={this.props.shortcuts}
          typingStatus={this.props.typingStatus}

          search={this.props.search}
          startMessageSearch={this.startMessageSearch}
          stopMessageSearch={this.stopMessageSearch}
          isGlobalSearch={this.props.isGlobalSearch}
          startGlobalMsgSearch={this.props.searchGlobalContactMessage}
          showSearchQuery={this.props.showSearchQuery}
          updateGlobalContactMessageSearch={this.updateGlobalContactMessageSearch}

          contextMsgId={this.props.contextMsgId}
          getMessageContext={this.getContactMsgContext}

          contextDate={this.props.contextDate}
          cleanContactDateMsgContext={this.props.cleanContactDateMsgContext}
          showTimePickerForDateContext={this.props.activeChatSource !== CHAT_SOURCES.MSGS ? null : this.showTimePicker}

          pinnedMsgs={this.props.pinnedMsgs}
          pinMsg={this.props.pinClientMsg}
          unpinMsg={this.props.deletePinnedMessage}

          activeChatSource={!this.props.activeGroup ? this.props.activeChatSource : CHAT_SOURCES.MSGS}

          removeMessageReminder={this.props.removeMessageReminder}

          replyMsg={this.replyMsg}
          repliedMsg={this.state.repliedMsg}
          cleanRepliedMsg={this.cleanRepliedMsg}

          editMsg={this.editMsg}
          editedMsg={!this.props.activeGroup ? this.state.editedMsg : null}
          cleanEditedMsg={this.cleanEditedMsg}

          voiceMsg={!this.props.activeGroup ? this.state.voiceMsg : null}
          updateVoiceMsg={this.updateVoiceMsg}

          images={this.state.images}
          updateImages={this.updateImages}
          cleanImages={this.cleanImages}

          videos={this.state.videos}
          updateVideos={this.updateVideos}
          cleanVideos={this.cleanVideos}

          addNewArrayGirlsToState={this.props.addNewArrayGirlsToState}
          sendMessage={this.props.sendMessage}
          openModal={this.props.openModal}
          playMedia={this.props.playMedia}
          markChatAsRead={this.props.markChatAsRead}
          changeRecipientAudioStatus={this.changeRecipientAudioStatus}
          callFromChat
          fullScreenMode={this.props.fullScreenMode}
          isMsgInput={true}
          isShowUndoTabs={true}
          isScheduledMsgsSource={this.props.activeChatSource === CHAT_SOURCES.SCHEDULED_MSGS}
          scheduledMsgsCount={this.props.scheduledMsgsCount}
          serviceMsgCount={this.props.serviceMsgCount}

          searchSource={this.props.girlsSearchSource}
        />
      ) : (
        <div className="chat">
          <div className="chat__title title">
            <Title
              icon={ICONS.comments}
              text={this.props.title}
              count={this.props.tabs.length}
            />
          </div>

          <ChatRestore
            recentTabs={this.props.recentTabs}
            type={CHAT_TYPES.GIRL}
          />
        </div>
      );
  }
}

const mapStateToProps = (state, ownProps) => ({
  active: state.girlChats.active,
  activeGroup: state.girlChats.activeGroup,
  activeGroupType: state.girlChats.activeGroupType,
  activeRecipient: state.contacts.entities[state.girlChats.active || ownProps.currentCallerId],
  tabs: state.girlChats.tabs,
  unfixedTab: state.girlChats.unfixedTab,
  recentTabs: state.girlChats.recentTabs,

  timeline: selectGirlChatsField(state, 'timeline'),
  timelinePending: selectGirlChatsField(state, 'timelinePending'),
  updatePending: selectGirlChatsField(state, 'updatePending'),
  timelineLowerLoadedPage: selectGirlChatsField(state, 'timelineLowerLoadedPage'),
  timelinePageCount: selectGirlChatsField(state, 'timelinePageCount'),
  timelineCurrentPage: selectGirlChatsField(state, 'timelineCurrentPage'),
  newInteractionType: selectGirlChatsField(state, 'newInteractionType'),

  timelineMedia: state.girlChats.timelineMedia,

  auxiliaryTimeline: state.girlChats.auxiliaryTimeline,
  auxiliaryLowerLoadedPage: state.girlChats.auxiliaryLowerLoadedPage,
  auxiliaryPageCount: state.girlChats.auxiliaryPageCount,
  auxiliaryHigherLoadedPage: state.girlChats.auxiliaryHigherLoadedPage,
  auxiliaryCurrentPage: state.girlChats.auxiliaryCurrentPage,

  reminders: state.girlChats.reminders,

  isGlobalSearch: selectGirlChatsField(state, 'isGlobalMsgSearch'),
  showSearchQuery: selectGirlChatsField(state, 'showSearchQuery'),

  msgTemplates: state.msgTemplates.girlsTemplates,
  shortcuts: state.msgTemplates.shortcuts,

  search: selectGirlChatsField(state, 'search'),

  contextMsgId: state.girlChats.contextMsgId,
  contextDate: selectGirlChatsField(state, 'contextDate'),

  userId: state.user.id,
  userTimezone: selectUserTimezone(state),
  userHour12: state.user.hour12,

  pinnedMsgs: state.girlChats.pinnedMsgs,
  activeChatSource: state.girlChats.chatSource,

  typingStatus: state.typingOperators.chats[state.girlChats.active + '_' + CHAT_TYPES.GIRL],
  scheduledMsgsCount: selectGirlChatsField(state, 'scheduledMsgsCount'),
  serviceMsgCount: selectGirlChatsField(state, 'serviceMsgCount'),

  girlsSearchSource: state.contacts.girls.searchSource,
  girlsSearch: state.contacts.girls.search,

  isArchiveDisplayed: state.girlChats.isArchiveDisplayed,
  isAuxiliaryArchiveDisplayed: state.girlChats.isAuxiliaryArchiveDisplayed,

  hasArchive: state.girlChats.hasArchive,
  auxiliaryHasArchive: state.girlChats.auxiliaryHasArchive,
});


const mapDispatchToProps = {
  closeModal,
  updateContactInState,
  getContactTimeline,
  updateContactTimeline,
  updateContactMessageSearch,
  updateActiveContact,
  sendMessage,
  removeContactTab,
  removeContactTabsFromContextMenu,
  getContactMessageContext,
  markChatAsRead: markContactChatAsRead,
  getTimelineMedia,
  getContactReminders,
  cleanContactReminders,
  cleanTimelineMedia,
  updateContactTimelineMedia,
  stopContactMessageSearch,
  searchContactMessage,
  searchGlobalContactMessage,
  updateGlobalContactMessageSearch,
  pinClientMsg,
  deletePinnedMessage,
  fixGirlTab,
  playMedia,
  removeMessageReminder,
  updateContactMessageContext,
  updateContactReminders,
  getContactDateMsgContext,
  cleanContactDateMsgContext,
  updateContactDateMsgContext,
  openModal,
  addNewArrayGirlsToState,

  getScheduledMsgs,
  updateScheduledMsgs,
  cleanScheduledMsgs,
  getScheduledMsgsCount,

  getServiceMsgs,
  updateServiceMsgs,
  cleanServiceMsgs,
  getServiceMsgsCount,
  getUnfinishedBookingsByGirlId,
};

export default connect(mapStateToProps, mapDispatchToProps)(GirlChat);

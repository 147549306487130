import React, { useState } from 'react';
import FeedbackAddBtn from 'components/FeedbackAddBtn/FeedbackAddBtn';
import FeedbackList from 'components/FeedbackList/FeedbackList';
import ToggleSwitch from 'components/ToggleSwitch/ToggleSwitch';
import ProfileReviews from 'components/ProfileReviews/ProfileReviews';
import { CONTACT_TYPES } from 'config/constants';


const FeedbacksReviewsTab = (props) => {
  const {
    feedbackList,
    editedContact,
    setFeedbackList,
    activeContactInForm,
    isNewContactCreation,
  } = props;

  const [isFeedbacks, setIsFeedbacks] = useState(true);

  const handleToggleChange = (e) => {
    e.preventDefault();
    setIsFeedbacks(!isFeedbacks);
  };

  return (
    <>
      {+activeContactInForm.type === CONTACT_TYPES.GIRL && 
        <div className='profile-reviews__toggle-wrap' onClick={handleToggleChange}>
          Feedbacks
          <ToggleSwitch isChecked={!isFeedbacks} />
          Reviews
        </div>
      }

      {isFeedbacks 
        ? <>
            <div>
              <FeedbackAddBtn
                activeContact={activeContactInForm}
                setFeedbackList={setFeedbackList}
              />
            </div>

            <FeedbackList
              contactId={isNewContactCreation ? null : editedContact.id}
              feedbackList={feedbackList}
              setFeedbackList={setFeedbackList}
              activeContact={activeContactInForm}
            />
          </>
        : <ProfileReviews
            isContactTab
            className="profile-reviews-tab"
            id={activeContactInForm.diva_default_id} 
          />
      }
    </>
  )
}

export default FeedbacksReviewsTab;
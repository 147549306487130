import { ReactComponent as IconAddressCard1 } from './address-card-1.svg';
import { ReactComponent as IconAddressCard2 } from './address-card-2.svg';
import { ReactComponent as IconAdrBook } from './address-book.svg';
import { ReactComponent as IconAdrBookInvert } from './address-book-invert.svg';
import { ReactComponent as IconAngleQuote } from './angle-quote.svg';
import { ReactComponent as IconBell } from './bell.svg';
import { ReactComponent as IconBellFill } from './bell-fill.svg';
import { ReactComponent as IconBooking } from './booking.svg';
import { ReactComponent as IconBuffer } from './buffer.svg';
import { ReactComponent as IconCalendar } from './calendar.svg';
import { ReactComponent as IconCheckbox } from './checkbox.svg';
import { ReactComponent as IconChevron } from './chevron.svg';
import { ReactComponent as IconClose } from './close.svg';
import { ReactComponent as IconCloseWindow } from './close-window.svg';
import { ReactComponent as IconComments } from './comments.svg';
import { ReactComponent as IconEye } from './eye.svg';
import { ReactComponent as IconHistory } from './history.svg';
import { ReactComponent as IconMail } from './mail.svg';
import { ReactComponent as IconMap } from './map.svg';
import { ReactComponent as IconMapFill } from './map-fill.svg';
import { ReactComponent as IconMarker } from './marker.svg';
import { ReactComponent as IconMsgArrows } from './message-arrows.svg';
import { ReactComponent as IconPencil } from './pencil.svg';
import { ReactComponent as IconPhoneMenu } from './phone-menu.svg';
import { ReactComponent as IconPhoneVoicemail } from './phone-voicemail.svg';
import { ReactComponent as IconPin } from './pin.svg';
import { ReactComponent as IconPlus } from './plus.svg';
import { ReactComponent as IconPlusCircle } from './plus-circle.svg';
import { ReactComponent as IconPuzzle } from './puzzle.svg';
import { ReactComponent as IconPuzzleLeft } from './puzzle-left.svg';
import { ReactComponent as IconRobot } from './robot.svg';
import { ReactComponent as IconScale } from './scale.svg';
import { ReactComponent as IconMailInbox } from './mail-inbox.svg';
import { ReactComponent as IconArchive } from './archive.svg';
import { ReactComponent as IconArchiveJunk } from './archive-junk.svg';
import { ReactComponent as IconFile } from './file.svg';
import { ReactComponent as IconFileX } from './file-x.svg';
import { ReactComponent as IconPaperPlane } from './paper-plane.svg';
import { ReactComponent as IconTrash } from './trash.svg';
import { ReactComponent as IconTrashTransparent } from './trash-transparent.svg';
import { ReactComponent as IconClip } from './clip.svg';
import { ReactComponent as IconMissedCalls } from './missed-calls.svg';
import { ReactComponent as IconTransferCalls } from './transfer.svg';
import { ReactComponent as IconPause } from './pause.svg';
import { ReactComponent as IconPeopleConference } from './people-conference.svg';
import { ReactComponent as IconIncomingCalls } from './incoming-calls.svg';
import { ReactComponent as IconBurgerMenu } from './burger-menu.svg';
import { ReactComponent as IconPhone } from './phone.svg';
import { ReactComponent as IconPhoneSquare } from './phone-square.svg';
import { ReactComponent as IconArrowCircle } from './arrow-circle.svg';
import { ReactComponent as IconArrowUpdate } from './arrow-update.svg';
import { ReactComponent as IconSnowflake } from './snowflake.svg';
import { ReactComponent as IconMicrophoneMute } from './microphone-mute.svg';
import { ReactComponent as IconPhoneDown } from './phone-down.svg';
import { ReactComponent as IconSearch } from './search.svg';
import { ReactComponent as IconLocation } from './location.svg';
import { ReactComponent as IconSettings } from './settings.svg';
import { ReactComponent as IconStar } from './star.svg';
import { ReactComponent as IconStarTransparent } from './star-transparent.svg';
import { ReactComponent as IconTelegram } from './telegram.svg';
import { ReactComponent as IconExternalLinkSquare } from './external-link-square.svg';
import { ReactComponent as IconUsers } from './users.svg';
import { ReactComponent as IconUsers2 } from './users2.svg';
import { ReactComponent as IconUpload } from './upload.svg';
import { ReactComponent as IconVenus } from './venus.svg';
import { ReactComponent as IconInfo } from './info.svg';
import { ReactComponent as IconCopy } from './copy.svg';
import { ReactComponent as IconCheck } from './check.svg';
import { ReactComponent as IconCheckCircle } from './check-circle.svg'
import { ReactComponent as IconHandRock } from './hand-rock.svg';
import { ReactComponent as IconHandPaper } from './hand-paper.svg';
import { ReactComponent as IconP } from './p.svg';
import { ReactComponent as IconF } from './f.svg';
import { ReactComponent as IconArrow } from './arrow.svg';
import { ReactComponent as IconSave } from './save.svg';
import { ReactComponent as IconStop } from './stop.svg';
import { ReactComponent as IconUndo } from './undo.svg';
import { ReactComponent as IconMedia } from './media.svg';
import { ReactComponent as IconSignOut } from './sign-out.svg';
import { ReactComponent as IconReply } from './reply.svg';
import { ReactComponent as IconReplyText } from './reply-text.svg';
import { ReactComponent as IconMicrophone } from './microphone.svg';
import { ReactComponent as IconSmile } from './smile.svg';
import { ReactComponent as IconMailLines } from './mail-lines.svg';
import { ReactComponent as IconMute } from './mute.svg';
import { ReactComponent as IconCircle } from './circle.svg';
import { ReactComponent as IconPondSign } from './pound-sign.svg';
import { ReactComponent as IconExpand } from './expand.svg';
import { ReactComponent as IconPlay } from './play.svg';
import { ReactComponent as IconVolumeLow } from './volume-low.svg';
import { ReactComponent as IconVolumeOff } from './volume-off.svg';
import { ReactComponent as IconVolumeUp } from './volume-up.svg';
import { ReactComponent as IconQuote } from './quote.svg';
import { ReactComponent as IconUsersPlus } from './users-plus.svg'
import { ReactComponent as IconOutCall } from './out-call.svg';
import { ReactComponent as IconIncCall } from './inc-call.svg';
import { ReactComponent as IconFilter } from './filter.svg';
import { ReactComponent as IconMinusSquare } from './minus-square.svg';
import { ReactComponent as IconLock } from './lock.svg';
import { ReactComponent as IconLockOpen } from './lock-open.svg';
import { ReactComponent as IconOut } from './out.svg';
import { ReactComponent as IconDuoGirls } from './duo-girls.svg';
import { ReactComponent as IconA } from './a.svg';
import { ReactComponent as IconPenSquare } from './pen-square.svg';
import { ReactComponent as IconMapDash } from './map-dash.svg';
import { ReactComponent as IconFolderOpen } from './folder-open.svg';
import { ReactComponent as IconArrowCircleThin } from './arrow-circle-thin.svg';
import { ReactComponent as IconMessageWarn } from './message-warn.svg';
import { ReactComponent as IconWarn } from './warn.svg';
import { ReactComponent as IconBagel } from './bagel.svg';
import { ReactComponent as IconStopBagel } from './stop-bagel.svg';
import { ReactComponent as IconB } from './b.svg';
import { ReactComponent as IconLink } from './link.svg';
import { ReactComponent as IconBars } from './bars.svg';
import { ReactComponent as IconTriangleWithCircle } from './triangle-with-circle.svg';
import { ReactComponent as IconCard } from './card.svg';
import { ReactComponent as IconW } from './w.svg';
import { ReactComponent as IconPlusMessage } from './plus-message.svg';
import { ReactComponent as IconMerge } from './merge.svg';
import { ReactComponent as IconForward } from './forward.svg';
import { ReactComponent as IconUserSecret } from './user-secret.svg';
import { ReactComponent as IconBellSlash } from './bell-slash.svg';
import { ReactComponent as IconFemale } from './female.svg';
import { ReactComponent as IconOperator } from './operator.svg';
import { ReactComponent as IconClock } from './clock.svg';
import { ReactComponent as IconPlusThick } from './plus-thick.svg';
import { ReactComponent as IconWhatsapp } from './whatsapp.svg';
import { ReactComponent as IconNineDots } from './nine-dots.svg';
import { ReactComponent as IconNineDashes } from './nine-dashes.svg';
import { ReactComponent as IconChatMsg } from './chat-msg.svg';
import { ReactComponent as IconInfoNew } from './info-new.svg';
import { ReactComponent as IconApple } from './apple.svg';
import { ReactComponent as IconR } from './r.svg';
import { ReactComponent as IconBookPlus} from './book-plus.svg';
import { ReactComponent as IconSubway } from './subway.svg';
import { ReactComponent as IconMistress } from './mistress.svg';
import { ReactComponent as IconTop } from './top.svg';

const ICONS = {
  addressCard1: IconAddressCard1,
  addressCard2: IconAddressCard2,
  arrowUpdate: IconArrowUpdate,
  adrBook: IconAdrBook,
  adrBookInvert: IconAdrBookInvert,
  angleQuote: IconAngleQuote,
  bell: IconBell,
  mistress: IconMistress,
  bellFill: IconBellFill,
  closeWindow: IconCloseWindow,
  link: IconLink,
  upload: IconUpload,
  booking: IconBooking,
  buffer: IconBuffer,
  calendar: IconCalendar,
  checkbox: IconCheckbox,
  check: IconCheck,
  chevron: IconChevron,
  close: IconClose,
  comments: IconComments,
  eye: IconEye,
  externalLinkSquare: IconExternalLinkSquare,
  history: IconHistory,
  mail: IconMail,
  map: IconMap,
  stop: IconStop,
  mapFill: IconMapFill,
  msgArrows: IconMsgArrows,
  pencil: IconPencil,
  phoneMenu: IconPhoneMenu,
  phoneVoicemail: IconPhoneVoicemail,
  pin: IconPin,
  plus: IconPlus,
  plusCircle: IconPlusCircle,
  puzzle: IconPuzzle,
  puzzleLeft: IconPuzzleLeft,
  robot: IconRobot,
  scale: IconScale,
  mailInbox: IconMailInbox,
  archive: IconArchive,
  archiveJunk: IconArchiveJunk,
  file: IconFile,
  fileX: IconFileX,
  paperPlane: IconPaperPlane,
  trash: IconTrash,
  trashTransparent: IconTrashTransparent,
  clip: IconClip,
  missedCalls: IconMissedCalls,
  transferCalls: IconTransferCalls,
  pause: IconPause,
  peopleConference: IconPeopleConference,
  incomingCalls: IconIncomingCalls,
  burgerMenu: IconBurgerMenu,
  phoneSquare: IconPhoneSquare,
  phone: IconPhone,
  arrowCircle: IconArrowCircle,
  snowflake: IconSnowflake,
  microphoneMute: IconMicrophoneMute,
  phoneDown: IconPhoneDown,
  search: IconSearch,
  settings: IconSettings,
  location: IconLocation,
  star: IconStar,
  starTransparent: IconStarTransparent,
  telegram: IconTelegram,
  marker: IconMarker,
  users: IconUsers,
  users2: IconUsers2,
  venus: IconVenus,
  info: IconInfo,
  copy: IconCopy,
  handRock: IconHandRock,
  handPaper: IconHandPaper,
  p: IconP,
  f: IconF,
  r: IconR,
  arrow: IconArrow,
  save: IconSave,
  undo: IconUndo,
  media: IconMedia,
  reply: IconReply,
  microphone: IconMicrophone,
  signOut: IconSignOut,
  smile: IconSmile,
  mailLines: IconMailLines,
  mute: IconMute,
  circle: IconCircle,
  poundSign: IconPondSign,
  expand: IconExpand,
  play: IconPlay,
  volumeMute: IconVolumeLow,
  volumeOff: IconVolumeOff,
  volumeUp: IconVolumeUp,
  quote: IconQuote,
  usersPlus: IconUsersPlus,
  outCall: IconOutCall,
  incCall: IconIncCall,
  filter: IconFilter,
  minusSquare: IconMinusSquare,
  lock: IconLock,
  lockOpen: IconLockOpen,
  out: IconOut,
  duoGirls: IconDuoGirls,
  a: IconA,
  penSquare: IconPenSquare,
  mapDash: IconMapDash,
  folderOpen: IconFolderOpen,
  arrowCircleThin: IconArrowCircleThin,
  messageWarn: IconMessageWarn,
  warn: IconWarn,
  bagel: IconBagel,
  stopBagel: IconStopBagel,
  b: IconB,
  bars: IconBars,
  triangleWithCircle: IconTriangleWithCircle,
  card: IconCard,
  w: IconW,
  plusMessage: IconPlusMessage,
  replyText: IconReplyText,
  merge: IconMerge,
  checkCircle: IconCheckCircle,
  forward: IconForward,
  userSecret: IconUserSecret,
  bellSlash: IconBellSlash,
  female: IconFemale,
  operator: IconOperator,
  clock: IconClock,
  plusThick: IconPlusThick,
  whatsapp: IconWhatsapp,
  nineDots: IconNineDots,
  nineDashes: IconNineDashes,
  chatMsg: IconChatMsg,
  infoNew: IconInfoNew,
  apple: IconApple,
  bookPlus: IconBookPlus,
  subway: IconSubway,
  top: IconTop,
}

export default ICONS;

import { useEffect, useMemo, useState } from 'react';


const useSelectMarkersOnMap = (refs, markers, isEnabled = true) => {
  const [selectionCoordinates, setSelectionCoordinates] = useState(null);
  const [selectedMarkers, setSelectedMarkers] = useState([]);
  const [shouldStopSelection, setShouldStopSelection] = useState(false);
  const [shiftKeyIsPressed, setShiftKeyIsPressed] = useState(false);

  const { map, mapContainer } = refs;

  const controller = new AbortController();
  const { signal } = controller;

  const handleMouseDown = (event) => {
    if (!mapContainer || !isEnabled) return;

    const startX = event.clientX - mapContainer.getBoundingClientRect().left;
    const startY = event.clientY - mapContainer.getBoundingClientRect().top;
    setSelectionCoordinates({ startX, startY, endX: startX, endY: startY });
  };

  const handleMouseMove = (event) => {
    if (!selectionCoordinates || !mapContainer || !isEnabled) return;

    const endX = event.clientX - mapContainer.getBoundingClientRect().left;
    const endY = event.clientY - mapContainer.getBoundingClientRect().top;
    setSelectionCoordinates({ ...selectionCoordinates, endX, endY });
  };

  const handleMouseUp = () => {
    if (!selectionCoordinates || !map || !mapContainer || !isEnabled) return;

    const { startX, startY, endX, endY } = selectionCoordinates;
    const maps = google.maps;

    const projection = map.getProjection();
    const topRight = projection.fromLatLngToPoint(map.getBounds().getNorthEast());
    const bottomLeft = projection.fromLatLngToPoint(map.getBounds().getSouthWest());

    const scale = Math.pow(2, map.getZoom());

    const nePoint = new maps.Point(
      Math.max(startX, endX) / scale + bottomLeft.x,
      Math.min(startY, endY) / scale + topRight.y
    );
    const swPoint = new maps.Point(
      Math.min(startX, endX) / scale + bottomLeft.x,
      Math.max(startY, endY) / scale + topRight.y
    );

    const neLatLng = projection.fromPointToLatLng(nePoint);
    const swLatLng = projection.fromPointToLatLng(swPoint);

    setSelectedMarkers(() => {
      const selectedMarkers = markers.filter((marker) => {
        const [lng, lat] = marker.geometry.coordinates;
        const byLatAreaIncludes = swLatLng.lat() <= lat && lat <= neLatLng.lat();
        const byLngAreaIncludes = swLatLng.lng() <= lng && lng <= neLatLng.lng();

        return byLatAreaIncludes && byLngAreaIncludes
      })

      return selectedMarkers;
    });

    setSelectionCoordinates(null);
  };

  const selectionBox = useMemo(() => {
    if (!selectionCoordinates) return null;

    return (
      <div
        className='selection-box'
        style={{
          position: 'absolute',
          border: '2px dashed #000',
          backgroundColor: 'rgba(0, 0, 0, 0.1)',
          zIndex: 1000,
          left: Math.min(selectionCoordinates.startX, selectionCoordinates.endX),
          top: Math.min(selectionCoordinates.startY, selectionCoordinates.endY),
          width: Math.abs(selectionCoordinates.startX - selectionCoordinates.endX),
          height: Math.abs(selectionCoordinates.startY - selectionCoordinates.endY),
        }}
      />
    )
  }, [selectionCoordinates])

  useEffect(() => {
    if (!map) return;

    map.setOptions({ draggable: shouldStopSelection });
  }, [map, shouldStopSelection]);

  useEffect(() => {
    !shouldStopSelection && mapContainer?.addEventListener('mousedown', handleMouseDown, { signal });
    window.addEventListener('mousemove', handleMouseMove, { signal });
    window.addEventListener('mouseup', handleMouseUp, { signal });
    window.addEventListener('keydown', (event) => {
      if (event.key === 'Shift') {
        setShouldStopSelection(true);
        setShiftKeyIsPressed(true);
      }
    }, { signal });
    window.addEventListener('keyup', (event) => {
      if (event.key === 'Shift') {
        setShouldStopSelection(false);
        setShiftKeyIsPressed(false);
      }
    }, { signal });

    return () => controller.abort();
  }, [
    map,
    mapContainer,
    selectionCoordinates,
    isEnabled,
    shouldStopSelection,
  ])

  useEffect(() => {
    if (!isEnabled) {
      setSelectedMarkers([]);
    }

    setShouldStopSelection(!isEnabled);
  }, [isEnabled])

  const getMarkerProps = () => ({
    onMouseEnter: () => isEnabled && setShouldStopSelection(true),
    onMouseLeave: () => isEnabled && !shiftKeyIsPressed && setShouldStopSelection(false)
  })

  return { selectedMarkers, selectionBox, getMarkerProps };
}

export default useSelectMarkersOnMap;

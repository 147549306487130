import { useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';
import { useForm, useFormState } from 'react-final-form';

import API from 'api/api';
import ICONS from 'assets/icons';
import { useDidUpdate } from 'hooks';
import { classModifier } from 'utils';
import popperViewportCenter from 'config/popperViewportCenter';

import './BookingSpecialRequestField.scss';
import { setBookingLog } from 'redux/ducks/bookings';
import List from 'components/List/List';
import BookingSpecialRequestItem from './BookingSpecialRequestItem';
import BookingTooltipAddFrom from '../BookingTooltipAddFrom/BookingTooltipAddFrom';


const BookingSpecialRequestField = ({
  isEditBooking,
  bookingId,
  isDisabled,
  isRequestFieldOpen,
  setIsRequestFieldOpen,
  getBookingLog,
  callerId,
  isFormEmpty
}) => {
  const formState = useFormState();
  const initialFormStateRequestText = formState.values.requestText || '';
  const requestData = formState.values?.requestData;
  const initialFormStateRequestMsgs = formState.values.specialRequestMsgs || '';

  const [specialRequestList, setSpecialRequestList] = useState(initialFormStateRequestMsgs);
  const [requestFieldText, setRequestfieldText] = useState(initialFormStateRequestText);
  const [newRequests, setNewRequests] = useState([]);
  const [deletedRequests, setDeletedRequests] = useState([]);

  const form = useForm();

  const {
    setTriggerRef,
    setTooltipRef,
    getTooltipProps,
  } = usePopperTooltip({
    trigger: 'click',
    visible: isRequestFieldOpen,
    onVisibleChange: setIsRequestFieldOpen,
    closeOnOutsideClick: false,
  }, {
    modifiers: [popperViewportCenter]
  });

  useDidUpdate(() => {
    if(!isRequestFieldOpen) {
      setRequestfieldText(initialFormStateRequestText);
      setDeletedRequests([]);
      setNewRequests([]);
    }
  }, [isRequestFieldOpen])

  // useDidUpdate(() => {
  //   if(!isRequestFieldOpen) {
  //     formRequestText !== requestFieldText && setRequestfieldText(formRequestText);
  //     requestFieldText && setRequestfieldText('');
  //   };
  // }, [isRequestFieldOpen]);

  const handleSaveRequest = () => {
    const obj = {
      id: requestData?.id,
      text: requestFieldText,
      is_sussess: 1,
      description: requestFieldText,
      booking_id: bookingId
    };

    if(isEditBooking) {
      if((requestFieldText !== initialFormStateRequestText) && requestData) {
        API.updateBookingRequest(obj)
          .then(({ data }) => {
            form.change('requestData', obj);
            // updateBookingsList(prevState => prevState.map(booking => booking.id === bookingId ? data : booking));
          })
          .catch((err) => {console.error(err)})
          .finally(setBookingLog)
      } else if(requestFieldText && !requestData) {
        API.addBookingRequest(obj)
          .then(({ data }) => {
            const newRequest = data.requests.filter((req) => !req.is_from_message)[0];
            form.change('requestData', newRequest);
            // updateBookingsList(prevState => prevState.map(booking => booking.id === bookingId ? data : booking));
          })
          .catch((err) => {console.error(err)})
          .finally(setBookingLog)
      } else if(!requestFieldText && requestData) {
        API.deleteBookingRequest(obj.id)
        .then(() => {
          form.change('requestData', null);
          // updateBookingsList(prevState => prevState.map(booking => {
          //   if(booking.id === bookingId) {
          //     return { 
          //       ...booking,
          //       requests: booking.requests.filter(i => i.id !== obj.id)
          //     }
          //   } else return booking;
          // }));
        })
        .catch((err) => {console.error(err)})
        .finally(setBookingLog)
      };

      let updatedFormStateRequestMsgs = initialFormStateRequestMsgs;

      const deleteRequestsPromise = deletedRequests.length 
        ? API.deleteSpecialRequestsFromMessages(bookingId, deletedRequests) 
        : Promise.resolve();

      const addRequestsPromise = newRequests.length 
        ? API.addSpecialRequestsFromMessages(bookingId, newRequests.map(obj => obj.message)) 
        : Promise.resolve();

      Promise.all([deleteRequestsPromise, addRequestsPromise])
        .then(([deleteResponse, addResponse]) => {
          if (deleteResponse) {
            updatedFormStateRequestMsgs = initialFormStateRequestMsgs.filter((req) => !deletedRequests.includes(req.id));
          }

          if (addResponse) {
            updatedFormStateRequestMsgs = [...updatedFormStateRequestMsgs, ...addResponse.data];
          }

          form.change('specialRequestMsgs', updatedFormStateRequestMsgs);
          setSpecialRequestList(updatedFormStateRequestMsgs);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      form.change('specialRequestMsgs', newRequests);
    }

    setTimeout(getBookingLog, 2000);
    form.change('requestText', requestFieldText);
    setIsRequestFieldOpen(false);
  }

  const handleCancel = () => {
    setSpecialRequestList(initialFormStateRequestMsgs);
    setIsRequestFieldOpen(false);
  }

  const tooltipProps = getTooltipProps({
    className: 'booking-special-request__tooltip',
  });

  const addSpecialRequestToList = (item) => {
    if(!newRequests.some((obj) => obj.id === item.id)){
      setNewRequests((prev) => [...prev, item])
      setSpecialRequestList((prev) => [...prev, item]);
    };
  }
  

  const deleteSpecialRequestFromList = (item) => {
    const filteredList = specialRequestList.filter(obj => obj.id !== item.id);
    setSpecialRequestList(filteredList);

    if(item.hasOwnProperty("is_from_message")) {
      setDeletedRequests((prev) => [...prev, item.id])
    } else {
      const newList = newRequests.filter(obj => obj.id !== item.id);
      setNewRequests(newList);
    }
  }

  const renderButton = () => {
    return isEditBooking
      ? <button 
          type='button'
          ref={setTriggerRef}
          className={classModifier('booking-edit-form__btn', 'edit-special-request')}
          disabled={isDisabled || isFormEmpty}
        >
          <ICONS.pencil className={classModifier('booking-edit-form__btn-icon', 'edit-special-request')}/>
        </button>
      : <button 
          type='button'  
          ref={setTriggerRef}
          className={classModifier('booking-form__btn', 'add-special')}
          disabled={isDisabled || isFormEmpty}
        >
          Add Special Request
          <ICONS.plusCircle className={classModifier('booking-form__btn-icon', 'add-special')}/>
        </button>
  };

  return (
    <div className='booking-special-request'>

      {renderButton()}

      {isRequestFieldOpen &&
        <div ref={setTooltipRef} {...tooltipProps}>
          
          <div className='booking-special-request__header'>
            <h2 className='booking-special-request__title'>Special Request</h2>
            <div className='booking-special-request__btns-wrapper'>
              <button 
                type='button' 
                onClick={handleCancel}
                className={classModifier('booking-special-request__btn', 'cancel')}
              >
                Cancel
              </button>
              <button 
                type='button' 
                onClick={handleSaveRequest}
                className={classModifier('booking-special-request__btn', 'save')}
              >
                Save
                <ICONS.check className={classModifier('booking-special-request__btn-icon', 'check')}/>
              </button>
            </div>
          </div>

          <div className='booking-special-request__main'>

            <div className='booking-special-request__main-header'>
              <h2 className='booking-special-request__subtitle'>Special Request</h2>
              <BookingTooltipAddFrom
                callerId={callerId}
                onSelect={addSpecialRequestToList}
                isBookingEdit
              />
            </div>

            <div className='booking-special-request__textarea-wrap'>
              <textarea
                value={requestFieldText} 
                onChange={e => setRequestfieldText(e.target.value)}
              />
            </div>

            <div className='booking-special-request__list-wrap'>
              <List
                classPrefix='booking-special-request'
                list={specialRequestList}
                listItemKey={"id"}
                withoutAbsentLabel
                scrollbarProps={{ 
                  renderThumbVertical: props => <div {...props} style={{ backgroundColor: '#E6EBF1', width: '4px', borderRadius: '2px' }}className="scrollbars-thumb" />
                }}
              >
                <BookingSpecialRequestItem onDelete={deleteSpecialRequestFromList}/>
              </List>
            </div>
          </div>
        
        </div>
      }
    </div>
  )
}

export default BookingSpecialRequestField;

import React from 'react';
import { connect } from 'react-redux';
import { v4 } from 'uuid';

import ICONS from 'assets/icons';
import { ADR_BOOK_FILTERS, INTERACTION_TYPES } from 'config/constants';
import { classModifier, getContactAvatar, showContactName, getHighlightedText } from 'utils';
import { selectContactById } from 'redux/selectors/selectors';
import { updateActiveAdrBookContactId } from 'redux/ducks/addressBook';
import { getContactsById } from 'redux/ducks/contacts';

import "./AdrBookItem.scss";
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';

const AdrBookItem = ({ style = { height: 0 } , ...props }) => {
  const { 
    contact, 
    isActiveSelect, 
    onAddSelectContact, 
    isSelectMode, 
    selectedContactsList = [], 
    dataPending, 
    isNewContactCreation 
  } = props;
  const isSelectedContact = selectedContactsList.map(item => item.id).includes(contact.id);
  const isCommunicationViaBot = [
      INTERACTION_TYPES.INCOMING_MSG_TELEGRAM, 
      INTERACTION_TYPES.OUTGOING_MSG_TELEGRAM,
      INTERACTION_TYPES.INCOMING_NIGHT_BOT_MESSAGE, 
      INTERACTION_TYPES.OUTGOING_NIGHT_BOT_MESSAGE
    ].includes(contact.interactionObject?.type);

  const isDinstarMsg = [
      INTERACTION_TYPES.INCOMING_MSG_DINSTAR, 
      INTERACTION_TYPES.OUTGOING_MSG_DINSTAR
    ].includes(contact.interactionObject?.type);

  const isMailMsg = [
      INTERACTION_TYPES.INCOMING_EMAIL
    ].includes(contact.interactionObject?.type);

  const isWhatsAppMsg = [
      INTERACTION_TYPES.INCOMING_MSG_WHATSAPP,
      INTERACTION_TYPES.OUTGOING_MSG_WHATSAPP,
    ].includes(contact.interactionObject?.type);


  const isTelegramMsg = [
      INTERACTION_TYPES.TELEGRAM_GROUP_MESSAGE,
      INTERACTION_TYPES.INCOMING_PRIVATE_MSG_TELEGRAM,
      INTERACTION_TYPES.OUTGOING_PRIVATE_MSG_TELEGRAM,
      INTERACTION_TYPES.MASTER_TASK_TELEGRAM_GROUP,
    ].includes(contact.interactionObject?.type);

  const isAppleMsg = [
      INTERACTION_TYPES.INCOMING_MSG_IPHONE_IMESSAGE,
      INTERACTION_TYPES.OUTGOING_MSG_IPHONE_IMESSAGE,
      INTERACTION_TYPES.INCOMING_MSG_IPHONE_SMS,
      INTERACTION_TYPES.OUTGOING_MSG_IPHONE_SMS,
    ].includes(contact.interactionObject?.type);

  if (contact.isTempStart) {
    return (
      <div 
        style={{
          ...style,
          bottom: style.bottom ? style.bottom + 8 : 8,
          height: style.height - 8
        }}
        className={classModifier('adr-book-item', 'divider-for-temporary')}
      >
        <span className='adr-book-item__divider-temp-text'>
          Temporary contacts
        </span>
      </div>
    )
  }

  const handleClick = () => {
    if(dataPending || isNewContactCreation) return;

    if(isSelectMode && !isSelectedContact) {
      onAddSelectContact && onAddSelectContact(contact);
      return; 
    }

    if (!props.isActive) {
      props.getContactsById(contact.id, contact.type)
        .then((contact) => {
          props.updateActiveAdrBookContactId(contact.id);
        });
    }
  }

  const setDeviderBetweenJSX = (array, devider) => {
    return array.reduce((acc, jsx) => !acc ? [jsx] : [...acc, devider, jsx], null);
  }

  const getHighlightsForField = (title, field, selector) => {
    let highlights = [];

    const pushHighlight = (item) => {
      if (!item) {
        return;
      }

      const commonText = selector && typeof item === "object" ? selector(item) : item;
      let highlightedText;

      if (commonText) {
        highlightedText = getHighlightedText(commonText, props.search, 'adr-book-item__highlight');
        highlightedText && highlights.push(highlightedText);
      }

    }

    if (field instanceof Array) {
      field.forEach(pushHighlight);
    } else {
      pushHighlight(field);
    }

    return highlights.length ? (
      <span key={v4()} className='adr-book-item__highlight-field'>
        {title}: {setDeviderBetweenJSX(highlights, ', ')}
      </span>
    ) : null;
  }

  const getHighlights = () => {
    let highlights = [];
    const addresses = contact.minimizedAddresses || contact.addresses;
    const tels = contact.minimizedTels || contact.tels;
    const emails = contact.minimizedEmails || contact.emails;
    const telegramNicknames = contact.minimizeTelegramNicknames || contact.telegram_nicknames;

    [
      getHighlightsForField('Address', addresses, ({ address }) => address),
      getHighlightsForField('Phone', tels, ({ tel }) => tel),
      getHighlightsForField('Email', emails, ({ email }) => email),
      getHighlightsForField('Telegram', telegramNicknames, ({ nickname }) => nickname),
      getHighlightsForField('ID', contact.diva_id),
    ].forEach(
      (highlight) => highlight && highlights.push(highlight)
    )

    return setDeviderBetweenJSX(highlights, '/');
  };

  const getHighlightedSearchText = () => {
    const searchText = props.search;
    const idxOfSearchedPart = contact.fn.toUpperCase().indexOf(searchText.toUpperCase());

    if(idxOfSearchedPart >= 0) {
      const strBefore = contact.fn.slice(0, idxOfSearchedPart)
      let strBeforeToArraay = [<span key={v4()}>{strBefore}</span>];

      if(strBefore.startsWith('EX') && !!contact.is_ex) {
        strBeforeToArraay = [<mark key={v4()} className="adr-book-item__ex">EX</mark>, strBefore.slice(2)];
      }

      const sanitizedSearchText = searchText.replace(/[-\/()]/g, '\\$&');
      const escapedSubstring = sanitizedSearchText.replace(/\+/g, "\\+");
      const regExForSearchedText = new RegExp(escapedSubstring, "gi");
      const searchedTxtFromFn = contact.fn.match(regExForSearchedText) ? contact.fn.match(regExForSearchedText)[0] : '';

      const searchedStrToJsx = <mark key={v4()} className="adr-book-item__highlight">{searchedTxtFromFn}</mark>;
      const strAfter = <span key={v4()}>{contact.fn.slice(idxOfSearchedPart + searchText.length)}</span>;

      return [...strBeforeToArraay, searchedStrToJsx, strAfter];
    } else return contact.fn;
  };

  const contactTels = contact.minimizedTels || contact.tels || [];
  const contactUrls = contact.minimizedUrls || contact.urls;
  const isNeedSearchingAllContactsButton =
    props.search &&
    props.lastIndex === props.index &&
    props.contactType !== ADR_BOOK_FILTERS.ALL &&
    !isActiveSelect;

  return (
    <div
      style={{
        ...style,
        bottom: style.bottom ? style.bottom + 8 : 8,
        height: style.height ? style.height - 8 : 'auto'
      }}
      className={classModifier('adr-book-item', [
        isSelectMode 
          ? (isActiveSelect || (!isActiveSelect & isSelectedContact)) && "active" 
          : props.isActive && "active",
        (dataPending || isNewContactCreation) && 'disabled'
      ])}
      onClick={handleClick}
    >
      {/* {contact.type === 2 && <div className={classModifier("adr-book-item__contact-status", "active")}></div>} */}

      <div className={classModifier("adr-book-item__img-wrap", [
        contact.availability && contact.availability === 'off today'
          ? 'off-today'
          : contact.availability,
        ])}
      >
        <LazyLoadImage src={getContactAvatar(contact)} alt="ava" className="adr-book-item__img" />

        {(contact.default_channel === 'telegram' || isTelegramMsg) &&
          <ICONS.telegram className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {contact?.has_telegram_groups &&
          <ICONS.users className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {isCommunicationViaBot &&
          <ICONS.robot className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {isDinstarMsg &&
          <ICONS.comments className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }

        {isWhatsAppMsg &&
          <ICONS.whatsapp className={classModifier("adr-book-item__ava-icon", "whatsapp")} />
        }

        {isMailMsg &&
          <ICONS.mail className={classModifier("adr-book-item__ava-icon", "mail")} />
        }

        {isAppleMsg &&
          <ICONS.apple className={classModifier("adr-book-item__ava-icon", "telegram")} />
        }
        {/* {contact.type === 2 && contact.availability && <ICONS.arrowUpdate width="10px" height="10px" />} */}

      </div>

      <div className="adr-book-item__info">
        <span className="adr-book-item__name">
          {props.search && contact.fn && getHighlightedSearchText()}
          {!props.search && contact.fn && showContactName(contact.fn, 'adr-book-item__ex')}
        </span>


        <span className="adr-book-item__content">
          {props.search ? getHighlights() : contactTels[0]?.tel}
        </span>
      </div>

      {contact.type === 2 && !!contactUrls?.length &&
        <div className="adr-book-item__link-wrap">
          <a
            href={contactUrls[0].url}
            target="_blank"
            className="adr-book-item__external-link"
          >
            <ICONS.externalLinkSquare width="14px" height="14px" />
          </a>
        </div>
      }
      {isNeedSearchingAllContactsButton &&
        <button
          onClick={(e) => {
            e.stopPropagation();

            props.changeContactType(ADR_BOOK_FILTERS.ALL, props.userId);
          }}
          className="adr-book-list__global-search-btn adr-book-list__global-search-btn--modifier"
        >
          Try Searching All Contacts
        </button>
      }
    </div>
  );
}

const mapStateToProps = (state, { id }) => ({
  userId: state.user.id,
  contact: selectContactById(state, id),
  isActive: state.addressBook.active === id,
  search: state.addressBook.search,
  isNewContactCreation: state.addressBook.isNewContactCreation,
});

const mapDispatchToProps = {
  updateActiveAdrBookContactId,
  getContactsById
};

export default connect(mapStateToProps, mapDispatchToProps)(AdrBookItem);

import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import API from 'api/api';
import { getContactMessageContext } from 'redux/ducks/clientChats';
import { MODAL_TYPES } from 'redux/ducks/activeWindows';
import { selectContactById } from 'redux/selectors/selectors';
import { parseMsgText } from 'utils';

import "./Message.scss";
import { NOTIFICATION_TYPES } from 'redux/ducks/notifications';
import { getNotificationProps } from 'utils/getNotiifcationProps';
import PinnedMsgsAttachments from 'containers/Chat/components/ChatTimeline/PinnedMsgs/PinnedMsgsAttachments';

const simpleChatTypes = [
  NOTIFICATION_TYPES.newRelatedMessage,
  NOTIFICATION_TYPES.messageReminder,
  'new-message-reminder',
  'reminder'
];
const sessionChatTypes = [NOTIFICATION_TYPES.clientTextedInSession, NOTIFICATION_TYPES.profileTextedInSession];


export const Message = ({ className, notification }) => {
  const callerIds = getNotificationProps(notification, 'callerId');

	const caller = useSelector((state) => selectContactById(state, callerIds[0]));
  const userTimezone = useSelector((state) => state.user.userTimezone);
	const dispath = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();

  if (!callerIds[0]) {
    return '[NOT FOUND]';
  }

  const isSalesPage = location.pathname === '/sales';
  const isNotMainPage = location.pathname !== '/';

  const messageId = getNotificationProps(notification, 'messageId');
  const type = getNotificationProps(notification, 'messageType');
  const sessionId = getNotificationProps(notification, 'sessionId');
  const message = getNotificationProps(notification, 'message');
  const attachments = getNotificationProps(notification, 'attachments');

  const openChat = () => {
    if (isNotMainPage) {
      const channel = new BroadcastChannel('tab_channel');
      const messageData = { messageId, caller };

      channel.postMessage({ name: 'openChatMessage', ...messageData });
    } else {
      dispath(getContactMessageContext(messageId, caller.type, caller, null, userTimezone));
    }
  }

  // const openSessionChat = () => {
  //   if (!isSalesPage) {
  //     navigate('/sales');
  //   }

  //   API.switchSalesSession(sessionId);
  //   props.updateActiveSession(sessionId);
  //   props.openModal(MODAL_TYPES.profilesBuffer, { sessionId });
  //   props.getSessionContactMessageContext(messageId, caller.type, caller)
  // }

  const handleClick = (event) => {
    event.stopPropagation();

    // if (simpleChatTypes.includes(type)) {
      openChat();
    // }

    // if (sessionChatTypes.includes(type)) {
    //   openSessionChat();
    // }
  };

  let messageContent;

  if (message) {
    messageContent = `"${parseMsgText(message.split('\n')[0], true)}"`;
  } else if (!message && attachments) {
    messageContent = attachments.images || attachments.videos || attachments.voiceMsg
      ? <PinnedMsgsAttachments attachments={attachments} isMenuOpen={false} />
      : '"[ATTACHMENTS]"';
  } else {
    messageContent = '"[DELETED]"';
  }

  return (
    <>
      {attachments && <br />}

      <mark
        className={classNames('message-link', className)}
        onClickCapture={handleClick}
      >
        {messageContent}
      </mark>
    </>
  )
}

export default (date, isTimeOffset) => {
	if (!date) {
    return 'all'
  }

  if (Array.isArray(date)) {
    return date;
  }

  const formatedDate = new Date(date);

  // set offset for bookings with time below 5am for setting the key to the previous day
  if (formatedDate.getHours() <= 5 && isTimeOffset) {
    formatedDate.setDate(formatedDate.getDate() - 1);

    return formatedDate.setHours(0, 0, 0, 0);
  }

  return new Date(date).setHours(0, 0, 0, 0)
};

import React, { useEffect, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useSelector } from 'react-redux';

import { ROUTES } from "config/constants";
import {
  classModifier,
} from 'utils';

import './Navbar.scss';
import ICONS from 'assets/icons';

const getCurrentLinkClassName = ({ isActive, isBurgerMenu }) => {
  return isActive 
    ? classModifier('navbar__link', ['active', isBurgerMenu && 'is-burger-menu'])
    : 'navbar__link';
}

const Navbar = ({ isBurgerMenu }) => {
  const [isOpen, setIsOpen] = useState(false);
  const navbarRef = useRef();

  useEffect(() => {
    const handleClickOutside = event => {
      if (navbarRef.current && !navbarRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [navbarRef]);

  const isSalesPageOpen = useSelector((state) => state.user.isSalesPageOpen);
  const isMailPageOpen = useSelector((state) => state.user.isMailPageOpen);
  const isAdrBookPageOpen = useSelector((state) => state.user.isAdrBookPageOpen);
  const isCalendarPageOpen = useSelector((state) => state.user.isCalendarPageOpen);
  const location = useLocation();

  const handleBtnClick = () => setIsOpen(!isOpen);
  const getLinkClassName = props => getCurrentLinkClassName({ ...props, isBurgerMenu });

  return (
  <div ref={navbarRef}>
    <nav className={classModifier("navbar", isOpen && 'active')}>
      {isBurgerMenu && 
        <button className="navbar__btn" onClick={handleBtnClick}>
          <ICONS.chevron className={classModifier("navbar__icon", ["arrow", isOpen && "open"])}/>
        </button>
      }
    </nav>
    <ul  className={classModifier("navbar__list", [isBurgerMenu && 'column', isOpen && 'active'])}>
        {!isBurgerMenu && 
          <li className="navbar__item">
            <NavLink to={ROUTES.main} className={getLinkClassName}>
              <ICONS.comments className="navbar__icon"/>
            </NavLink>
          </li>
        }

        <li
          className={classModifier('navbar__item', [
            isSalesPageOpen && 'disabled',
            location.pathname === ROUTES.sales && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.sales}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
            onClick={() => setIsOpen(false)}
          >
            <ICONS.puzzle className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isAdrBookPageOpen && 'disabled',
            location.pathname === ROUTES.adrBook && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.adrBook}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
            onClick={() => setIsOpen(false)}
          >
            <ICONS.adrBook className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isMailPageOpen && 'disabled',
            location.pathname === ROUTES.mail && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.mail}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
            onClick={() => setIsOpen(false)}
          >
            <ICONS.mail className="navbar__icon"/>
          </NavLink>
        </li>

        <li
          className={classModifier('navbar__item', [
            isCalendarPageOpen && 'disabled',
            location.pathname === ROUTES.calendar && 'active',
          ])}
        >
          <NavLink
            to={ROUTES.calendar}
            target="_blank"
            className={getLinkClassName}
            rel="noreferrer"
            replace
            onClick={() => setIsOpen(false)}
          >
            <ICONS.calendar className="navbar__icon"/>
          </NavLink>
        </li>
      </ul>
  </div>
  )
}

export default Navbar;
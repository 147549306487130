
import React, { useLayoutEffect, useState } from 'react';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { selectContactById } from 'redux/selectors/selectors';
import { CUSTOM_TAGS_COMPONENTS, DYNAMIC_TAGS, HIDDEN_TAGS, SPECIAL_TAG_IDS, SPECIAL_TAG_KEYS } from './config/config';
import { CONTACT_TYPES } from 'config/constants';

import './ContactTags.scss';
import TagBlock from 'components/UI/TagBlock/TagBlock';


const convertTakKeyToTitle = (tagKey) => tagKey.split("-").join(" ");
const convertTitleToTagKey = (title) => title.split(" ").join("-").toLowerCase();


const filterTags = (tags, selectedSpecificTags, hiddenSpecificTags) => {
  const hiddenTags = [...HIDDEN_TAGS, hiddenSpecificTags];

  if (Array.isArray(selectedSpecificTags)) {
    return tags.filter((tag) => selectedSpecificTags.includes(tag.value));
  } else if (selectedSpecificTags) {
    return tags.filter((tag) => tag.value === selectedSpecificTags) ;
  }

  if (Array.isArray(hiddenTags)) {
    return tags.filter((tag) => !hiddenTags.includes(tag.value));
  } else if (hiddenTags) {
    return tags.filter((tag) => tag.value !== hiddenTags);
  }

  return _.uniqBy(tags, 'value');
}

const generateCallerTags = (caller) => {
  let tags = caller?.callerTags?.map((tag) => ({
    ...tag,
    value: convertTitleToTagKey(tag.title),
    callerId: caller.id
  })) || [];

  Object.keys(DYNAMIC_TAGS).forEach((tagKey) => {
    const { condition, title, ...restProps } = DYNAMIC_TAGS[tagKey];
    const existentTagInContact = tags.find((tag) => tag.title.toLowerCase() === convertTakKeyToTitle(tagKey));
    const isGirlOrClient = caller?.type === CONTACT_TYPES.CLIENT || caller?.type === CONTACT_TYPES.GIRL;

    if (!existentTagInContact && isGirlOrClient && condition(caller) && tagKey !== SPECIAL_TAG_KEYS.TEMPORARY && tagKey !== SPECIAL_TAG_KEYS.NOT_FOR) {
      const dynamicTagTitle = title?.(caller) || convertTakKeyToTitle(tagKey);

      tags.push({
        id: SPECIAL_TAG_IDS[caller.type][tagKey],
        value: tagKey,
        title: dynamicTagTitle,
        goingToBeCreated: caller.isEdited,
        ...restProps,
      })
    }

    if (existentTagInContact && !condition(caller)) {
      existentTagInContact.goingToBeDeleted = caller.isEdited;
    }
  }); 

  return tags;
}

export const TagRenderer = (props) => {
  const caller = useSelector(state => selectContactById(state, props.callerId));

  const TagComponent = CUSTOM_TAGS_COMPONENTS[props.value] || TagBlock;
  const isHiddenRemoveButton = Object.keys(DYNAMIC_TAGS).includes(props.value)

  const newProps = {
    ...props,
    isHiddenRemoveButton,
    value: props.value,
    caller,
  }

  return (
    <TagComponent {...newProps}>
      {props.title}
    </TagComponent>
  )
}

const DefaultWrapper = (props) => {
  const {
    tags,
    tagRenderer: TagRenderer, 
    className,
    setTags,
    noTagLabel,
    ...restProps
  } = props;

  return (
    <div
      className={classNames(className, 'contact-tags')}
      {...restProps}
    >
      {tags.map(tag => (
        <TagRenderer key={tag.id} {...tag} />
      ))}
    </div>
  )
}

const NoTagLabel = ({ notShowed }) => (
  !notShowed
    ? <div className="contact-tags--no-tags">
        (No tags)
      </div>
    : <></>
)

const ContactTags = (props) => {
  const {
    className,
    tagClassName,
    callers,
    initialTags,
    wrapper: Wrapper = DefaultWrapper,
    wrapperProps,
    withoutAbsentLabel,
    selectedSpecificTags,
    hiddenSpecificTags,
  } = props;

  // if (!caller) return null;

  const [tags, setTags] = useState([]);

  useLayoutEffect(() => {
    const callersArray = Array.isArray(callers) ? callers : [callers];
    const tags = callersArray.map(generateCallerTags).flat();
    const filteredTags = filterTags(tags, selectedSpecificTags, hiddenSpecificTags);

    setTags(filteredTags);
  }, [callers, selectedSpecificTags, hiddenSpecificTags])

  useLayoutEffect(() => {
    initialTags && setTags(initialTags);
  }, [initialTags])

  return (
    <Wrapper
      className={className}
      tags={tags}
      setTags={setTags}
      tagRenderer={(props) => <TagRenderer className={tagClassName} {...props} />}
      noTagLabel={() => <NoTagLabel notShowed={withoutAbsentLabel} />}
      {...wrapperProps}
    />
  )
}

export default React.memo(
  ContactTags,
  (prevProps, nextProps) => _.isEqual(prevProps, nextProps)
);

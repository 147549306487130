import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import API from 'api/api';
import ICONS from 'assets/icons';
import {
  LIST_TYPES,
  MAIL_LIST_LIMIT,
  unsetDelayedAction,
  clearSelectedMails,
  setEmailsToActiveFolder,
} from 'redux/ducks/mail';
import { selectActiveFolderTab } from 'redux/selectors/selectors';

import './UndoActionBlock.scss';

const UndoActionBlock = (props) => {
  const {
    offset,
    search,
    activeFolder,
    delayedAction,
    activeFolderTab,
    activeSortFilter,
    clearSelectedMails,
    unsetDelayedAction,
    setEmailsToActiveFolder,
  } = props;

  const [delay, setDelay] = useState(delayedAction.delay - 1);
  const [cancelPending, setCancelPending] = useState(false);
  const [cancelError, setCancelError] = useState(null);

  const intervalId = useRef();
  const delaySave = useRef();

  const navigate = useNavigate();

  const configWithCurrentMailFolderParams = {
    offset,
    folder: activeFolder,
    mode: activeFolderTab,
    limit: MAIL_LIST_LIMIT,
    sortBy: activeSortFilter,
  };

  const cancelDeletionRequest = (params) => {
    const isSearch = activeFolder === LIST_TYPES.search;

    if (isSearch) {
      return API.cancelDeleteMailsFromSearchList({
        ...params,
        search,
        offset,
        limit: MAIL_LIST_LIMIT,
      });
    }

    return API.cancelDeleteMails({
      ...params,
      ...configWithCurrentMailFolderParams,
    });
  };

  const cancelRequests = {
    compose: API.cancelDelayedMail,
    delete: cancelDeletionRequest,
    archive: (params) => API.cancelArchiveMails({
      ...params,
      ...configWithCurrentMailFolderParams,
    }),
    move: (params) => API.cancelMoveTo({
      ...params,
      ...configWithCurrentMailFolderParams,
    })
  };

  const setEmailsToActiveFolderAndClearSelectedMails = ({ responseData }) => {
    const { offset, mails, count } = responseData;

    setEmailsToActiveFolder({
      mails,
      offset,
      totalCount: count,
      folder: activeFolder,
    });
    clearSelectedMails();
  };

  const cancelActions = {
    compose: (params) => {
      const { id, to, subject, body, attachments } = params;

      navigate('/mail/compose', {
        state: {
          draft: { id, email: to, subject, message: body, attachments },
        }
      });
    },
    delete: setEmailsToActiveFolderAndClearSelectedMails,
    archive: setEmailsToActiveFolderAndClearSelectedMails,
    move: setEmailsToActiveFolderAndClearSelectedMails,
  };

  useEffect(() => {
    intervalId.current = setInterval(() => {
      if (delaySave.current >= 2) {
        setDelay(prev => prev - 1);
        return;
      }
      unsetDelayedAction(delayedAction.actionId);
    }, 1000);

    return () => {
      clearInterval(intervalId.current);
    };
  }, []);

  useEffect(() => {
    delaySave.current = delay;
  }, [delay]);

  const handleCancel = () => {
    const { type, cancelRequestParams = {}, cancelActionParams = {} } = delayedAction;
    const cancelRequestFunction = cancelRequests[type];
    const cancelActionFunction = cancelActions[type];

    clearInterval(intervalId.current);
    setCancelPending(true);

    cancelRequestFunction(cancelRequestParams)
      .then(({ data }) => {
        cancelActionFunction({
          ...cancelActionParams,
          responseData: data,
        });
        unsetDelayedAction(delayedAction.actionId);
      })
      .catch(err => {
        setCancelError(err);
        setCancelPending(false);

        setTimeout(() => {
          unsetDelayedAction(delayedAction.actionId);
        }, 3000);
      });
  };

  return (
    <div className="undo-action-block">
      <p className="undo-action-block__text">
        <span>{delayedAction.delayedActionText}</span>

        {!cancelPending && !cancelError &&
          `Undo ..${delay} sec.`
        }
        {cancelPending &&
          'Cancelling...'
        }
        {cancelError &&
          'Cancel failed'
        }
      </p>

      <button
        onClick={handleCancel}
        disabled={cancelPending || cancelError}
        className="undo-action-block__cancel-btn"
      >
        <ICONS.undo className="undo-action-block__cancel-btn-icon" />
      </button>
    </div>
  );
};

const mapStateToProps = (state) => ({
  offset: state.mail.offset,
  search: state.mail.mailList.search,
  activeFolder: state.mail.mailList.activeFolder,
  activeFolderTab: selectActiveFolderTab(state)?.mode,
  activeSortFilter: state.mail.mailList.activeSortFilter,
});

const mapDispatchToProps = {
  setEmailsToActiveFolder,
  clearSelectedMails,
  unsetDelayedAction,
};

export default connect(mapStateToProps, mapDispatchToProps)(UndoActionBlock);

import React, { memo } from 'react';
import Highlighter from "react-highlight-words";

import { parseMsgText, classModifier } from 'utils';


const MsgText = ({ interaction, highlightedText, classPrefix = 'interaction', ...props }) => {
  const handleGirlNameClick = (e) => {
    if (props.onGirlNameClick && typeof props.onGirlNameClick === 'function') {
      props.onGirlNameClick(e);
    }
    return;
  }

  const highlightIfNeed = (text, highlightedText) => highlightedText
    ? <Highlighter
      highlightClassName={`${classPrefix}__highlight`}
      searchWords={highlightedText.split(' ')}
      autoEscape={true}
      textToHighlight={text}
    />
    : text;

  const getMsgTextLayout = () => {
    if (!!interaction.hasUrls || interaction.has_urls) {
      const parsedArray = parseMsgText(interaction.body || interaction.description || interaction.message);
      const parsedMsg = [];
      const attachments = interaction.attachments || interaction.attachment || {};
      const attachmentLinksCopy = [...((typeof attachments === 'string' ? JSON.parse(attachments) : attachments)?.links || [])];

      // if parseRegex find no match. Also it mean that server not parse msg correct;
      if (typeof parsedArray === 'string') {
        console.warn('Custom client error: Server bad parse interaction with id: ', interaction.id)
        return highlightIfNeed(parsedArray, highlightedText);
      }

      parsedArray.forEach((part, idx) => {
        switch (part.type) {
          case 'text':
            parsedMsg.push(highlightIfNeed(part.value, highlightedText));
            break;
          case 'mention':
            parsedMsg.push(
              <span
                className={`${classPrefix}__mention`}
                key={idx}
                data-value={part.value}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </span>
            );
            break;
          case 'link':
            const attachemntLink = attachmentLinksCopy.shift();
            const { site_title } = attachemntLink || {};

            parsedMsg.push(
              <a
                className={`${classPrefix}__link`}
                href={part.value}
                title={site_title?.trim()}
                target="_blank"
                rel="noopener noreferrer"
                key={idx}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </a>
            );
            break;
          case 'girlName':
            parsedMsg.push(
              <span
                className={`${classPrefix}__clickable-name`}
                data-value={part.value}
                key={idx}
                onClick={handleGirlNameClick}
              >
                {highlightIfNeed(part.value, highlightedText)}
              </span>
            );
            break;

          default:
            break;
        }
      });
      return parsedMsg;

    }
    else {
      return highlightIfNeed(parseMsgText(interaction.body ||  interaction.message || interaction.description, true), highlightedText)
    }
  }

  return (
    <p
      className={classModifier(`${classPrefix}__text`, [
        ((!!Number(interaction.is_deleted) || !!Number(interaction.deleted)) && !interaction.attachments) && 'deleted',
        props.isMsgExpanded && 'expanded',
        props.isCompleted && 'completed',
        props.mode,
      ])}
    >
      {getMsgTextLayout(interaction)}
    </p>
  );
}

export default memo(MsgText);

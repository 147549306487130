import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

import API from 'api/api';
import ICONS from 'assets/icons';
import { getTotalRate } from 'utils/getServiceRate';
import {
  BOOKING_DETAILS_DATE_CONFIG,
  BOOKING_MESSAGES_DATE_CONFIG,
} from 'config/dates-сonfig';
import {
  BOOKING_LOCATION_TYPES,
  BOOKING_STATUS_FILTERS,
  BUTTON_THEMES,
} from 'config/constants';

import {
  classModifier,
  getContactAvatar,
} from 'utils';
import { useDidUpdate } from 'hooks';

import {
  selectUserTimezone,
  selectAllRequirements,
  selectModalsTransitionModes,
} from 'redux/selectors/selectors';
import { createBookingByValues, setUpdateBookingsPending } from 'redux/ducks/bookings';

import './BookingForm.scss';
import Spinner from 'components/UI/Spinner/Spinner';
import BookingNotes from './components/BookingNotes';
import BookingFieldType from './components/BookingFieldType';
import LazyLoadImage from 'components/LazyLoadImage/LazyLoadImage';
import BookingLocationMap from './components/BookingLocationMap';
import BookingSelectedProfile from './components/BookingSelectedProfile';
import SelectFormField from 'components/SelectFormField/SelectFormField';
import { FeedbackContactField } from 'components/FeedbackForm/components';
import CalendarFormField from 'components/CalendarFormField/CalendarFormField';
import BookingFormStatusField from './components/BookingFormStatusField';
import OverlappedBookingsMsg from 'components/OverlappedBookingsMsg/OverlappedBookingsMsg';
import BookingSpecialRequestField from './components/BookingSpecialRequestField/BookingSpecialRequestField';
import BookingSpecialRequestMessages from './components/BookingSpecialRequestMessages/BookingSpecialRequestMessages';
import BookingRequirements from 'components/BookingRequirements/BookingRequirements';
import DateTime from 'components/DateTime';
import setDeviderBetweenJSX from 'utils/setDeviderBetweenJSX';
import { closeModal, MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import SearchListContactItem from 'components/SearchList/components/SearchListContactItem/SearchListContactItem';
import Button from 'components/Button/Button';
import SearchListDivaItem from 'components/SearchList/components/SearchListDivaItem/SearchListDivaItem';
import BookingServicesField from './components/BookingServicesField';
import getCurrentSelectedRate from 'utils/getBookingSelectedRate';
import getIconTypeByInteractionId from 'utils/getIconTypeByInteractionId';
import ExistedBookingsList from 'components/ExistedBookngsList/ExistedBookingsList';
import OverflowedTags from 'components/OverflowedTags/OverflowedTags';
import ContactTags from 'components/ContactTags/ContactTags';
import classNames from 'classnames';
import BookingSelectedProfiles from './components/BookingSelectedProfiles';

const CONTACTS_LIMIT = 20;

const getLabelByType = (values) => {
  if (values.meetingType === 'solo') {
    return values.type;
  }
  const profileNameBySelectedIncall = values.type === 'incall_duo'
    ? values.escort?.name
    : values.profile?.name;

  const label = values.type === 'outcall'
    ? values.type
    : `Incall ${values.type === 'incall_both' ? 'Both' : `(${profileNameBySelectedIncall})`}`


  return label;
}

const fetchSearchClientsContacts = ({ query, offset, cancelToken }) => {
  return API.searchContacts(1, query, offset, CONTACTS_LIMIT, cancelToken)
    .then(({ data }) => ({
      newItems: data,
      newHasMore: data.length === CONTACTS_LIMIT,
    }))
    .catch(console.error);
};

const fetchDivaGirls = ({ query, offset, cancelToken, part }) => {
  return API.getActiveDivaGirls({ limit: CONTACTS_LIMIT, part, offset, search: query, cancelToken })
    .then(({ data }) => ({
      newItems: data.result,
      newHasMore: data.length === CONTACTS_LIMIT,
    }))
    .catch(console.error);
}

const isProfileOnlyIncallOrOutcall = (profile) => {
  if (!profile) {
    return null;
  }

  const isDisabled = (type) => !Boolean(profile.prices.find((price) => price[type] && price[type] !== '-'))

  if (isDisabled('incall')) {
    return 'outcall';
  }

  if (isDisabled('outcall')) {
    return 'incall';
  }

  return 'all';
}


const BookingForm = ({
  initialActiveSessionContact = null,
  agents,
  userHour12,
  userTimezone,
  activeSessionId = 0,
  initialProfile = null,
  createBookingByValues,
  girlsIdsFromActiveChats,
  date,
  dispatchCloseEvent,
  ignoreSessionContact,
  ...props
}) => {
  const isLoading = useSelector((state) => state.bookings.updateBookingsPending);
  const modalsTransitionModes = useSelector(selectModalsTransitionModes);

  const [activeSessionContact, setActiveSessionContact] = useState(!ignoreSessionContact ? initialActiveSessionContact : null);
  const [profile, setProfile] = useState(initialProfile);
  const [isOpenFrontWindow, setIsOpenFrontWindow] = useState(false);
  const [overlaps, setOverlaps] = useState({ conflictBookings: [], isOverlaps: false });
  const [attachments, setAttachments] = useState([]);
  const [wasBookingCreated, setWasBookingCreated] = useState(false);
  const [isRequestFieldOpen, setIsRequestFieldOpen] = useState(false);
  const [isRequestMessageFieldOpen, setIsRequestMessageFieldOpen] = useState(false);

  const lastInteractionType = getIconTypeByInteractionId(activeSessionContact?.interactionObject?.type);
  const LastInteractionIcon = ICONS[lastInteractionType];

  const dispatch = useDispatch();

  const notForIds = useMemo(() =>
    activeSessionContact?.not_for_clients?.map(item => item.diva_default_id || null)
    , [activeSessionContact]);

  const isClientAMember = activeSessionContact?.fn?.split(" ")?.find(word => word.toLowerCase() === 'member')

  const initialBookingValues = useMemo(() => ({
    profile,
    type: isProfileOnlyIncallOrOutcall(profile) === 'all' ? 'incall' : isProfileOnlyIncallOrOutcall(profile),
    services: profile?.services,
    taxi: { value: null, label: '-' },
    discount: { value: null, label: '-' },
    bookingStatus: Object.values(BOOKING_STATUS_FILTERS).find(item => item.label === 'to sort'),
    notesAttachments: [],
    requirements: props.requirements,
    duoRequirements: props.requirements,
    address: {
      type: BOOKING_LOCATION_TYPES.PRIVATE,
    },
    meetingType: 'solo',
    date: new Date(date).getTime() || null
  }), [profile, date]);
  /* calculate individual types for every contact */

  const calculateActualTypes = (type, isDuo, convert) => {
    let soloBookingType = type;
    let duoBookingType = type;

    if (isDuo) {
      if (type === 'incall') {
        soloBookingType = 'incall';
        duoBookingType = 'outcall'
      }

      if (type === 'incall_both') {
        soloBookingType = 'incall';
        duoBookingType = 'incall';
      }

      if (type === 'incall_duo') {
        soloBookingType = 'outcall';
        duoBookingType = convert ? 'incall' : 'incall_duo';
      }
    }

    return [soloBookingType, duoBookingType];
  }

  const saveBooking = async ({ values, forceBooking = false }) => {
    setIsOpenFrontWindow(true);
    createBookingByValues(values, activeSessionId, profile.id, activeSessionContact.id, forceBooking)
      .then(({ data: bookingIds }) => {
        if (values.requestText) {
          bookingIds.forEach(bookingId => {
            API.addBookingRequest({
              text: values.requestText,
              is_success: 1,
              description: values.requestText,
              booking_id: bookingId
            });
          })
        }

        if (!!values.scheduleMessages?.length) {
          bookingIds.forEach(bookingId => {
            values.scheduleMessages.forEach(msg => {
              API.createScheduledMsg({
                ...msg,
                body: msg.body,
                date: msg.date,
                contactId: activeSessionContact.id,
                bookingId
              });
            });
          })
        };

        if (!!values.specialRequestMsgs?.length) {
          bookingIds.forEach(bookingId => {
            API.addSpecialRequestsFromMessages(bookingId, values.specialRequestMsgs.map(obj => obj.message));
          })
        }

        setWasBookingCreated(true);
      })
      .catch((err) => {
        console.dir(err);

        if (err.response?.data.code === 403) {
          const { conflictBookings } = JSON.parse(err.response.data.message);

          setOverlaps({
            conflictBookings,
            isOverlaps: true
          });
        }
        setIsOpenFrontWindow(false);
      })
      .finally(() => {
        dispatch(setUpdateBookingsPending(false));
      });
  };

  const getCurrentBookingDuration = (type, profile, escort = {}, isDuo) => {
    if (!profile) return [];

    const [soloBookingType, duoBookingType] = calculateActualTypes(type, isDuo, true);

    const preparedSoloPrices = profile.prices.map((price, index) => {
      if (!price[soloBookingType] || price[soloBookingType] === "-") {
        if (profile.prices_member[index][soloBookingType] && profile.prices_member[index][soloBookingType] !== "-") {
          return profile.prices_member[index];
        }
        if (profile.prices_secret[index][soloBookingType] && profile.prices_secret[index][soloBookingType] !== "-") {
          return profile.prices_secret[index];
        }
      }
      return price;
    });

    const filteredProfilePrices = preparedSoloPrices
      .filter((price) => price[soloBookingType] && price[soloBookingType] !== '-');
    const filteredEscortPrices = escort.prices
      ?.filter((price) => price[duoBookingType] && price[duoBookingType] !== '-');

    let filteredPrices;
    if (filteredEscortPrices) {
      filteredPrices = filteredProfilePrices.filter((price) => {
        return filteredEscortPrices.find((escortPrice) => escortPrice.name === price.name)
      })
    } else {
      filteredPrices = filteredProfilePrices
    }

    return filteredPrices
      .map(item => ({ label: item.name, value: item[soloBookingType] }));
  };

  const bookingRateUpdate = (profile, bookingDuration, type, rate, formChange, duoProfile) => {
    const [soloBookingType, duoBookingType] = calculateActualTypes(type, !!duoProfile);
    
    const getFilteredRateByType = (bookingType, selectProfile) => getCurrentSelectedRate(bookingType, bookingDuration, selectProfile);
  
    const rateByType = getFilteredRateByType(soloBookingType, profile);
    const rateMember = rateByType.find(item => isClientAMember && item.type === 'M' && item.value);
    const rateDefault = rateByType.find(item => item.type === (rate?.type || 'D'));
    const updatedRate = rateMember || rateDefault || '';
  
    if (formChange) {
      formChange('rate', updatedRate);
  
      if (duoProfile) {
        const rateByTypeDuo = getFilteredRateByType(duoBookingType, duoProfile);
        const rateMemberDuo = rateByTypeDuo.find(item => isClientAMember && item.type === 'M' && item.value)
        const rateDefaultDuo = rateByTypeDuo.find(item => item.type === (rate?.type || 'D'))
        const updatedDuoRate = rateMemberDuo || rateDefaultDuo || '';
  
        formChange('escortRate', updatedDuoRate);
      }
    } else {
      return updatedRate;
    }
  };

  const renderCheckedServices = values => values
    .filter(item => !!item.checked)
    .map((item, idx, arr) => {
      const isComma = idx !== arr.length - 1;
      return (
        <span key={idx}>
          {item.name}
          {isComma && ', '}
          &nbsp;
        </span>
      )
    });

  const renderPaidServices = values => values
    .filter(item => item.price)
    .filter(item => !!item.checked)
    .map((item, idx, arr) => {
      const title = `£ ${item.price} ${item.name}`;
      const isComma = idx !== arr.length - 1;
      return (
        <span key={idx}>
          {title}
          {isComma && ', '}
          &nbsp;
        </span>
      )
    });

  const renderDateFieldsFormGroup = (values, form) => {
    const isDuo = values.meetingType === 'duo';
    return (
      <div className={classModifier('booking-form__fields-wrapper', [
        'select-fields', isDuo && 'is-duo'
      ])}
      >
        <Field
          name='date'
          component={CalendarFormField}
          isDisabled={!values.profile}
          openImmediately
        />

        <div className={classModifier('booking-form__field', 'duration')}>
          <Field
            name='bookingDuration'
            component={SelectFormField}
            fieldType='bookingDuration'
            options={getCurrentBookingDuration(values.type, values.profile, values.escort, values.meetingType === 'duo')}
            isDisabled={!values.profile || !values.type}
            onBookingDurationChange={duration => bookingRateUpdate(
              values.profile, duration, values.type, values.rate, form.change, values.escort
            )}
          />
        </div>

        {overlaps.isOverlaps && (
          <OverlappedBookingsMsg
            conflictBookings={overlaps.conflictBookings}
            values={values}
            submitForm={saveBooking}
          />
        )}
      </div>
    )
  }

  const handleDeleteProfile = (formChange, fieldName, values) => {
    if (fieldName === 'profile') {
      formChange('profile', values.escort);
      formChange('services', values.escort?.services);
      formChange('taxi', values.escort?.taxi);
      formChange('rate', values.escort?.rate);
      formChange('discount', values.escort?.discount);
      values.type === 'incall_duo' && formChange('type', values.escort?.taxi);
    } else formChange(fieldName, undefined);

    formChange('meetingType', 'solo');
  };

  const updateAttachments = useCallback((attachments, form, values) => {
    setAttachments((prev) => ([...prev, ...attachments]))
    form.change('notesAttachments', [...values.notesAttachments, ...attachments])
  }, []);

  const deleteAttachemnts = useCallback((index, form, values) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
    form.change('notesAttachments', values.notesAttachments.filter((_, i) => i !== index));
  }, [])

  const renderBookingDetails = (values) => {
    const parts = [];

    if (values.date) {
      parts.push(
        <DateTime
          date={values.date}
          config={BOOKING_DETAILS_DATE_CONFIG}
        />
      )
    }

    if (values.type) {
      parts.push(
        <span className='booking-form__booking-details-title-label'>
          {getLabelByType(values)}
        </span>
      )
    }

    if (values.bookingDuration) {
      parts.push(values.bookingDuration.label);
    }

    if (values.rate) {
      const totalRate = values.escortRate 
        ? values.rate.value + values.escortRate?.value
        : values.rate.value;

      parts.push(`£${totalRate}`);
    }

    return setDeviderBetweenJSX(parts, ' / ');
  }

  const onAddClient = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchSearchClientsContacts,
      itemComponent: SearchListContactItem,
      onChoose: (selectedContact) => {
        setActiveSessionContact(selectedContact);
        dispatch(closeModal(MODAL_TYPES.SearchList));
      },
    }))
  }

  const onAddGirl = () => {
    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData: fetchDivaGirls,
      itemComponent: SearchListDivaItem,
      onChoose: (selectedContact) => {
        for (let serviceId in selectedContact.services) {
          selectedContact.services[serviceId].id = serviceId
        }

        setProfile(selectedContact);
        dispatch(closeModal(MODAL_TYPES.SearchList));
      },
    }))
  }

  return (
    <Form
      onSubmit={(values) => saveBooking({ values })}
      validate={validateForm}
      initialValues={initialBookingValues}
      mutators={arrayMutators}
      render={({ handleSubmit, values, form }) => {
        const isDuo = values.meetingType === 'duo';
        const isSolo = values.meetingType === 'solo';
        const isDisabledTaxiSolo = values.type === 'incall' && isSolo;

        let [soloBookingType, duoBookingType] = calculateActualTypes(values.type, isDuo);

        useEffect(() => {
          return () => {
            wasBookingCreated && dispatchCloseEvent({ pickedDate: values.date });
          }
        }, [wasBookingCreated, values.date])

        useDidUpdate(() => {
          let newDuration;
          const actualDurations = getCurrentBookingDuration(values.type, values.profile, values.escort, isDuo);
          const isCurrentDurationFindedInActuals = values.bookingDuration && !actualDurations.find((duration) => values.bookingDuration.label === duration?.label);

          if (values.bookingDuration && isCurrentDurationFindedInActuals) {
            form.change('bookingDuration', actualDurations[0]);
            newDuration = actualDurations[0];
          }

          bookingRateUpdate(
            values.profile,
            newDuration || values.bookingDuration,
            values.type,
            values.rate,
            form.change,
            values.escort
          )
        }, [isDuo]);

        useEffect(() => {
          setOverlaps({ conflictBookings: [], isOverlaps: false })
        }, [values.date, values.meetingType])

        useDidUpdate(() => {
          if (isDisabledTaxiSolo) {
            form.change('taxi', { value: null, label: '-' });
          }
        }, [values.type]);

        const classes = classNames(
          'booking-form',
          classModifier('modal', [
            modalsTransitionModes[MODAL_TYPES.bookingForm],
            MODAL_TYPES.bookingForm
          ]),
        )

        const handleNameClick = (event) => {
          event.stopPropagation();

          dispatch(openModal(MODAL_TYPES.contactCard, { contact: activeSessionContact }))
        }

        return (
          <form onSubmit={handleSubmit} className={classes}>
            <div className='booking-form__header'>
              <div className='booking-form__btns-wrap'>
                <button
                  type='button'
                  onClick={() => props.closeModal()}
                  className={classModifier('booking-form__btn', 'cancel')}
                >
                  Cancel
                </button>

                <Field
                  isBooking
                  name="escort"
                  fieldType='diva'
                  isSetProfileToFormState
                  bookingDivaId={[values.profile?.id, values.escort?.id]}
                  component={FeedbackContactField}
                  setMeetingType={() => form.change('meetingType', 'duo')}
                  isDisabled={!values.profile}
                />

                <BookingSpecialRequestField
                  isRequestFieldOpen={isRequestFieldOpen}
                  setIsRequestFieldOpen={setIsRequestFieldOpen}
                  isDisabled={!values.profile}
                  callerId={activeSessionContact?.id}
                />
              </div>

              <div className='booking-form__btns-wrap'>
                <button
                  type="submit"
                  disabled={overlaps.isOverlaps || !(profile && activeSessionContact)}
                  className={classModifier('booking-form__btn', 'submit')}
                >
                  Submit Booking
                </button>

                <Field name="bookingStatus">
                  {({ input: { value } }) => (
                    <BookingFormStatusField
                      form={form}
                      status={value}
                      isDisabled={!values.profile}
                      isCreateBooking
                    />
                  )}
                </Field>
              </div>
            </div>

            {activeSessionContact && (
              <ExistedBookingsList
                clientId={activeSessionContact.id}
                userTimezone={userTimezone}
              />
            )}

            <div className='booking-form__main'>
              <div className='booking-form__content-wrapper'>
                {!!isDuo && <span className='booking-form__duo-logo'>DUO</span>}

                <div className={classModifier('booking-form__fields-wrapper', [
                  'contacts', isDuo && 'is-duo'
                ])}>
                  <div
                    className='booking-form__client-info-block-wrapper'
                  >
                    {!activeSessionContact
                      ? (
                        <Button
                          type="button"
                          className="booking-form__choose-button"
                          theme={BUTTON_THEMES['inversion-light']}
                          icon={<ICONS.plusCircle />}
                          {...(!activeSessionContact ? { onClick: onAddClient } : {})}
                        >
                          Add Client
                        </Button>
                      )
                      : (
                        <div
                          className="booking-form__client-info-block"
                          onClick={onAddClient}
                        >
                          <div className='booking-form__client-info'>
                            <div className='booking-form__avatar-container'>
                              <LazyLoadImage src={getContactAvatar(activeSessionContact)} alt="ava" />
                              {LastInteractionIcon && (
                                <LastInteractionIcon className = {classModifier("booking-form__channel-type", lastInteractionType)} />
                              )}
                            </div>
                            <div className='booking-form__client-text-content'>
                              <span
                                className='booking-form__client-name'
                                onClick={handleNameClick}
                              >
                                {activeSessionContact.fn}
                              </span>

                              <span className='booking-form__client-contact'>
                                {activeSessionContact?.tels[0]?.tel || activeSessionContact?.emails[0]?.email || '(no data)'}
                              </span>
                            </div>
                          </div>

                          <ContactTags
                            className="booking-form__client-tags-wrapper"
                            tagClassName="booking-form__client-tag"
                            callers={activeSessionContact}
                            wrapper={OverflowedTags}
                          />
                        </div>
                      )}

                    {isDuo &&
                      <div className='booking-form__booking-type-duo-wrapper'>
                        <BookingFieldType
                          getCurrentSelectedRate={(duration, type) => bookingRateUpdate(
                            values.profile, duration, type, values.rate, form.change, values.escort
                          )}
                          availableSoloTypes={isProfileOnlyIncallOrOutcall(values.profile)}
                          availableDuoTypes={isProfileOnlyIncallOrOutcall(values.escort)}
                          formChange={form.change}
                          actualType={values.type}
                        />
                      </div>
                    }
                  </div>


                  {isDuo
                    ? renderDateFieldsFormGroup(values, form, soloBookingType, duoBookingType)
                    : (
                      <div className='booking-form__selected-profiles'>
                        <BookingSelectedProfile
                          notForIds={notForIds}
                          profile={values.profile}
                          {...(!initialProfile ? { onClick: onAddGirl } : {})}
                        />
                      </div>
                    )
                  }
                </div>

                <div className={classModifier('booking-form__fields-wrapper', 'time')}>
                  {isSolo &&
                    <BookingFieldType
                      getCurrentSelectedRate={(duration, type) => bookingRateUpdate(
                        values.profile, duration, type, values.rate, form.change, values.escort
                      )}
                      availableSoloTypes={isProfileOnlyIncallOrOutcall(values.profile)}
                      formChange={form.change}
                      isDisabled={!profile}
                      soloBookingType={soloBookingType}
                      actualType={values.type}
                    />
                  }

                  {isDuo &&
                    <div className='booking-form__selected-profiles'>
                      <BookingSelectedProfile
                        notForIds={notForIds}
                        profile={values.profile}
                        isCanNotBeDeleted
                      />
                    </div>
                  }

                  <div className='booking-form__field-select-group'>
                    <div className={classModifier('booking-form__field', 'rate')}>
                      <Field
                        name='rate'
                        fieldType='rate'
                        component={SelectFormField}
                        options={!values.profile ? [] : getCurrentSelectedRate(soloBookingType, values.bookingDuration, values.profile)}
                        isDisabled={!values.profile || !values.type}
                      />
                    </div>

                    <div className={classModifier('booking-form__field', 'discount')}>
                      <Field
                        isSearchable
                        name='discount'
                        fieldType='discount'
                        component={SelectFormField}
                        isDisabled={!values.profile}
                      />
                    </div>

                    <div className={classModifier('booking-form__field', 'taxi')}>
                      <Field
                        name='taxi'
                        isSearchable
                        fieldType='taxi'
                        component={SelectFormField}
                        isDisabled={!values.profile || isDisabledTaxiSolo}
                      />
                    </div>

                    {!(!values.profile || isDisabledTaxiSolo) &&
                      <Field
                        name="taxiNoteSolo"
                        component="textarea"
                        placeholder="Taxi note"
                        className={classModifier('select-form-field__label', 'taxi-note')}
                      />
                    }
                  </div>

                  {isDuo &&
                    <button
                      type='button'
                      onClick={() => handleDeleteProfile(form.change, 'profile', values)}
                      className={classModifier('booking-form__btn', 'clear-profile')}
                    >
                      <ICONS.plusCircle className={classModifier('booking-form__btn-icon', 'clear-profile')} />
                    </button>
                  }
                </div>

                {isSolo && renderDateFieldsFormGroup(values, form, soloBookingType, duoBookingType)}

                {profile &&
                  <FieldArray
                    name='services'
                    component={BookingServicesField}
                    clearServices={() => form.change('services', profile?.services)}
                    isCollapsed
                  />
                }

                {isDuo && <>
                  <div className={classModifier('booking-form__fields-wrapper', 'time')}>
                    <div className='booking-form__selected-profiles'>
                      <BookingSelectedProfile
                        notForIds={notForIds}
                        profile={values.escort}
                        isCanNotBeDeleted
                      />
                    </div>

                    <div className='booking-form__field-select-group'>
                      <div className={classModifier('booking-form__field', 'rate')}>
                        <Field
                          name='escortRate'
                          fieldType='rate'
                          component={SelectFormField}
                          options={getCurrentSelectedRate(duoBookingType, values.bookingDuration, values.escort)}
                          isDisabled={!values.type}
                        />
                      </div>

                      <div className={classModifier('booking-form__field', 'discount')}>
                        <Field
                          isSearchable
                          name='escort.discount'
                          fieldType='discount'
                          component={SelectFormField}
                        />
                      </div>

                      <div className={classModifier('booking-form__field', 'taxi')}>
                        <Field
                          name='escort.taxi'
                          isSearchable
                          fieldType='taxi'
                          component={SelectFormField}
                        />
                      </div>

                      <Field
                        name="taxiNoteDuo"
                        component="textarea"
                        placeholder="Taxi note"
                        className={classModifier('select-form-field__label', 'taxi-note')}
                      />
                    </div>

                    <button
                      type='button'
                      onClick={() => handleDeleteProfile(form.change, 'escort')}
                      className={classModifier('booking-form__btn', 'clear-profile')}
                    >
                      <ICONS.plusCircle className={classModifier('booking-form__btn-icon', 'clear-profile')} />
                    </button>
                  </div>

                  <FieldArray
                    name='escort.services'
                    component={BookingServicesField}
                    clearServices={() => form.change('services', values.escort?.services)}
                    isCollapsed
                  />
                </>}

                <div className={classModifier('booking-form__fields-wrapper', 'location')}>
                  <div className={classModifier('booking-form__field', 'location')}>
                    <BookingLocationMap
                      profile={values.type === 'incall_duo' ? values?.escort : profile}
                      type={values.type}
                      isDisabled={!profile}
                      callerId={activeSessionContact?.id}
                    />
                  </div>
                </div>
              </div>

              <div className='booking-form__info-wrapper'>
                <div className={classModifier('booking-form__booking-details-wrapper', 'info')}>

                  <div className='booking-form__booking-details-group'>
                    <h4 className='booking-form__booking-details-title'>Booking details:</h4>

                    <div>
                      {renderBookingDetails(values).map((value, idx) => (
                        <span key={idx}>{value}</span>
                      ))}
                    </div>
                  </div>

                  {/* SOLO DETAILS */}

                  {isDuo &&
                    <div className={classModifier('booking-form__booking-details-group', [
                      'girl-name', values.profile?.agent_id && 'has-agent'
                    ])}>
                      <h4 className={classModifier('booking-form__booking-details-title')}>
                        {values.profile?.name}
                        {values.profile?.agent_id && <ICONS.a className='booking-form__agent-icon' />}
                      </h4>
                    </div>
                  }

                  <div className='booking-form__booking-details-group'>
                    <h4 className='booking-form__booking-details-title'>Services:</h4>
                    {renderCheckedServices(values.services || [])}
                  </div>

                  <div className='booking-form__booking-details-group-block'>
                    <div className={classModifier('booking-form__booking-details-group', 'rate')}>
                      <h4 className='booking-form__booking-details-title'>Rate:</h4>
                      {values.rate && <span>£{values.rate?.value}</span>}
                    </div>

                    <div className={classModifier('booking-form__booking-details-group', 'discount')}>
                      <h4 className='booking-form__booking-details-title'>Discount:</h4>
                      {values.discount && <span>£{values.discount?.value}</span>}
                    </div>

                  </div>

                  <div className='booking-form__booking-details-group-block'>
                    <div className={classModifier('booking-form__booking-details-group', 'extras')}>
                      <h4 className='booking-form__booking-details-title'>Extras:</h4>
                      {renderPaidServices(values.services || [])}
                    </div>

                    <div className='booking-form__booking-details-group'>
                      <h4 className='booking-form__booking-details-title'>Taxi:</h4>
                      {values.taxi && <span>£{values.taxi?.value}</span>}
                    </div>
                  </div>

                  {/* DUO DETAILS */}

                  {isDuo &&
                    <>
                      <div className={classModifier('booking-form__booking-details-group', [
                        'girl-name', values.escort?.agent_id && 'has-agent'
                      ])}>
                        <h4 className={classModifier('booking-form__booking-details-title')}>
                          {values.escort?.name}
                          {values.escort?.agent_id && <ICONS.a className='booking-form__agent-icon' />}
                        </h4>
                      </div>

                      <div className='booking-form__booking-details-group'>
                        <h4 className='booking-form__booking-details-title'>Services:</h4>
                        {renderCheckedServices(values.escort?.services || [])}
                      </div>

                      <div className='booking-form__booking-details-group-block'>
                        <div className={classModifier('booking-form__booking-details-group', 'rate')}>
                          <h4 className='booking-form__booking-details-title'>Rate:</h4>
                          {values.escortRate && <span>£{values.escortRate?.value}</span>}
                        </div>

                        <div className={classModifier('booking-form__booking-details-group', 'discount')}>
                          <h4 className='booking-form__booking-details-title'>Discount:</h4>
                          {values.escort?.discount && <span>£{values.escort?.discount?.value}</span>}
                        </div>

                      </div>

                      <div className='booking-form__booking-details-group-block'>
                        <div className={classModifier('booking-form__booking-details-group', 'extras')}>
                          <h4 className='booking-form__booking-details-title'>Extras:</h4>
                          {renderPaidServices(values.escort?.services || [])}
                        </div>

                        <div className='booking-form__booking-details-group'>
                          <h4 className='booking-form__booking-details-title'>Taxi:</h4>
                          {values.escort?.taxi && <span>£{values.escort?.taxi?.value}</span>}
                        </div>
                      </div>
                    </>
                  }

                  <div className='booking-form__booking-details-group'>
                    <h4 className='booking-form__booking-details-title'>Total:</h4>
                    <span className='booking-form__booking-total-rate'>
                      {values.rate?.value ? `£${getTotalRate(values)}` : '-'}
                    </span>
                  </div>

                </div>

                <div className={classModifier('booking-form__booking-details-wrapper', isDuo && 'requirements-duo')}>
                  {isDuo &&
                    <h4 className={classModifier('booking-form__requirements-title', values.profile?.agent_id && 'has-agent')}>
                      {values.profile?.name}
                      {values.profile?.agent_id && <ICONS.a className='booking-form__agent-icon' />}
                    </h4>
                  }
                  {!!values.requirements?.length &&
                    <FieldArray
                      name='requirements'
                      component={BookingRequirements}
                      isDisabled={!values.profile}
                      actualType={values.type}
                    />
                  }
                </div>

                {isDuo && !!values.duoRequirements?.length &&
                  <div className='booking-form__booking-details-wrapper'>
                    <h4 className={classModifier('booking-form__requirements-title', values.escort?.agent_id && 'has-agent')}>
                      {values.escort?.name}
                      {values.escort?.agent_id && <ICONS.a className='booking-form__agent-icon' />}
                    </h4>
                    <FieldArray
                      name='duoRequirements'
                      component={BookingRequirements}
                      actualType={values.type}
                    />
                  </div>
                }

                <div className={classModifier('booking-form__booking-details-wrapper', 'messages')}>
                  <BookingSpecialRequestMessages
                    isDisabled={!values.profile}
                    isRequestMessageFieldOpen={isRequestMessageFieldOpen}
                    setIsRequestMessageFieldOpen={setIsRequestMessageFieldOpen}
                  />

                  <div className='booking-form__messages-list'>
                    {values.scheduleMessages?.map((msg, idx) => (
                      <div key={idx} className={classModifier('booking-form__booking-details-group', 'request-msg')}>
                        <div className='booking-form__booking-details-group'>
                          <h4 className='booking-form__booking-details-title'>Message to client:</h4>
                          {msg.date &&
                            <DateTime
                              date={msg.date}
                              config={BOOKING_MESSAGES_DATE_CONFIG}
                            />
                          }
                        </div>
                        <span className='booking-form__booking-request-msg'>{msg.body}</span>
                      </div>
                    ))}
                  </div>
                </div>

                <div className={classModifier('booking-form__booking-details-wrapper', 'notes')}>
                  <BookingNotes
                    attachments={attachments}
                    updateAttachments={(attachment) => updateAttachments(attachment, form, values)}
                    deleteAttachemnts={(i) => deleteAttachemnts(i, form, values)}
                    isDisabled={!values.profile}
                  />
                </div>
              </div>

              {isOpenFrontWindow &&
                <div className='booking-form__front-wrap'>
                  {isLoading
                    ? <Spinner spinnerSize={150} />
                    : <div className='booking-form__front-wrap-success'>
                      <h3 className='booking-form__front-wrap-title'>Booking has been submitted!</h3>
                      <button
                        type='button'
                        onClick={() => props.closeModal()}
                        className={classModifier('booking-form__btn', 'done')}
                      >
                        Done
                      </button>

                      <button
                        type='button'
                        onClick={() => setIsOpenFrontWindow(false)}
                        className={classModifier('booking-form__btn', 'book-another')}
                      >
                        Book {profile.name} {isDuo && `and ${values.escort.name} `}again
                      </button>
                    </div>
                  }
                </div>
              }
            </div>
          </form>
        )
      }}
    />
  )
}


const mSTP = (state) => ({
  requirements: selectAllRequirements(state),
  userHour12: state.user.hour12,
  userTimezone: selectUserTimezone(state),
  girlsIdsFromActiveChats: state.girlChats.tabs,
  activeSession: state.sessions
});

const mDTP = ({
  createBookingByValues,
})

const validateForm = values => {
  const errors = {};

  setRequiredIfEmpty('type');
  setRequiredIfEmpty('rate');
  setRequiredIfEmpty('bookingDuration');
  setRequiredIfEmpty('date');
  checkSelectValue('discount');
  checkSelectValue('taxi');
  setRequiredIfEmptyOutcallAddress('latitude');
  setRequiredIfEmptyOutcallAddress('longitude');
  setRequiredIfEmptyOutcallAddress('locationName');
  values.escort && setRequiredIfEmpty('escortRate');

  return errors;

  function setRequiredIfEmpty(fieldName) {
    if (!values[fieldName]) {
      errors[fieldName] = 'Required';
    }
  }

  function checkSelectValue(fieldName) {
    const value = values[fieldName]?.value;
    const isNumeric = (/^[\d]+$/g).test(value);
    if (value !== null && !isNumeric) {
      errors[fieldName] = 'Enter a numeric value';
    }

    if (value < 0) {
      errors[fieldName] = 'Enter a valid value';
    }

    if (fieldName === 'discount' && getTotalRate(values) < 0) {
      const checkValue = Math.abs(getTotalRate(values) + values.discount?.value);
      errors[fieldName] = `Enter a value less than £${checkValue}`;
    }
  }

  function setRequiredIfEmptyOutcallAddress(fieldName) {
    if (values.type === 'outcall' && !values.address[fieldName]) {
      errors[fieldName] = 'Required';
    }
  }
}

export default connect(mSTP, mDTP)(BookingForm);

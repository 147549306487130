import React, { useEffect } from 'react';
import { FormSpy } from 'react-final-form';

import { useDeepEffect, usePrevious } from 'hooks';
import { isEmptyObj } from 'utils';
import { CONTACT_TYPES } from 'config/constants';

const isFormEdited = (values, initialValues, dirtyFields, isPhotoFile, isEditedTags) => {
  const isNotEqualObjects = (initialValues, values, fieldName) => {
    if (values?.length !== initialValues?.length) {
      return true;
    }
    switch (fieldName) {
      case 'tels': {
        return values?.some((obj, idx) => (
          obj.tel !== initialValues[idx].tel || 
          obj.uuid !== initialValues[idx].uuid
        ))
      }

      case 'emails': {
        return values?.some((obj, idx) => (
          obj.email !== initialValues[idx].email ||
          obj.uuid !== initialValues[idx].uuid
        ))
      }

      case 'telegram_nicknames': {
        return values?.some((obj, idx) => (
          obj.nickname !== initialValues[idx].nickname
        ))
      }

      case 'urls': {
        return values?.some((obj, idx) => (
          obj.url !== initialValues[idx].url ||
          obj.title !== initialValues[idx].title
        ))
      }

      case 'addresses': {
        return values?.some((obj, idx) => (
          obj.caller_id !== initialValues[idx].caller_id ||
          obj.line1 !== initialValues[idx].line1 || 
          obj.line2 !== initialValues[idx].line2 ||
          obj.city !== initialValues[idx].city ||
          obj.postcode !== initialValues[idx].postcode
        ))
      }

      default: return false;
    }
  }

  const isClientType = +values.type === CONTACT_TYPES.CLIENT;
  const isGirlType = +values.type === CONTACT_TYPES.GIRL;

  // formValuesTable(values, initialValues, dirtyFields, isPhotoFile, isEditedTags, isNotEqualObjects, isClientType, isGirlType);

  return (
    !!isPhotoFile ||
    !!isEditedTags ||
    ((!isClientType && !isGirlType) && values.fn !== initialValues.fn) ||
    (values.note || '') !== (initialValues.note || '') ||
    (isGirlType && values.fnParts?.custom_id !== initialValues.fnParts?.custom_id) ||
    (isGirlType && values.fnParts?.emoji !== initialValues.fnParts?.emoji) ||
    (isGirlType && values.fnParts?.location !== initialValues.fnParts?.location) ||
    (isGirlType && values.fnParts?.name !== initialValues.fnParts?.name) ||
    (isGirlType && values.fnParts?.nationality !== initialValues.fnParts?.nationality) ||
    (isGirlType && values.fnParts?.prices !== initialValues.fnParts?.prices) ||
    (isGirlType && values.fnParts?.feedback_letters !== initialValues.fnParts?.feedback_letters) ||
    (isGirlType && values.is_mistress && values.fnParts?.custom_id_mistress !== initialValues.fnParts?.custom_id_mistress) ||
    (isGirlType && values.is_mistress && values.fnParts?.feedback_letters_mistress !== initialValues.fnParts?.feedback_letters_mistress) ||
    (isGirlType && values.is_mistress && values.fnParts?.emoji_mistress !== initialValues.fnParts?.emoji_mistress) ||

    (+values.type === CONTACT_TYPES.CLIENT && (values.description !== initialValues.description)) ||
    (+values.type === CONTACT_TYPES.CLIENT && ((values.name || '') !== (initialValues.name || ''))) ||
    (+values.type === CONTACT_TYPES.CLIENT && ((values.description || '') !== (initialValues.description || ''))) ||
    (!isGirlType && (values.custom_id || null) !== (initialValues.custom_id || null)) ||
    Boolean(dirtyFields.type) ||
    Boolean(initialValues.is_ex) !== Boolean(values.is_ex) ||
    Boolean(initialValues.is_mistress) !== Boolean(values.is_mistress) ||
    Boolean(initialValues.is_top) !== Boolean(values.is_top) ||
    initialValues.is_banned_for_media !== values.is_banned_for_media ||
    isNotEqualObjects(initialValues.tels, values.tels, 'tels') ||
    isNotEqualObjects(initialValues.urls, values.urls, 'urls') ||
    isNotEqualObjects(initialValues.telegram_nicknames, values.telegram_nicknames, 'telegram_nicknames') ||
    isNotEqualObjects(initialValues.emails, values.emails?.filter(item => !!item.email), 'emails')
    // isNotEqualObjects(initialValues.addresses || [], values.addresses?.filter(item => !!item.address), 'addresses')
  )
}

const AdrBookContactFormAutoSave = props => {
  const { 
    form,
    active, 
    values,
    invalid,
    dirtyFields,
    isPhotoFile, 
    saveContact,
    callbacksRef,
    isEditedTags,
    lastStateListItem = {},
    isNewContactCreation,
  } = props;
  
  const prev = usePrevious(active);

  const formViaValues = values.via;
  const isAnyFormFieldEdited = !invalid && isFormEdited(
    values, 
    lastStateListItem,
    dirtyFields,
    isPhotoFile,
    isEditedTags
  );

  if(callbacksRef?.current) {
    callbacksRef.current.saveContact = () => saveContact();
  }

  useEffect(() => {
    props.setEdited && props.setEdited(isAnyFormFieldEdited);
  })

  useEffect(() => {
    // Saving contact data in case of phone/email deletion. If it was 
    // previously selected in the "via" field, the form state will be 
    // updated and the save will be performed on the onChange event 
    // for "via"

    const phoneUuid = formViaValues?.via_phone_uuid;
    const smsPhoneUuid = formViaValues?.via_sms_phone_uuid;
    const emailUuid = formViaValues?.via_email_uuid;

    const isDeletedTelsWithVia = values.tels?.some(item => item.uuid === phoneUuid) !==
      lastStateListItem.tels?.some(item => item.uuid === phoneUuid);

    const isDeletedTelsWithSMSVia = values.tels?.some(item => item.uuid === smsPhoneUuid) !==
      lastStateListItem.tels?.some(item => item.uuid === smsPhoneUuid);
      
    const isDeletedEmailsWithVia = values.emails?.some(item => item.uuid === emailUuid) !==
      lastStateListItem.emails?.some(item => item.uuid === emailUuid);

    if (!isNewContactCreation && lastStateListItem.tels?.length > values.tels?.length) {
      if(isDeletedTelsWithVia && isDeletedTelsWithSMSVia) {
        form.change('via.via_phone_uuid', null);
        form.change('via.via_sms_phone_uuid', null);
      } 
      else if(isDeletedTelsWithVia && !isDeletedTelsWithSMSVia) {
        form.change('via.via_phone_uuid', null);
      }
      else if(!isDeletedTelsWithVia && isDeletedTelsWithSMSVia) {
        form.change('via.via_sms_phone_uuid', null);
      }
      else saveContact();
    }

    if (!isNewContactCreation && lastStateListItem.emails?.length > values.emails?.length) {  
      isDeletedEmailsWithVia ? form.change('via.via_email_uuid', null) : saveContact();
    }

    if (!isNewContactCreation && lastStateListItem.telegram_nicknames?.length > values.telegram_nicknames?.length) {  
      saveContact();
    }
  }, [values.tels, values.emails, values.telegram_nicknames]);

  useDeepEffect(() => {
    const initialValuesVia = lastStateListItem?.via;

    const isEditedVia = !!formViaValues?.via_phone_uuid !== !!initialValuesVia?.via_phone_uuid ||
      !!formViaValues?.via_sms_phone_uuid !== !!initialValuesVia?.via_sms_phone_uuid ||
      !!formViaValues?.via_email_uuid !== !!initialValuesVia?.via_email_uuid ||
      !!formViaValues?.via_telegram !== !!initialValuesVia?.via_telegram;

    // condition to save edited fields "via" after getting correct formViaValues
    if (formViaValues) {
      !isNewContactCreation && isEditedVia && saveContact();
    }
  }, [formViaValues]);

  useDeepEffect(() => {
    let isLabelChanged = false;

    //check has label been changed
    for (let [i, tel] of Object.entries(values.tels || {})) {
      if (tel.tel && lastStateListItem?.tels?.[i] && tel.labelName !== lastStateListItem?.tels?.[i]?.labelName) {
        isLabelChanged = true;
        break;
      }
    }

    !isEmptyObj(values) && !isNewContactCreation && isLabelChanged && saveContact();
  }, [values.tels]);

  useEffect(() => {
    // comparing the previous and active field value to simulate the onBlur event

    if ((active || prev) && active !== prev) {
      !isNewContactCreation && isAnyFormFieldEdited && saveContact();
    }

  }, [active]);

  return null;
}

export default props => ( 
  <FormSpy
    {...props}
    subscription={{
      values: true, 
      active: true,
      invalid: true,
      dirtyFields: true,
    }}
    component={AdrBookContactFormAutoSave}
  />
)

// Test function for viewing changed form fields in table


// const formValuesTable = (values, initialValues, dirtyFields, isPhotoFile, isEditedTags, isNotEqualObjects, isClientType, isGirlType) => {
//   class FormField {
//     constructor(fieldName, values, initialValues, calculation) {
//       this.fieldName = fieldName;
//       this.values = values;
//       this.initialValues = initialValues;
//       this.result = calculation;
//     }
//   }
  
//   const isPhotoFileObj = new FormField('isPhotoFile', isPhotoFile, isPhotoFile, !!isPhotoFile);
//   const isEditedTagsObj = new FormField('isEditedTags', isEditedTags, isEditedTags, !!isEditedTags);
//   const fn = new FormField('fn', values.fn, initialValues.fn, ((!isClientType && !isGirlType) && values.fn !== initialValues.fn));
//   const note = new FormField('note', values.note, initialValues.note, (values.note || '') !== (initialValues.note || ''));
//   const is_top = new FormField('is_top', values.is_top, initialValues.is_top, (isGirlType && values.is_top !== initialValues.is_top));
//   const custom_id_girl = new FormField('custom_id (fnParts)', values.fnParts?.custom_id, initialValues.fnParts?.custom_id, (isGirlType && values.fnParts?.custom_id !== initialValues.fnParts?.custom_id));
//   const emoji_girl = new FormField('emoji (fnParts)', values.fnParts?.emoji, initialValues.fnParts?.emoji, (isGirlType && values.fnParts?.emoji !== initialValues.fnParts?.emoji));
//   const location_girl = new FormField('location (fnParts)', values.fnParts?.location, initialValues.fnParts?.location, (isGirlType && values.fnParts?.location !== initialValues.fnParts?.location));
//   const name_girl = new FormField('name (fnParts)', values.fnParts?.name, initialValues.fnParts?.name, (isGirlType && values.fnParts?.name !== initialValues.fnParts?.name));
//   const nationality_girl = new FormField('nationality (fnParts)', values.fnParts?.nationality, initialValues.fnParts?.nationality, (isGirlType && values.fnParts?.nationality !== initialValues.fnParts?.nationality));
//   const prices_girl = new FormField('prices (fnParts)', values.fnParts?.prices, initialValues.fnParts?.prices, (isGirlType && values.fnParts?.prices !== initialValues.fnParts?.prices));
//   const feedback_letters_girl = new FormField('feedback_letters (fnParts)', values.fnParts?.feedback_letters, initialValues.fnParts?.feedback_letters, (isGirlType && values.fnParts?.feedback_letters !== initialValues.fnParts?.feedback_letters));
//   const name_root = new FormField('name', values.name, initialValues.name, (+values.type === CONTACT_TYPES.CLIENT && ((values.name || '') !== (initialValues.name || ''))));
//   const description_root = new FormField('description', values.description, initialValues.description, (+values.type === CONTACT_TYPES.CLIENT && ((values.description || '') !== (initialValues.description || ''))));
//   const custom_id_root = new FormField('custom_id (root)', values.custom_id, initialValues.custom_id, (!isGirlType && (values.custom_id || null) !== (initialValues.custom_id || null)));
//   const dirtyFields_root = new FormField('dirtyFields.type', dirtyFields.type, dirtyFields.type, Boolean(dirtyFields.type));
//   const is_ex = new FormField('is_ex', values.is_ex, initialValues.is_ex, Boolean(initialValues.is_ex) !== Boolean(values.is_ex));
//   const is_mistress = new FormField('is_mistress', values.is_mistress, initialValues.is_mistress, Boolean(initialValues.is_mistress) !== Boolean(values.is_mistress));
//   const is_banned_for_media = new FormField('is_banned_for_media', values.is_banned_for_media, initialValues.is_banned_for_media, initialValues.is_banned_for_media !== values.is_banned_for_media);
//   const tels = new FormField('tels', values.tels, initialValues.tels, isNotEqualObjects(initialValues.tels, values.tels, 'tels'));
//   const urls = new FormField('urls', values.urls, initialValues.urls, isNotEqualObjects(initialValues.urls, values.urls, 'urls'));
//   const telegram_nicknames = new FormField('telegram_nicknames', values.telegram_nicknames, initialValues.telegram_nicknames, isNotEqualObjects(initialValues.telegram_nicknames, values.telegram_nicknames, 'telegram_nicknames'));
//   const emails = new FormField('emails', values.emails, initialValues.emails, isNotEqualObjects(initialValues.emails, values.emails?.filter(item => !!item.email), 'emails'));
  
//   console.table([
//     fn, 
//     note,
//     is_mistress,
//     is_top,
//     custom_id_girl,
//     emoji_girl,
//     location_girl,
//     name_girl,
//     nationality_girl,
//     prices_girl,
//     feedback_letters_girl,
//     name_root,
//     description_root,
//     custom_id_root,
//     dirtyFields_root,
//     is_ex,
//     is_banned_for_media,
//     tels,
//     urls,
//     telegram_nicknames,
//     emails,
//     isPhotoFileObj, 
//     isEditedTagsObj, 
//   ]);
// }
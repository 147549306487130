import { useSelector } from 'react-redux';

import { NotificationBlock } from 'components/UI/NotificationBlock/NotificationBlock';


export const MailNotification = (props) => {
  const isMailPageOpen = useSelector((state) => state.user.isMailPageOpen);

  const handleOpenNotification = () => {
    const { conversationId } = props.item.data.mail;

    if (!isMailPageOpen) {
      return window.open(`/mail/inbox/email/${conversationId}`, "_blank");
    }

    const channel = new BroadcastChannel('tab_channel');
    channel.postMessage({ name: 'openMail', conversationId });
  }

  return (
    <NotificationBlock {...props}>
			<NotificationBlock.Body>
				{props.getText}
      </NotificationBlock.Body>

      <NotificationBlock.Footer iconType={props.iconType}>
        <button onClick={handleOpenNotification}>
          Open
        </button>
      </NotificationBlock.Footer>
    </NotificationBlock>
  )
}

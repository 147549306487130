import React, { useEffect, useState } from 'react';
import { usePopperTooltip } from 'react-popper-tooltip';

import { classModifier } from '../../utils';

import Portal from 'components/Portal';
import './InfoMenu.scss';

const InfoMenu = props => {
  const {
    title,
    menuItems = [],
    icon: Icon,
    iconSize = 16,
    iconProps,
    disabled,
    dropWrapperProps,
  } = props;

  const [isMenuActive, setIsMenuActive] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);

  const {
    getTooltipProps,
    setTooltipRef,
    setTriggerRef,
  } = usePopperTooltip({
    placement: 'top',
    visible: isMenuActive,
    ...dropWrapperProps,
  });

  const clearTimeoutId = () => {
    clearTimeout(timeoutId);
  };
  
  useEffect(() => {
    return () => {
      clearTimeoutId();
    }
  }, [timeoutId]);

  const handleBtnClick = (e, item) => {
    e.preventDefault();
    e.stopPropagation();

    item.action && item.action();

    setIsMenuActive(false);
  }

  const showMenu = () => {
    clearTimeoutId();
    setIsMenuActive(true);
  }

  const hideMenu = () => {
    const timeoutId = setTimeout(() => {
      setIsMenuActive(false);
    }, 300);

    setTimeoutId(timeoutId);
  }

  return (
    <div className="title__control-box">
      <button
        ref={setTriggerRef}
        type="button"
        className={classModifier("info-menu", isMenuActive && "active")}
        onMouseEnter={showMenu}
        onMouseLeave={hideMenu}
        disabled={disabled}
      >
        <Icon
          className="info-menu__icon"
          width={iconSize}
          height={iconSize}
          {...iconProps}
        />
      </button>

      {isMenuActive &&
        <Portal>
          <div 
            ref={setTooltipRef}
            {...getTooltipProps({ className: "info-menu__popup" })}
            onMouseEnter={showMenu}
            onMouseLeave={hideMenu}
          >
            <div className="info-menu__title">
                {title}
            </div>

            <div className="info-menu__actions">
                {menuItems.map((menuItem, idx) => {
                  if (menuItem.isHidden) return null;

                  return (
                    <button
                      title={menuItem.title}
                      key={idx}
                      className="info-menu__action-btn"
                      onClick={(e) => handleBtnClick(e, menuItem)}
                    >
                      {menuItem.icon}
                    </button>
                  );
                })}
            </div>
          </div>
        </Portal>
      }
    </div>
  );
};

export default InfoMenu;
import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  getDefaultField,
  isEmptyObj,
  classModifier,
} from 'utils';
import ICONS from 'assets/icons';
import { useToggle } from 'hooks';
import { getContactsById } from 'redux/ducks/contacts';
import { CHAT_TYPES, CONTACTS_LIMIT, INTERACTION_TYPES } from 'config/constants';
import { deletePinnedMessage } from 'redux/ducks/clientChats';
import { MODAL_TYPES, openModal } from 'redux/ducks/activeWindows';
import {
  selectOperatorByUserId,
  selectTelegramGroupMember
} from 'redux/selectors/selectors';
import { MESSAGE_DATE_CONFIG, MULTI_MESSAGE_DATE_CONFIG } from 'config/dates-сonfig';

import MsgText from './MsgText';
import RoomInteractionMenu from '../InteractionMenu/RoomInteractionMenu';
import ContactInteractionMenu from '../InteractionMenu/ContactInteractionMenu';
import InteractionAttachments from '../../ChatAttachments/InteractionAttachments';
import ScheduledMsgInteractionMenu from '../InteractionMenu/ScheduledMsgInteractionMenu';
import AttachmentRepliedMessage from '../../ChatAttachments/AttachmentRepliedMessage';
import QuickBookingGirlsListItemByDiva from '../../QuickBookingGirlsListItem/QuickBookingGirlsListItemByDiva';
import DateTime from 'components/DateTime';
import { getActiveDivaGirls } from 'redux/ducks/divaGirls';


const OutgoingMessage = ({ interaction, search, activeRecipient, profileId, contactSessionId, ...props }) => {
  const dispatch = useDispatch();
  const operator = useSelector(state => selectOperatorByUserId(state, interaction.user_id, profileId));
  const isMsgAlreadyPinned = useSelector(
    (state) =>
      activeRecipient?.type === CHAT_TYPES.CLIENT
        ? state.clientChats.pinnedMsgs.find(el => el.id === interaction.id)
        : state.girlChats.pinnedMsgs.find(el => el.id === interaction.id)
  );
  const remindsMsgsIds = useSelector(state => [...state.clientChats.remindersMsgsIds, ...state.girlChats.remindersMsgsIds]);
  const telegramSender = useSelector((state) => selectTelegramGroupMember(state, interaction.from_telegram_user_id))

  const [menu, toggleMenu] = useToggle(false);
  const [isAnimate, setIsAnimate] = useToggle(false);

  const msgRef = useRef();

  const hasReminder = remindsMsgsIds.includes(interaction.id);
  const isFromTelegram =
    interaction.type === INTERACTION_TYPES.OUTGOING_NIGHT_BOT_MESSAGE || interaction.type === INTERACTION_TYPES.OUTGOING_MSG_TELEGRAM;
  const isFromWhatsapp =
    interaction.type === INTERACTION_TYPES.OUTGOING_MSG_WHATSAPP ||
    interaction.messager === "whatsapp";
  const isFromIphone =
    interaction.type === INTERACTION_TYPES.OUTGOING_MSG_IPHONE_SMS ||
    interaction.type === INTERACTION_TYPES.OUTGOING_MSG_IPHONE_IMESSAGE ||
    interaction.messager === "iphone";
  const isFromTelegramP2P =
    interaction.type === INTERACTION_TYPES.OUTGOING_PRIVATE_MSG_TELEGRAM ||
    interaction.messager === "telegram_client";
  const isFromTelegramGroup = interaction.type === INTERACTION_TYPES.OUTGOING_TELEGRAM_GROUP_MESSAGE;
  const isScheduledMsg =
    interaction.type === INTERACTION_TYPES.SCHEDULE_MESSAGE;
  const isRoleAdmin =
    interaction.chat?.chatType === "general" &&
    interaction.sender?.authAssignment?.item_name === "admin";
  const isContextMsg = interaction.id === +props.contextMsgId; // cause props.contextMsgId is string

  useEffect(() => {
    if (isContextMsg) {
      setIsAnimate();

      setTimeout(() => setIsAnimate(false), 2000);
    }
  }, [isContextMsg]);

  const showInteractionMenu = () => {
    if (props.isEdited || interaction.is_deleted || interaction.deleted) {
      return null;
    }

    if (interaction.chatId) {
      return <RoomInteractionMenu
        isOpen={menu}
        closeMenu={toggleMenu}
        interaction={interaction}
        isMine={true}
        editMsg={props.editMsg}
        shareMsg={props.shareMsg}
      />
    }

    return isScheduledMsg
      ? (
        <ScheduledMsgInteractionMenu
          isOpen={menu}
          closeMenu={toggleMenu}
          interaction={interaction}
          userTimezone={props.userTimezone}
          editMsg={props.editMsg}
        />
      )
      : (
        <ContactInteractionMenu
          isOpen={menu}
          closeMenu={toggleMenu}
          interaction={interaction}
          search={search}
          replyMsg={props.replyMsg}
          userTimezone={props.userTimezone}
          activeRecipientType={activeRecipient.type}
          activeChannel={activeRecipient.default_channel}
        />
      )
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case 'pending': {
        return <ICONS.check className="interaction__status-icon interaction__status-icon--pending" title="pending" />;
      }

      case 'sent': {
        return <ICONS.check className="interaction__status-icon interaction__status-icon--sent" title="sent" />;
      }

      case 'delivered': {
        return (
          <>
            <ICONS.check className="interaction__status-icon interaction__status-icon--delivered" title="delivered" />
            <ICONS.check className="interaction__status-icon interaction__status-icon--delivered" title="delivered" />
          </>
        );
      }

      case 'undelivered':
      case 'failed': {
        return <ICONS.undo className="interaction__status-icon interaction__status-icon--failed" title="failed" />;
      }

      default:
        return <ICONS.check className="interaction__status-icon interaction__status-icon--pending" title="pending" />;
    }
  };

  const getStatus = () => {
    const isInteractionStatusError = interaction.status === 'failed' || interaction.status === 'undelivered';

    if (interaction.status) {
      return (
        <span
          className={classModifier('interaction__status', [
            isInteractionStatusError && 'error',
            (interaction.is_deleted || interaction.deleted) && 'deleted',
            (isFromWhatsapp && interaction.isRead) && 'read',
          ])}
        >
          {!(interaction.is_deleted || interaction.deleted) && getStatusIcon(interaction.status)}
          {isInteractionStatusError && 'error'}
          {(interaction.is_deleted || interaction.deleted) && 'Removed'}
        </span>
      );
    }
  };

  const getMsgSender = () => {
    let sender;
    let isIAmSender = false;

    if (interaction.user_id === profileId) {
      sender = operator;
      isIAmSender = true;
    }
    else if (interaction.senderId === profileId) {
      sender = interaction.sender;
      isIAmSender = true;
    }
    else {
      sender = operator || {};
    }

    return {
      sender,
      isIAmSender
    };
  };

  const renderHeader = () => {
    const { isIAmSender, sender } = getMsgSender();

    return isIAmSender ? (
      <span
        className={classModifier('interaction__name', [
          'out',
          'is-sender',
          isFromWhatsapp && 'whatsapp',
          isFromIphone && 'iphone',
        ])}
      >
        You
        {isFromTelegram && <ICONS.robot />}
        {isFromWhatsapp && <ICONS.whatsapp />}
        {isFromIphone && <ICONS.apple />}
        {isFromTelegramP2P && <ICONS.telegram />}
      </span>
    ) : (
      <Fragment>
        {isFromTelegram &&
          <ICONS.robot className={classModifier('interaction__icon-social', 'telegram')}/>
        }

        {isFromWhatsapp &&
          <ICONS.whatsapp className={classModifier('interaction__icon-social', 'whatsapp')}/>
        }

        {isFromIphone &&
          <ICONS.apple className={classModifier('interaction__icon-social', 'iphone')}/>
        }

        {isFromTelegramP2P &&
          <ICONS.telegram className={classModifier('interaction__icon-social', 'telegram')}/>
        }

        {isFromTelegramGroup && (
          telegramSender?.photo && (
            <div
              className={classModifier("interaction__ava", !interaction.body && 'is-empty-body')}>
              <img
                src={`data:image/jpeg;base64,${telegramSender.photo}`}
                alt="ava"
              />

              <ICONS.telegram className="interaction__ava-telegram-icon"/>
            </div>
          )
        )}

        <span className={classModifier('interaction__name', 'out')}>
          {((isFromTelegram || isFromWhatsapp || isFromIphone || isFromTelegramP2P) && isEmptyObj(sender))
            ? isFromTelegramP2P
              ? 'Telegram Client'
              : 'System Bot'
            : isFromTelegramGroup
              ? telegramSender?.first_name || 'Unknown User'
              : sender.username
          }
        </span>
      </Fragment>
    );
  };

  const searchByGirlName = useCallback((e) => {
    const initialQuery = e.currentTarget.getAttribute('data-value');

    const fetchData = async ({ query, cancelToken }) => {
      return await dispatch(getActiveDivaGirls({
        params: {
          'filter-is_available_now': query ? 0 : 1,
          'search': query,
          'includeoff': false, // param to get all diva profiles
          cancelToken,
        },
        quickBooking: true,
      }))
        .then((res) => {
          if (res.girls) {
            return ({
              newItems: res.girls,
              newHasMore: res.girls.length === CONTACTS_LIMIT,
            });
          }
        })
        .catch(console.error);
    }

    dispatch(openModal(MODAL_TYPES.searchList, {
      fetchData,
      initialQuery,
      itemComponent: QuickBookingGirlsListItemByDiva,
      itemProps: {
        activeRecipient,
        contactSessionId: contactSessionId,
      },
      isHandleClickCanBeRedifinedByItem: true,
    }));
  }, [contactSessionId]);

  const getMsgContext = (e) => {
    if (!search) {
      return;
    }

    if (!props.contextMsgId) {                          // if we not in Msg Context
      return e.target.className !== 'lazy-load__image'
        ? props.isGlobalSearch
          ? dispatch(getContactsById(interaction.caller_id, interaction.contact.type))
            .then((contact) => {
              props.getMessageContext(interaction.id, contact, search);
            })
          : props.getMessageContext(interaction.id, null, search)
        : null;
    }
  };

  const setContextRef = ref => {
    if (isContextMsg) {
      return props.contextMsgRef.current = ref;
    }
  };

  const isSentOnNotDefaultNumber = interaction.phone_number && getDefaultField(activeRecipient.tels, 'tel') !== interaction.phone_number;

  return (
    <>
      <li
        className={classModifier('interaction', [
          'out',
          interaction.isNew && 'new',
          (interaction.is_deleted || interaction.deleted) && 'deleted',
          props.activeChatSource === 'media' && interaction.attachments?.repliedMessage && 'hidden',
          isRoleAdmin && 'is-role-admin',
          interaction.type === INTERACTION_TYPES.OUTGOING_MSG_IPHONE_IMESSAGE && 'imessage',
          isFromWhatsapp && 'whatsapp',
        ])}
        // style={isContextMsg ? { border: "1px solid #0092F2" } : null}
        data-isanimate={isAnimate}
        onClick={getMsgContext}
        ref={setContextRef}
        // if we will use below {() => toggleMenu()} it will rerender this interaction
        onMouseEnter={() => toggleMenu(true)}
        onMouseLeave={() => toggleMenu(false)}
      >
        <header className="interaction__header" ref={msgRef}>
          <div className="interaction__header-info interaction__header-info--reverse">
            {renderHeader()}

            {/*{!!interaction.edited && <span className="interaction__edited">edited</span>}*/}

            <span className="interaction__time">
              {isScheduledMsg && 'Will be sent at: '}

              <DateTime
                date={isScheduledMsg ? interaction.date : interaction.dateCreated}
                config={isScheduledMsg ? MULTI_MESSAGE_DATE_CONFIG : MESSAGE_DATE_CONFIG}
              />
            </span>

            {getStatus()}

            {(isSentOnNotDefaultNumber && !isFromTelegramGroup) &&
              <span className="interaction__channel">
                {`(to: ${interaction.phone_number})`}
              </span>
            }

            {showInteractionMenu()}

            {isMsgAlreadyPinned &&
              <button
                className="interaction__btn-pinned"
                onClick={() => dispatch(deletePinnedMessage(isMsgAlreadyPinned.id, activeRecipient.type))}
              >
                <ICONS.pin className="interaction__icon-pinned" />
              </button>
            }

            {hasReminder &&
              <ICONS.bellFill className="interaction__icon-bell" />
            }
          </div>
        </header>

        <div
          className={classModifier('interaction__body-wrap', [
            (interaction.is_deleted || interaction.deleted) && 'deleted',
            (interaction.attachments?.images && interaction.body) && 'attachments',
            isRoleAdmin && 'is-role-admin',
            !interaction.body && !interaction.attachments && 'empty-body'
          ])}
          onClick={props.handleLinkClick}
        >
          {(menu && isFromIphone) &&
            <span className="interaction__msg-type">
                {interaction.type === 21 ? 'sms' : 'imsg'}
              </span>
          }

          {interaction.attachments?.repliedMessage &&
            <div className="interaction__replied-msg">
              <AttachmentRepliedMessage
                profileId={profileId}
                userHour12={props.userHour12}
                interaction={interaction.attachments.repliedMessage}
                userTimezone={props.userTimezone}
                getMessageContext={props.getMessageContext}
                activeRecipient={activeRecipient}
              />
            </div>
          }

          {(interaction.body || !interaction.attachments) &&
            <div className={classModifier("interaction__text-wrap", [
              interaction.attachments?.images && 'attachments'
            ])}>
              <MsgText
                highlightedText={search}
                interaction={interaction}
                onGirlNameClick={searchByGirlName}
              />
            </div>
          }

          {interaction.attachments &&
            <InteractionAttachments
              attachments={interaction.attachments}
              profileId={props.profileId}
              userTimezone={props.userTimezone}
              userHour12={props.userHour12}
            />
          }
        </div>
      </li>
      {/* {parsedGirlsList.isOpen &&
        <ParsedGirlList
          msgRef={msgRef}
          activeRecipient={activeRecipient}
          addNewArrayGirlsToState={props.addNewArrayGirlsToState}
          profileId={props.profileId}
          parsedGirlsList={parsedGirlsList}
          updateActiveContact={props.updateActiveContact}
          setParsedGirlsList={setParsedGirlsList}
        />
      } */}
    </>
  );
};


export default React.memo(OutgoingMessage);

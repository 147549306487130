import React from 'react';
import AutoSizer from "react-virtualized-auto-sizer";
import { Scrollbars } from "react-custom-scrollbars-2";



const CustomListWrapper = (props) => (
  <AutoSizer>
    {({ width, height }) => (
      <Scrollbars
        // autoHide
        hideTracksWhenNotNeeded
        renderView={props.renderView}
        renderThumbHorizontal={() => <div style={{ display: 'none' }} />}
        renderThumbVertical={() => <div style={{ display: 'none' }} />}
        // renderThumbVertical={props => (
        //   <div {...props} className="thumb-vertical" />
        // )}
        // renderTrackVertical={props => (
        //   <div {...props} className="track-vertical" />
        // )}
        style={{ width: width + 30, height: height - 60 }}
      >
        {props.children}
      </Scrollbars>
    )}
  </AutoSizer>
);

export default CustomListWrapper;

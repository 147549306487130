import { Route, Navigate, Routes, useMatch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { ROUTES } from 'config/constants';
import { LIST_TYPES, MAIL_LIST_FOLDER_TABS } from 'redux/ducks/mail';
import { selectAvailableMailsTabs, selectMailListSearchQuery } from 'redux/selectors/selectors';

import './MailMain.scss';
import MailComposer from './components/MailComposer/MailComposer';
import MailList from './components/MailList/MailList';
import MailChat from 'containers/MailMain/components/MailChat/MailChat';

const MailMain = () => {
  const chatRouteMatch = useMatch(`${ROUTES.mail}/:folder/email/:id`);
  const mailListSearchQuery = useSelector(selectMailListSearchQuery);
  const availableMailsTabs = useSelector(selectAvailableMailsTabs);

  const getChatRouteElement = () => {
    if (!chatRouteMatch) {
      return null;
    }
    const { folder, id } = chatRouteMatch.params;

    const isFolderExists = Object.values(LIST_TYPES).includes(folder);
    
    if (!isFolderExists) {
      return <Navigate to={`../inbox/email/${id}`} replace />;
    }
    return <MailChat params={chatRouteMatch.params}/>;
  }

  return (
    <div className="mail-main">
      <Routes>
        <Route path="/compose" element={<MailComposer />} />

        {mailListSearchQuery.length && (
          <Route
            path="/search"
            element={<MailList type={LIST_TYPES.search} key="search" />}
          />
        )}

        <Route
          path="/inbox"
          element={
            <MailList
              type={LIST_TYPES.inbox}
              tabs={[
                ...MAIL_LIST_FOLDER_TABS[LIST_TYPES.inbox],
                ...availableMailsTabs.map((mail) => ({
                  operatorName: mail.operatorName,
                  mode: mail.type,
                  id: mail.id,
                  default: mail.is_default,
                })),
              ]}
              key="inbox"
            />
          }
        />
        <Route
          path="/archive"
          element={
            <MailList
              type={LIST_TYPES.archive}
              tabs={[
                ...MAIL_LIST_FOLDER_TABS[LIST_TYPES.inbox],
                ...availableMailsTabs.map((mail) => ({
                  operatorName: mail.operatorName,
                  mode: mail.type,
                  id: mail.id,
                  default: mail.is_default,
                })),
              ]}
              key="archive"
            />
          }
        />
        <Route
          path="/drafts"
          element={<MailList type={LIST_TYPES.drafts} key="drafts" />}
        />
        <Route
          path="/sent"
          element={
            <MailList
              type={LIST_TYPES.sent}
              key="sent"
              tabs={[
                ...MAIL_LIST_FOLDER_TABS[LIST_TYPES.inbox],
                ...availableMailsTabs.map((mail) => ({
                  operatorName: mail.operatorName,
                  mode: mail.type,
                  id: mail.id,
                  default: mail.is_default,
                })),
              ]}
            />
          }
        />
        <Route
          path="/spam"
          element={
            <MailList
              type={LIST_TYPES.spam}
              key="spam"
              tabs={[
                ...MAIL_LIST_FOLDER_TABS[LIST_TYPES.inbox],
                ...availableMailsTabs.map((mail) => ({
                  operatorName: mail.operatorName,
                  mode: mail.type,
                  id: mail.id,
                  default: mail.is_default,
                })),
              ]}
            />
          }
        />
        <Route
          path="/trash"
          element={
            <MailList
              type={LIST_TYPES.trash}
              key="trash"
              tabs={[
                ...MAIL_LIST_FOLDER_TABS[LIST_TYPES.inbox],
                ...availableMailsTabs.map((mail) => ({
                  operatorName: mail.operatorName,
                  mode: mail.type,
                  id: mail.id,
                  default: mail.is_default,
                })),
              ]}
            />
          }
        />

        <Route path="/:folder/email/:id" element={getChatRouteElement()} />

        <Route path="/*" element={<Navigate to={`../inbox`} replace />} />
      </Routes>
    </div>
  );
}

export default MailMain;

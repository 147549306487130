import moment from 'moment';
import _ from 'lodash';

import { getDateByTimezoneOffset } from 'utils';
import convertDateToBookingsKey from '../utils/convertDateToBookingsKey';
import convertDateToFilterIndex from '../utils/convertDateToFilterIndex';
import { selectBookingFilters, selectIsDateKeyExist } from '../selectors/selectors';
import checkBookingForFilters from '../utils/checkBookingForFilters';
import getHashOfFilters from '../utils/getHashOfFilters';
import { BOOKING_STATUSES, FINISHED_BOOKING_STATUSES, UNFINISHED_BOOKING_STATUSES } from 'redux/ducks/sessions';
import getShortBookingItem from '../utils/getShortBookingItem';
import joinTwoDates from 'utils/joinTwoDates';


const DELETE_INITIAL_BOOKING_STATE = 'DELETE_INITIAL_BOOKING_STATE';

const UPDATE_BOOKINGS = 'UPDATE_BOOKINGS';

const SET_DATE = 'SET_DATE';
const SET_DATE_BY_DAYS = 'SET_DATE_BY_DAYS';
const SET_EXTRA_STATE = 'SET_EXTRA_STATE';
const SET_QUERY = 'SET_QUERY';

const CHANGE_BOOLEAN_FILTER = 'CHANGE_BOOLEAN_FILTER';
const CHANGE_DUO_FILTER = 'CHANGE_DUO_FILTER';
const CHANGE_CURRENT_GIRL_FILTER = 'CHANGE_CURRENT_GIRL_FILTER';
const CHANGE_OUTCALL_FILTER = 'CHANGE_OUTCALL_FILTER';
const CHANGE_STATUS_FILTER = 'CHANGE_STATUS_FILTER';
const CHANGE_FILTER_ID = 'CHANGE_FILTER_ID';
const CHANGE_SORTING = 'CHANGE_SORTING';
const CHANGE_CONFIG_KEY = 'CHANGE_CONFIG_KEY';

const SET_PENDING = 'SET_PENDING';

const RESET_DATE = 'RESET_DATE';
const RESET_DAY_FILTER = 'RESET_DAY_FILTERS';
const RESET_ALL_DAY_FILTERS = 'RESET_ALL_DAY_FILTERS';
const RESET_FILTER = 'RESET_FILTER';
const RESET_QUERY = 'RESET_QUERY';

const DELETE_BOOKING = 'DELETE_BOOKING';
const PASTE_BOOKING = 'PASTE_BOOKING';

const ADD_STATUS_COUNT = 'ADD_STATUS_COUNT';
const REDUCE_STATUS_COUNT = 'REDUCE_STATUS_COUNT';

const ADD_FINISHED_STATUS_COUNT = 'ADD_FINISHED_STATUS_COUNT';
const REDUCE_FINISHED_STATUS_COUNT = 'REDUCE_FINISHED_STATUS_COUNT';

const ADD_NOTES_STATUS_COUNT = 'ADD_NOTES_STATUS_COUNT';
const REDUCE_NOTES_STATUS_COUNT = 'REDUCE_NOTES_STATUS_COUNT';

const ADD_REQUESTS_STATUS_COUNT = 'ADD_REQUESTS_STATUS_COUNT';
const REDUCE_REQUESTS_STATUS_COUNT = 'REDUCE_REQUESTS_STATUS_COUNT';

const DELETE_SAVED_FILTER = 'DELETE_SAVED_FILTER';


export const deleteInitialBookingState
  = (date, filtersKey) => ({ type: DELETE_INITIAL_BOOKING_STATE, payload: { date, filtersKey } });

export const setDate = (date) => ({ type: SET_DATE, payload: { date } })
export const setDayBefore = () => ({ type: SET_DATE_BY_DAYS, payload: { days: -1 } });
export const setDayAfter = () => ({ type: SET_DATE_BY_DAYS, payload: { days: 1 } });
export const setPending = (date, value) => ({ type: SET_PENDING, payload: { date, value } });
export const setQuery = (value) => ({ type: SET_QUERY, payload: { value } });
export const setExtraState = (date, bookingId, value, name) => ({
  type: SET_EXTRA_STATE,
  payload: { date, bookingId, value, name }
});

export const changeBooleanFilter = (date, name) => ({ type: CHANGE_BOOLEAN_FILTER, payload: { date, name } });
export const changeDuoFilter = (date) => ({ type: CHANGE_DUO_FILTER, payload: { date } });
export const changeCurrentGirlFilter = (date, girlId) => ({
  type: CHANGE_CURRENT_GIRL_FILTER,
  payload: { date, girlId }
});
export const changeOutcallFilter = (date) => ({ type: CHANGE_OUTCALL_FILTER, payload: { date } });
export const changeFilterId = (date, filterName, value) => ({
  type: CHANGE_FILTER_ID,
  payload: { date, filterName, value }
}
);
export const changeStatusFilter = (date, name) => ({ type: CHANGE_STATUS_FILTER, payload: { date, name } });
export const changeSorting = (date, sortOption) => ({ type: CHANGE_SORTING, payload: { date, sortOption } });
export const changeConfigKey = (configKey) => ({ type: CHANGE_CONFIG_KEY, payload: { configKey } });

export const updateBookings = (data, date, isNotNew) => ({
  type: UPDATE_BOOKINGS,
  payload: { data, date, isNotNew }
});

export const resetAllDayFilters = (count, dates) => ({
  type: RESET_ALL_DAY_FILTERS,
  payload: { count, dates }
});
export const resetDayFilter = (date) => ({
  type: RESET_DAY_FILTER,
  payload: { date }
})
export const resetFilter = (date, name) => ({
  type: RESET_FILTER,
  payload: { date, name }
})
export const resetDate = () => ({ type: RESET_DATE });
export const resetQuery = () => ({ type: RESET_QUERY });

export const deleteBooking = (booking, filtersKey) => ({
  type: DELETE_BOOKING,
  payload: { booking, filtersKey }
});

export const pasteBooking = (booking, filtersKey) => ({
  type: PASTE_BOOKING,
  payload: { booking, filtersKey }
})

export const reduceStatusCount = (booking, filtersKey) => ({
  type: REDUCE_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const addStatusCount = (booking, filtersKey) => ({
  type: ADD_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const addFinishedCount = (booking, filtersKey) => ({
  type: ADD_FINISHED_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const reduceFinishedCount = (booking, filtersKey) => ({
  type: REDUCE_FINISHED_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const addNotesCount = (booking, filtersKey) => ({
  type: ADD_NOTES_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const reduceNotesCount = (booking, filtersKey) => ({
  type: REDUCE_NOTES_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const addRequestsCount = (booking, filtersKey) => ({
  type: ADD_REQUESTS_STATUS_COUNT,
  payload: { booking, filtersKey }
})

export const reduceRequestsCount = (booking, filtersKey) => ({
  type: REDUCE_REQUESTS_STATUS_COUNT,
  payload: { booking, filtersKey }
})


const ACTION_GROUPS = {
  BOOKING: 'BOOKING',
  STATUS_COUNT: 'STATUS_COUNT',
  FINISHED_COUNT: 'FINISHED_COUNT',
  NOTES_COUNT: 'NOTES_COUNT',
  REQUESTS_COUNT: 'REQUESTS_COUNT',
}

const actionsOnSocketUpdate = {
  [ACTION_GROUPS.BOOKING]: { add: pasteBooking, remove: deleteBooking },
  [ACTION_GROUPS.STATUS_COUNT]: { add: addStatusCount, remove: reduceStatusCount },
  [ACTION_GROUPS.FINISHED_COUNT]: { add: addFinishedCount, remove: reduceFinishedCount },
  [ACTION_GROUPS.NOTES_COUNT]: { add: addNotesCount, remove: reduceNotesCount },
  [ACTION_GROUPS.REQUESTS_COUNT]: { add: addRequestsCount, remove: reduceRequestsCount },
}

const actionBehaviors = {
  [ACTION_GROUPS.BOOKING]: 'dynamic',
  [ACTION_GROUPS.STATUS_COUNT]: 'static',
  [ACTION_GROUPS.FINISHED_COUNT]: 'dynamic',
  [ACTION_GROUPS.NOTES_COUNT]: 'dynamic',
  [ACTION_GROUPS.REQUESTS_COUNT]: 'dynamic',
}

export const changeBookingState = (oldBooking, newBooking) => (getState, dispatch) => {
  if (oldBooking === null || oldBooking?.[0] === null || newBooking === null || newBooking?.[0] === null) {
    return;
  }
  
  const state = getState();
  const allFiltersKeys = Object.keys(state.entities);

  allFiltersKeys.forEach((filtersKey, i) => {
    const updateBookingState = (booking, actionMode) => {
      _.forEach(actionsOnSocketUpdate, (actions, groupName) => {
        const action = actions[actionMode];
        const filters = JSON.parse(filtersKey);
        const isActionCheckedForFilters = actionBehaviors[groupName] === 'dynamic'
          ? checkBookingForFilters(booking, filters)
          : true;

        if (isActionCheckedForFilters) {
          dispatch(action(booking, filtersKey));
        }
      });
    }

    oldBooking && updateBookingState(oldBooking, 'remove');
    newBooking && updateBookingState(newBooking, 'add');
  });
}

export const deleteSavedFilter = (filtersKey) => ({
  type: DELETE_SAVED_FILTER,
  payload: { filtersKey }
})


export const createInitialState = (duringAllTime, userTimezone) => {
  const currentDate = !duringAllTime
    ? Date.parse(getDateByTimezoneOffset(userTimezone))
    : null;

  return {
    entities: {},
    filters: {
      general: {
        currentDate: currentDate,
        date: currentDate,
      },
      byDay: [],
      updatedDate: null,
    },
    itemsState: {},
    settings: {
      configKey: null,
      userTimezone,
    }
  }
};

export const reducer = (state, { type, payload }) => {
  const updateSpecificFilter = (setter) => {
    const { date } = payload;
    const index = convertDateToFilterIndex(state.filters.general.date, date);
    const newFilter = setter ? setter(state.filters.byDay[index]) : null;
    const isFiltersSeparated = state.filters.byDay.length > 1;

    return {
      ...state,
      filters: {
        ...state.filters,
        byDay: state.filters.byDay.map((filter, i) => {
          if (i === index) {
            if (!newFilter) return {};

            return { ...filter, ...newFilter };
          } else {
            return filter
          }
        }),
        updatedDate: isFiltersSeparated ? date : null,
      },
    }
  }

  const updateGeneralFilter = (setter) => {
    const { general } = state.filters;
    const newFilter = setter(general);

    return {

      ...state,
      filters: {
        ...state.filters,
        general: { ...general, ...newFilter },
        updatedDate: null,
      },
    }
  }

  const updateDate = (setter) => {
    const { date, currentDate } = state.filters.general;
    const updatedDate = Array.isArray(setter(date)) ? setter(date) : [setter(date)];

    const dateWithSavedTime = updatedDate.map((date) =>
      joinTwoDates(moment(date), moment(currentDate))
    );
    
    return {
      ...state,
      filters: {
        ...state.filters,
        general: {
          ...state.filters.general,
          date: dateWithSavedTime.length > 1
            ? dateWithSavedTime
            : dateWithSavedTime[0],
        }
      }
    }
  }

  const updateBookingInDay = (setter) => {
    const { filtersKey, booking: { date } } = payload;
    const dateKey = convertDateToBookingsKey(date, true);
    const oldBookings = state.entities[filtersKey]?.[dateKey];

    if (!oldBookings) {
      return state;
    } else {
      return updateBookings(setter, filtersKey, dateKey);
    }
  }

  const updateBookingsDay = (setter) => {
    const filtersKey = getHashOfFilters(selectBookingFilters(state, payload.date));
    const dateKey = convertDateToBookingsKey(payload.date);

    return updateBookings(setter, filtersKey, dateKey);
  }

  const updateBookings = (setter, filtersKey, dateKey) => {
    const oldBookings = state.entities[filtersKey]?.[dateKey];
    const newBookings = setter(oldBookings);

    return {
      ...state,
      entities: {
        ...state.entities,
        [filtersKey]: {
          ...state.entities[filtersKey],
          [dateKey]: {
            ...state.entities[filtersKey]?.[dateKey],
            ...newBookings,
          }
        }
      },
    }
  }

  const updateBookingExtraState = (setter, name) => {
    const dateKey = convertDateToBookingsKey(payload.date);
    const newExtraState = setter(state.itemsState);

    if (name) {
      return {
        ...state,
        itemsState: {
          ...state.itemsState,
          [dateKey]: {
            ...state.itemsState[dateKey],
            [name]: {
              ...state.itemsState[dateKey]?.[name],
              ...newExtraState
            }
          }
        }
      }
    }
		
    return {
			...state,
      itemsState: {
        ...state.itemsState,
        [dateKey]: {
          ...state.itemsState?.[dateKey],
          ...newExtraState
        }
      }
    }
  }

  const updateCounters = (counter, modifier) => {
    return updateBookingInDay((oldBookings) => ({
      entity: {
        ...oldBookings?.entity,
        bookingsCountByConfirmationStatus: {
          ...oldBookings?.entity?.bookingsCountByConfirmationStatus,
          [counter]: modifier(oldBookings?.entity?.bookingsCountByConfirmationStatus?.[counter] || 0)
        }
      }
    }))
  }


  switch (type) {
    case DELETE_INITIAL_BOOKING_STATE: {
      const { date, filtersKey } = payload;
      const dateKey = convertDateToBookingsKey(date);

      return {
        ...state,
        entities: {
          ...state.entities,
          [filtersKey]: {
            ...state.entities[filtersKey],
            [dateKey]: null
          }
        }
      }
    }
    
    case SET_DATE: {
      return updateDate(() => payload.date);
    }

    case SET_DATE_BY_DAYS: {
      const { days } = payload;
      const nextDay = (date) => moment(date).add(days, 'days').valueOf();
      
      return updateDate(
        (date) => Array.isArray(date)
          ? date.map(nextDay)
          : nextDay(date)
      )
    }

    case RESET_DATE: {
      return updateDate(() => state.filters.general.currentDate);
    }

    case SET_QUERY: {
      return updateGeneralFilter(() => ({ searchQuery: payload.value || null }));
    }

    case RESET_QUERY: {
      return updateGeneralFilter(() => ({ searchQuery: null }));
    }

    case CHANGE_BOOLEAN_FILTER: {
      return updateSpecificFilter(
        (filter) => ({ [payload.name]: !filter?.[payload.name] || null })
      );
    }

    case CHANGE_DUO_FILTER: {
      return updateSpecificFilter(
        (filter) => ({ meeting_type: filter?.meeting_type ? null : 'duo' })
      );
    }

    case CHANGE_CURRENT_GIRL_FILTER: {
      return updateSpecificFilter(
        (filter) => ({ girlId: filter.girlId === payload.girlId ? null : payload.girlId })
      );
    }

    case CHANGE_OUTCALL_FILTER: {
      return updateSpecificFilter(
        (filter) => ({ type: filter?.type ? null : 'outcall' })
      );
    }

    case CHANGE_STATUS_FILTER: {
      return updateSpecificFilter(
        (filter) => (
          { confirmationStatus: filter.confirmationStatus === payload.name ? null : payload.name }
        )
      )
    }

    case CHANGE_FILTER_ID: {
      return updateSpecificFilter(() => ({ [payload.filterName]: payload.value }));
    }

    case CHANGE_SORTING: {
      return updateSpecificFilter(
        (filter) => {
          if (filter.sortOption !== payload.sortOption) {
            return { sortOption: payload.sortOption, sortOptionType: 'desc' }
          } else if (filter.sortOptionType === 'desc') {
            return { sortOptionType: 'asc' }
          } else {
            return { sortOption: null, sortOptionType: null }
          }
        }
      )
    }

    case CHANGE_CONFIG_KEY: {
      return {
        ...state,
        settings: {
          ...state.settings,
          configKey: payload.configKey
        }
      }
    }

    case RESET_DAY_FILTER: {
      return updateSpecificFilter()
    }

    case RESET_ALL_DAY_FILTERS: {
      return {
        ...state,
        filters: {
          ...state.filters,
          byDay: Array(payload.count).fill({}),
          updatedDate: null
        }
      }
    }

    case RESET_FILTER: {
      return updateSpecificFilter(
        () => ({ [payload.name]: null })
      )
    }


    case SET_PENDING: {
      return updateBookingsDay(() => ({ pending: payload.value }));
    }

    case SET_EXTRA_STATE: {
			const { bookingId, value, name } = payload;

      return updateBookingExtraState(() => ({ [bookingId]: value }), name);
    }

    case UPDATE_BOOKINGS: {
      const { data, isNotNew } = payload;
      const bookingShortItems = data.bookings.map(getShortBookingItem);

      return updateBookingsDay((oldBookings) => ({
        entity: {
          bookingItems: isNotNew
            ? [...oldBookings?.entity?.bookingItems || [], ...bookingShortItems]
            : bookingShortItems,
          bookingsCount: data.bookingsCount,
          bookingsCountByConfirmationStatus: data.bookingsCountByConfirmationStatus,
          bookingsTotal: data.bookingsTotal,
        },
        extraState: oldBookings?.extraState || {},
        pending: false,
      }))
    }
    
    case PASTE_BOOKING: {
      const bookingShortItem = getShortBookingItem(payload.booking);

      return updateBookingInDay((oldBookings) => ({
        entity: {
          ...oldBookings.entity,
          bookingItems: _.sortBy([...oldBookings?.entity?.bookingItems, bookingShortItem], 'date'),
          bookingsCount: oldBookings?.entity?.bookingsCount + 1,
          bookingsTotal: oldBookings?.entity?.bookingsTotal + 1,
        }
      }))
    }

    case DELETE_BOOKING: {
      const { booking } = payload;

      return updateBookingInDay((oldBookings) => ({
        entity: {
          ...oldBookings.entity,
          bookingItems: oldBookings?.entity?.bookingItems.filter(({ id }) => String(id) !== String(booking.id)),
          bookingsCount: oldBookings?.entity?.bookingsCount - 1,
          bookingsTotal: oldBookings?.entity?.bookingsTotal - 1,
        }
      }));
    }

    case ADD_STATUS_COUNT: {
      const { booking } = payload;
      const { confirmation_status } = booking;

      return updateCounters(confirmation_status, (count) => count + 1);
    }

    case REDUCE_STATUS_COUNT: {
      const { booking } = payload;
      const { confirmation_status } = booking;

      return updateCounters(confirmation_status, (count) => count - 1);
    }

    case ADD_FINISHED_STATUS_COUNT: {
			const { booking } = payload;
      const confirmation_status = BOOKING_STATUSES[booking.status];

      if (!FINISHED_BOOKING_STATUSES.includes(confirmation_status)) {
        return state;
      }

      return updateCounters('finished', (count) => count + 1);
    }

    case REDUCE_FINISHED_STATUS_COUNT: {
			const { booking } = payload;
      const confirmation_status = BOOKING_STATUSES[booking.status];

      if (!FINISHED_BOOKING_STATUSES.includes(confirmation_status)) {
        return state;
      }

      return updateCounters('finished', (count) => count - 1);
    }

    case ADD_NOTES_STATUS_COUNT: {
      if (!payload.booking.notes) {
        return state;
      }

      return updateCounters('notes', (count) => count + 1);
    }

    case REDUCE_NOTES_STATUS_COUNT: {
      if (!payload.booking.notes) {
        return state;
      }

      return updateCounters('notes', (count) => count - 1);
    }

    case ADD_REQUESTS_STATUS_COUNT: {
      if (!payload.booking.requests?.length) {
        return state;
      }

      return updateCounters('requests', (count) => count + 1);
    }

    case REDUCE_REQUESTS_STATUS_COUNT: {
      if (!payload.booking.requests?.length) {
        return state;
      }

      return updateCounters('requests', (count) => count - 1);
    }

    case DELETE_SAVED_FILTER: {
      const { filtersKey } = payload;
      const { [filtersKey]: deletedFilter, ...restEntities} = state.entities;

      return {
        ...state,
        entities: restEntities
      }
    }

    default: {
      return state;
    }
  }
}

import React from 'react';
import classNames from 'classnames';

import { getDateByTimezoneOffset } from '../utils';

import ICONS from 'assets/icons';
import Icon from './Icon/Icon';

const bookingDurations = {
  '30 minutes': 30 * 60 * 1000,
  '45 minutes': 45 * 60 * 1000,
  '1 hour': 60 * 60 * 1000,
  '90 minutes': 90 * 60 * 1000,
  '2 hours': 2 * 60 * 60 * 1000,
  '3 hours': 3 * 60 * 60 * 1000,
  'Overnight': 12 * 60 * 60 * 1000,
};

const bookingStages = {
  sixHours: 6 * 60 * 60 * 1000,
  twoHours: 2 * 60 * 60 * 1000,
  oneHour: 60 * 60 * 1000,
}

const getIconColor = (dateDiff, isPreboking) => {
  const { sixHours, twoHours, oneHour } = bookingStages;

  if (dateDiff >= sixHours && isPreboking) {
    return '#b380ff';
  }

  if (dateDiff >= sixHours) {
    return '#32bf45'; // green
  }

  if (dateDiff <= sixHours && dateDiff >= twoHours) {
    return '#99c23a'; // green-yellow
  }

  if (dateDiff <= twoHours && dateDiff >= oneHour) {
    return '#f5aa42'; // orange
  }

  if (dateDiff <= oneHour && dateDiff >= 0) {
    return '#f54242'; // red
  }
  
  return '#f0a8d2'; // pink
}

const BookingIcon = props => {
  const {
    className = '',
    booking,
    userTimezone,
    onClick,
  } = props;

  if (!booking || booking.is_deleted) return null;

  if (!booking.date) {
    return (
      <ICONS.f 
        className={classNames('booking-icon', className)}
        fill="#809fff"
      />
    )
  }

  const dateByTimezone = getDateByTimezoneOffset(userTimezone);
  const bookingDateByTimezone = getDateByTimezoneOffset(userTimezone, booking.date);
  const dateDiff = bookingDateByTimezone - dateByTimezone;

  const onIconClick = () => {
    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  };

  // if unknown or empty duration
  if (!bookingDurations[booking.duration]) return null;
  // if booking finished
  if (dateDiff + bookingDurations[booking.duration] < 0) return null;

  let iconColor = getIconColor(dateDiff, booking.is_prebooking); 

  return (
    <Icon
      className={classNames('booking-icon', className)}
      icon={booking.is_prebooking ? ICONS.p : ICONS.booking}
      fill={iconColor}
      onClick={onIconClick}
      data-booking-icon
    />
  )
};

export default BookingIcon;
